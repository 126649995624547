import React, { useEffect } from "react"
import styled, { keyframes } from "styled-components"

import CloseIcon from "./CloseIcon"
import FadeInOut from "./FadeInOut"

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;

  .initFadeOut {
    will-change: opacity;
    animation: 0.3s ease-out ${fadeOut} running forwards;
  }

  .content-aligner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    margin-bottom: 100px;
    justify-content: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .content-aligner {
      align-items: center;
      padding: 20px;
    }
  }
`

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${({ theme }) => `${theme.palette.cocoaBrown}75`};
`

const Content = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 15px;
  max-width: 90vw;
  max-height: 90vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .scroll-container {
    height: 100%;
    overflow: hidden scroll;
    padding: 0px 20px;
    margin-bottom: 20px;
  }

  .close-button {
    align-self: flex-end;
    padding: 20px;
    cursor: pointer;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .scroll-container {
      padding: 0px 50px;
      margin-bottom: 50px;
    }
  }
`

interface Props {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<Props> = ({ open, onClose, children }) => {
  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")
    if (open) {
      bodyElement[0].style.overflow = "hidden"
    } else {
      bodyElement[0].style.overflow = "hidden auto"
    }
  }, [open])

  return open ? (
    <Container>
      <Backdrop data-cy-test="modal-backdrop" onClick={onClose} />
      <div className="content-aligner">
        <FadeInOut fadeType={open ? "fadeIn" : "fadeOut"} from="bottom">
          <Content>
            <div className="close-button" onClick={onClose}>
              <CloseIcon />
            </div>
            <div className="scroll-container">{children}</div>
          </Content>
        </FadeInOut>
      </div>
    </Container>
  ) : null
}

export default Modal

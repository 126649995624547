import React from "react"
import styled from "styled-components"

import LogoBase from "../Logo"
import MenuIcon from "../MenuIcon"
import CloseIcon from "../CloseIcon"

const BarWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 20px 0px 20px;
  background-color: ${({ theme }) => theme.palette.mystic};
`

const Logo = styled(LogoBase)`
  display: block;
  margin-bottom: 0px;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 5px;
`

const IconWrapper = styled.div`
  width: 12px;
  height: 12px;
  margin-top: 8px;
  margin-right: 18px;
  position: relative;
  cursor: pointer;
`

interface Props {
  isOpen: boolean
  onClick?: () => void
}

const MobileTopBar = ({ isOpen, onClick }: Props): JSX.Element => {
  return (
    <BarWrapper>
      <Logo />
      <LinksWrapper>
        <IconWrapper onClick={onClick}>
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </IconWrapper>
      </LinksWrapper>
    </BarWrapper>
  )
}

export default MobileTopBar

import { useField, FieldHookConfig } from "formik"
import React, { useEffect, useState, useCallback } from "react"
import styled from "styled-components"

import { InputLabel, InputFieldError, InputContainer } from "./Input/index"
import Loader from "./Loader"
interface Props {
  label?: string
  placeholder: string
  options: Inputs.SelectOption[]
  loading?: boolean
  margin?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const DropDownInput = ({
  label,
  placeholder,
  options,
  loading,
  onChange,
  disabled,
  margin = "0px 0px 10px 0px",
  ...props
}: Props & FieldHookConfig<string>): JSX.Element => {
  const [field, meta, helpers] = useField(props)
  const hasValue = (field.value || "").length > 0
  const hasError = meta.touched && meta.error
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      helpers.setValue(event.target.value)
      if (onChange) onChange(event)
      setFocus(false)
    },
    [helpers, onChange]
  )

  return (
    <InputContainer margin={margin}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledContainer
        inFocus={focus}
        hasValue={hasValue}
        isDisabled={disabled ? disabled : false}
        hasError={!!hasError}
      >
        <select
          onMouseDown={() => {
            setFocus(true)
          }}
          onBlurCapture={() => {
            setFocus(false)
          }}
          {...field}
          onChange={handleChange}
          value={field.value}
          placeholder={placeholder}
          disabled={loading || disabled}
        >
          <option value="">{placeholder || `Select option`}</option>
          {options.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="image-container">
          {loading ? (
            <Loader size="small" />
          ) : (
            <img
              src="/images/icons/icon-dropdown-arrow.svg"
              alt="Dropdown icon"
            />
          )}
        </div>
      </StyledContainer>
      {hasError && <InputFieldError>{meta.error}</InputFieldError>}
    </InputContainer>
  )
}

export default DropDownInput

const StyledContainer = styled.div<{
  inFocus: boolean
  hasValue: boolean
  isDisabled: boolean
  hasError: boolean
}>`
  border: 1px solid
    ${({ hasError, inFocus, theme }) =>
      hasError
        ? theme.palette.red
        : inFocus
        ? theme.palette.mineShaft
        : theme.palette.cornflowerBlue};
  border-radius: 6px;
  position: relative;
  text-align: left;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};

  select {
    flex: 1;
    width: 100%;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ hasValue, theme }) =>
      hasValue ? theme.palette.black : theme.palette.baliHai};
    font-size: 14px;
    line-height: 22px;
    padding: 16px 32px 15px 18px;
    border: none;
    outline: none;
    transition: 0.1s;
    appearance: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .image-container {
    position: absolute;
    width: 12px;
    right: 18px;
    top: 18px;
    pointer-events: none;

    img {
      transform: ${({ inFocus }) =>
        inFocus ? "rotate(180deg)" : "rotate(0deg)"};
      opacity: 1;
      margin-bottom: 0;
    }
  }
`

import React, { useState, useRef } from "react"
import styled from "styled-components"

import { useNotify } from "../../alert/hooks"
import { useUser } from "../../auth/hooks"
import { InputWithButton, RadioToggle } from "../../components"
import {
  validatePhoneNumber,
  mobileNumberToInternational,
} from "../../utils/mobileNumber"
import Logo from "../Logo"
import Modal from "../Modal"

const Container = styled.div<{
  compact: boolean
}>`
  width: 100%;
  max-width: 100%;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.black};

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 10px;
    }
  }

  .title {
    font-family: ${({ theme }) => theme.fonts.title};
    font-size: 26px;
    margin-bottom: 10px;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 64vw;
    min-height: auto;

    .header {
      img {
        margin-bottom: 25px;
      }

      .title {
        margin-bottom: ${({ compact }) => (compact ? "10px" : "35px")};
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 650px;
  }
`

const ModalMessage = styled.div`
  width: 100%;
  padding: 20px 15px;
  border: 1px solid ${({ theme }) => theme.palette.cornflowerBlue};
  border-radius: 4px;
  margin-top: 10px;

  h4 {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    line-height: 18px;
    margin: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 25px;

    h4 {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 26px;
    }

    p {
      display: block;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      margin: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    p {
      font-size: 15px;
      line-height: 26px;
    }
  }
`

const ContactOptionsContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`

const ContactOptionButton = styled.button<{
  isActive: boolean
  activeColor: string
}>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 9px;
  background-color: ${({ isActive, activeColor }) =>
    isActive && activeColor ? activeColor : "transparent"};
  border: ${({ isActive, activeColor, theme }) =>
    isActive && activeColor
      ? `2px solid ${activeColor}`
      : `2px solid ${theme.palette.bombay}`};
  padding: 20px;
  transition: background-color 0.2s, border-color 0.2s;

  &:hover {
    background-color: ${({ isActive, activeColor }) =>
      !isActive && activeColor && activeColor + "22"};
    border-color: ${({ isActive, activeColor }) =>
      !isActive && activeColor && activeColor};
    cursor: pointer;
  }

  span {
    font-size: 14px;
    line-height: 22px;
    margin: 0 20px;
    font-weight: 500;
    color: ${({ isActive, theme }) =>
      isActive ? theme.palette.white : theme.palette.black};
  }

  .check-icon {
    margin-bottom: 0;
    color: ${({ theme }) => theme.palette.white};
    display: ${({ isActive }) => (isActive ? "inline" : "none")};
    position: absolute;
    right: 15px;
  }
`

const ContactActionContainer = styled.div`
  width: 100%;
  margin-top: 25px;
`

const CopyCode = styled.div<{
  color: string
}>`
  background: ${({ color }) => `${color}30`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.mineShaft};
  cursor: pointer;

  .message {
    text-align: left;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.black};
    font-size: 15px;
    line-height: 22px;
    margin-right: 20px;
  }

  .code {
    color: ${({ color }) => color};
  }

  .copy-action-button {
    display: flex;

    img {
      min-width: 22px;
    }
  }
`

const radioOptions = [
  {
    id: "merchant",
    label: "A Merchant",
    value: "merchant",
    defaultChecked: true,
  },
  {
    id: "recruit",
    label: "A Friend",
    value: "recruit",
    defaultChecked: false,
  },
]

interface ContactOption {
  name: string
  color: string
  optionButtonLabel: string
  actionPrefix?: string
  actionPlaceholder: string
  actionButtonLabel?: string
  actionButtonIcon: string
}

interface Props {
  open: boolean
  onClose: () => void
  code: string
}

const ShareCodeModal: React.FC<Props> = ({ open, onClose, code }: Props) => {
  const contactOptions: ContactOption[] = [
    {
      name: "copy",
      optionButtonLabel: "Copy link",
      actionPrefix: "This is your unique link:",
      actionPlaceholder: code,
      color: "#018668",
      actionButtonIcon: "/images/icons/icon-link.svg",
    },
    {
      name: "whatsapp",
      optionButtonLabel: "Whatsapp",
      actionPrefix: "+27",
      actionPlaceholder: "Add contact number",
      color: "#25D366",
      actionButtonLabel: "Send Whatsapp",
      actionButtonIcon: "/images/icons/icon-whatsapp-simple.svg#base",
    },
    {
      name: "email",
      optionButtonLabel: "Email",
      actionPlaceholder: "Add email address",
      color: "#018668",
      actionButtonLabel: "Send Email",
      actionButtonIcon: "/images/icons/icon-send-email.svg#base",
    },
  ]

  const { user } = useUser()
  const notify = useNotify()
  const [isCopying, setIsCopying] = useState(false)
  const [isMerchant, setIsMerchant] = useState(true)
  const referralLink = `${
    isMerchant
      ? process.env.GATSBY_MERCHANT_REFERRAL_URL
      : process.env.GATSBY_FRIEND_REFERRAL_URL
  }${code}`
  const message = isMerchant
    ? "Follow this link to sign up as a Yoco merchant\n"
    : "Follow this link to sign up to become a certified Yoco reseller\n"

  const extraMessage = `
  \nCongratulations! You're few steps away from saying "yes" to card payments! Your Yoco certified reseller has sent you this link to use to sign up.\n
  Please note the following important information:
  - A Yoco certified reseller should never know the password to your Yoco portal. When setting up your account using this link, do not share your password with anyone.
  - We will never ask your for your password to log into your portal
  - If you would like to verify the authenticity of your Yoco reseller, please send us more details to reseller@yoco.com\n`

  const referralMessage = encodeURIComponent(
    `${message} ${referralLink} ${extraMessage}`
  )
  const contactInputRef = useRef<HTMLInputElement>(null)
  const [currentContactOption, setCurrentContactOption] =
    useState<ContactOption>(contactOptions[0])

  const CopyToClipboard = async () => {
    if (isCopying) return

    setIsCopying(true)

    if ("clipboard" in navigator) {
      try {
        await navigator.clipboard.writeText(referralLink)
        notify("info", "Copied")
      } catch {
        notify("danger", "There was an issue when trying to copy the link")
      }
    } else {
      notify("danger", "There was an issue when trying to copy the link")
    }

    setIsCopying(false)
  }

  const toggleCodeRecipient = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setIsMerchant(e.target?.value === "merchant")
    }
  }

  const handleSend = () => {
    if (code) {
      if (currentContactOption.name === "copy") {
        CopyToClipboard()
      } else if (currentContactOption.name === "whatsapp") {
        if (
          contactInputRef?.current?.value &&
          validatePhoneNumber(contactInputRef?.current?.value)
        ) {
          const phoneNumber = mobileNumberToInternational(
            contactInputRef?.current?.value
          )
          window.open(
            `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${referralMessage}&app_absent=0`,
            "_blank"
          )
        } else {
          notify(
            "warning",
            "Invalid phone number",
            "select a contact to continue"
          )
          window.open(
            `https://api.whatsapp.com/send/?text=${referralMessage}&app_absent=0`,
            "_blank"
          )
        }
      } else {
        const email = document.createElement("a")
        email.href = `mailto:${contactInputRef?.current?.value}?subject=${message}&body=${referralMessage}`
        email.click()
      }
    } else {
      notify(
        "danger",
        "Error loading Referral code",
        "Please clear browser cache and try again. if problem persists contact support"
      )
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Container
        data-cy-test="share-code-modal"
        compact={user?.profile?.profileType !== "RESELLER"}
      >
        <div className="header">
          <Logo />
          <h1 data-cy-test="share-code-modal-heading" className="title">
            {user?.profile?.profileType === "RESELLER"
              ? "Send unique link to..."
              : "Send unique link to a Merchant"}
          </h1>
          {user?.profile?.profileType === "RESELLER" ? (
            <RadioToggle
              name="shareCodeModalToggle"
              options={radioOptions}
              size="small"
              OnRadioToggle={toggleCodeRecipient}
            />
          ) : null}
        </div>
        {isMerchant ? (
          <ModalMessage>
            <h4>
              Earn up to R500 for every merchant that signs up with your link.
            </h4>
            <p>
              Ask potential merchants to use your unique code at the end of
              checkout when signing up for their merchant account on yoco.com or
              use this direct link to sign up.
            </p>
          </ModalMessage>
        ) : (
          <ModalMessage>
            <h4>
              Earn up to R500 for every friend that signs up as a reseller with
              your unique code.
            </h4>
            <p>
              Ask your friends to use your unique code when signing up for the
              reseller program or use this direct link to sign up.
            </p>
            <br />
            <p>
              Please note your friend must activate 10 merchants and verify
              their identity in order for you to receive your reward.
            </p>
          </ModalMessage>
        )}
        <ContactOptionsContainer>
          {contactOptions.map((option: ContactOption) => (
            <ContactOptionButton
              key={option.name}
              isActive={currentContactOption.name === option.name}
              activeColor={option.color}
              onClick={() => {
                if (contactInputRef?.current) {
                  contactInputRef.current.value = ""
                }
                setCurrentContactOption(option)
              }}
            >
              <span>{option.optionButtonLabel}</span>
              <svg className="check-icon" width={22} height={22}>
                <use href="/images/icons/icon-circled-check.svg#base" />
              </svg>
            </ContactOptionButton>
          ))}
        </ContactOptionsContainer>
        <ContactActionContainer>
          {currentContactOption.name === "copy" ? (
            <CopyCode
              color={currentContactOption.color}
              onClick={() => handleSend()}
            >
              <div className="copy-action">
                <div className="message">
                  {currentContactOption.actionPrefix}
                </div>
                <div className="code">{referralLink}</div>
              </div>
              <div className="copy-action-button">
                <img
                  src={currentContactOption.actionButtonIcon}
                  alt="Contact action icon"
                />
              </div>
            </CopyCode>
          ) : (
            <InputWithButton
              color={currentContactOption.color}
              prefix={currentContactOption.actionPrefix}
              placeholder={currentContactOption.actionPlaceholder}
              buttonLabel={currentContactOption.actionButtonLabel}
              buttonIcon={currentContactOption.actionButtonIcon}
              contactInputRef={contactInputRef}
              handleSubmit={handleSend}
            />
          )}
        </ContactActionContainer>
      </Container>
    </Modal>
  )
}

export default ShareCodeModal

import { useLocation } from "@reach/router"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { useIsMobile } from "../../../hooks"
import { useSignOut } from "../../auth/hooks"
import LogoBase from "../../components/Logo"
import LinkButton from "../LinkButton"
import { UniqueLinkCodeContext } from "../Nav/ShareCodeContext"
import Links from "./Links"
import MobileTopBar from "./MobileTopBar"
import ReferralLink from "./ReferralLink"
import UserProfile from "./UserProfile"

const NavWrapper = styled.div`
  height: 100%;
`

const MenuWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  overflow: hidden auto;

  &.desktop {
    height: inherit;

    nav {
      max-height: 100vh;
      height: 100%;
      width: ${({ theme }) => theme.desktopNavWidth};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &.open {
    --transform-translate-x: -100%;
  }

  &.closed {
    --transform-translate-x: 0;
  }

  &.mobile {
    height: 100%;
    width: 100vw;
    flex-grow: 1;
    background-color: white;

    z-index: 30;
    top: 0;
    bottom: 0;
    right: -100%;
    position: fixed;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform;
    transition-duration: 0.5s;
    transform: translateX(var(--transform-translate-x));
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: white;
  pointer-events: none;
`

const Logo = styled(LogoBase)`
  display: block;
  margin: 0 auto 28px !important;
`

const LinksWrapper = styled.div<{
  isMobile: boolean
}>`
  padding: ${({ isMobile }) => (isMobile ? "50px 20px 0px" : "48px 48px 0px")};
  position: relative;
`

const NavFooter = styled.div<{
  isMobile: boolean
}>`
  padding: ${({ isMobile }) => (isMobile ? "50px 20px 0px" : "0px 30px 48px")};
  position: relative;
`

const LogoutWrapper = styled.div`
  padding-bottom: 20px;
  bottom: 40px;
`

const Nav = (): JSX.Element => {
  const isMobile = useIsMobile()
  const signOut = useSignOut()
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [location.pathname, setIsOpen])

  return (
    <NavWrapper>
      {isMobile ? (
        <MobileTopBar isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      ) : null}
      <MenuWrapper
        data-cy-test="main-nav-bar"
        className={
          isMobile ? (isOpen ? "mobile open" : "mobile closed ") : "desktop"
        }
      >
        {isMobile ? <BackgroundWrapper /> : null}
        <nav>
          {isMobile ? (
            <MobileTopBar isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
          ) : null}
          <LinksWrapper isMobile={isMobile}>
            {!isMobile ? <Logo /> : null}
            <Links />
          </LinksWrapper>

          <NavFooter isMobile={isMobile}>
            <UserProfile CloseNav={() => setIsOpen(!isOpen)} />
            <UniqueLinkCodeContext.Consumer>
              {({ toggleReferralCodeModal }) => (
                <ReferralLink
                  toggleReferralCodeModal={toggleReferralCodeModal}
                />
              )}
            </UniqueLinkCodeContext.Consumer>
            <LogoutWrapper>
              <LinkButton
                cypressTestId="nav-logout-button"
                color="black"
                onClick={signOut}
                leftIcon="/images/nav/icon-logout.svg"
              >
                Logout of portal
              </LinkButton>
            </LogoutWrapper>
          </NavFooter>
        </nav>
      </MenuWrapper>
    </NavWrapper>
  )
}

export default Nav

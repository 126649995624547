import React, { useState } from "react"
import styled from "styled-components"

import Arrow from "./Arrow"
import Card from "./Card"

const CardWrapper = styled.div`
  flex: 1;
  width: 100%;
  cursor: pointer;
  transition: 0.2s;

  & > div {
    height: 100%;
  }

  .card-contents {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .card-details {
      display: flex;
      gap: 20px;
    }
  }

  h3 {
    color: ${({ theme }) => theme.palette.white};
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    margin: 0px;
  }

  button {
    background-color: transparent;
    border-radius: 18px;
    border: none;
    color: ${({ theme }) => theme.palette.orangePeel};
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    padding: 0;
    cursor: pointer;
  }

  .arrow-icon {
    margin-left: 20px;
    transition: 0.2s;
  }

  &:hover {
    .arrow-icon {
      transform: translateX(5px);
    }
  }
`

type Props = {
  heading: string
  onClick: () => void
}

const DocumentCard: React.FC<Props> = ({ heading, onClick }) => {
  const [viewed, setViewed] = useState(false)

  return (
    <CardWrapper
      onClick={() => {
        setViewed(true)
        onClick()
      }}
    >
      <Card
        padding="20px"
        backgroundColor="#222"
        hasShadow={!viewed}
        borderRadius="12px"
      >
        <div className="card-contents">
          <div className="card-details">
            <img
              src="/images/dashboard/icon-document.svg"
              alt="Signature icon"
            />
            <div>
              <h3>{heading}</h3>
              <button
                type="button"
                onClick={() => {
                  setViewed(true)
                  onClick()
                }}
              >
                {viewed ? "View again" : "View now"}
              </button>
            </div>
          </div>
          <div className="arrow-icon">
            <Arrow color="#fff" />
          </div>
        </div>
      </Card>
    </CardWrapper>
  )
}
export default DocumentCard

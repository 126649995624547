import { FieldHookConfig, useField } from "formik"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"

import { useGooglePlacesAutocompleteAPI } from "../../hooks"
import { useNotify } from "../alert/hooks"
import { CloseIcon } from "../components"
import "../css/googlePlacesAutocomplete.css"
import { InputContainer, InputFieldError, InputLabel } from "./Input/index"

const StyledContainer = styled.div<{
  inFocus: boolean
  inputWidth: string
  hasError: boolean
}>`
  border: 1px solid
    ${({ hasError, inFocus, theme }) =>
      hasError
        ? theme.palette.red
        : inFocus
        ? theme.palette.mineShaft
        : theme.palette.cornflowerBlue};
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: ${({ inputWidth }) => inputWidth || "0px"};
`

const InputWrapper = styled.div<{
  inFocus: boolean
  hasValue: boolean
}>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ hasValue, theme }) =>
    hasValue ? theme.palette.black : theme.palette.baliHai};
  font-size: 14px;
  line-height: 22px;
  padding: 16px 18px 15px 18px;
  border: none;
  outline: none;
  transition: 0.1s;

  img {
    width: 18px;
    margin-bottom: 0;
  }

  input {
    flex: 1;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ hasValue, theme }) =>
      hasValue ? theme.palette.black : theme.palette.baliHai};
    font-size: 14px;
    line-height: 22px;
    margin-left: 10px;
    border: none;
    outline: none;
    width: 100%;
  }
`

const AreaTags = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

const TagItem = styled.div`
  background-color: rgba(112, 153, 144, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 6px;
  gap: 10px;

  p {
    color: ${({ theme }) => theme.palette.mineShaft};
    font-size: 12px;
    margin: 0;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  .removeButton:hover {
    cursor: pointer;
  }
`

interface Props {
  label?: string
  placeholder: string
  margin?: string
}

const GooglePlacesInput = ({
  label,
  placeholder,
  margin = "0px 0px 10px 0px",
  ...props
}: Props & FieldHookConfig<Reseller.SalesAreas[]>): JSX.Element => {
  const notify = useNotify()
  const scriptLoading = useGooglePlacesAutocompleteAPI()
  const inputRef = useRef<HTMLInputElement>(null)

  const autocomplete = useMemo(() => {
    if (!scriptLoading && inputRef?.current) {
      const options = {
        componentRestrictions: { country: "za" },
        fields: ["place_id", "name", "address_components", "geometry"],
        strictBounds: false,
        types: ["(regions)"],
      }
      return new google.maps.places.Autocomplete(inputRef?.current, options)
    } else {
      return undefined
    }
  }, [scriptLoading, inputRef])

  const [field, meta, helpers] = useField(props)
  const hasError = meta.touched && meta.error
  const hasValue = field?.value.length !== 0
  const [focus, setFocus] = useState(false)
  const { setValue } = helpers

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (autocomplete) {
      const selectedPlaceListener = google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          const place = autocomplete.getPlace()
          const existingTag = field.value.find((area) => {
            return area.placeId === place.place_id
          })
          if (!existingTag) {
            const lng = place.geometry?.location.lng()
            const lat = place.geometry?.location.lat()
            const province = place.address_components?.find((adminLevel) =>
              adminLevel.types.includes("administrative_area_level_1")
            )?.long_name

            if (place && lng && lat && place.place_id) {
              const tempAreaTags = [...field.value]
              tempAreaTags?.push({
                placeId: place.place_id,
                province: province || "",
                suburbName: place.name,
                longitude: lng,
                latitude: lat,
                radius: 0.0,
              })
              setValue(tempAreaTags)

              if (inputRef.current) {
                inputRef.current.value = ""
              }
            }
          } else {
            notify(
              "warning",
              "Area already added",
              "that area is already in your list of tags"
            )
          }
        }
      )

      return () => {
        google.maps.event.removeListener(selectedPlaceListener)
      }
    }
  }, [autocomplete, notify, field.value, setValue])

  const removeAreaTag = useCallback(
    (targetArea: string) => {
      const tempAreaTags = field.value.filter(
        (area) => area.suburbName !== targetArea
      )
      setValue(tempAreaTags)
    },
    [setValue, field.value]
  )

  const handleInputFocus = useCallback(() => {
    if (!focus) {
      setFocus(true)
      inputRef?.current?.focus()
    }
  }, [focus])

  return (
    <InputContainer margin={margin}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledContainer inputWidth="0px" inFocus={focus} hasError={!!hasError}>
        <InputWrapper
          hasValue={hasValue}
          inFocus={focus}
          onClick={handleInputFocus}
        >
          <img
            id="tagIcon"
            src="/images/dashboard/icon-tag.svg"
            alt="Tag icon"
          />
          <input
            ref={inputRef}
            placeholder={scriptLoading ? "Loading..." : placeholder}
            onBlur={() => setFocus(false)}
            disabled={scriptLoading}
          />
        </InputWrapper>
      </StyledContainer>
      {hasError && <InputFieldError>{meta.error}</InputFieldError>}
      <AreaTags>
        {field.value.map((area) => (
          <TagItem key={area.suburbName}>
            <p>{area.suburbName}</p>
            <div
              className="removeButton"
              onClick={() => removeAreaTag(area.suburbName)}
            >
              <CloseIcon />
            </div>
          </TagItem>
        ))}
      </AreaTags>
    </InputContainer>
  )
}

export default GooglePlacesInput

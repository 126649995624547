import styled from "styled-components"

import Button from "./Button"

const RoundedButton = styled(Button)`
  padding: 9px 20px;
  border-radius: 52px;
  border-width: 2px;
`

export default RoundedButton

import React from "react"
import styled from "styled-components"

interface Props {
  color: string
  prefix: string | undefined
  placeholder: string
  buttonLabel: string | undefined
  buttonIcon: string
  contactInputRef: React.RefObject<HTMLInputElement>
  handleSubmit: () => void
}

const ContactInputButton = ({
  color,
  prefix,
  placeholder = "Enter your contact here",
  buttonLabel,
  buttonIcon,
  contactInputRef,
  handleSubmit,
}: Props): JSX.Element => {
  const renderIcon = () => (
    <ButtonIcon>
      <use href={buttonIcon}></use>
    </ButtonIcon>
  )

  return (
    <>
      <ContactActionContainer color={color} buttonHasLabel={!!buttonLabel}>
        <div className="contact-action">
          {prefix ? <span className="prefix">{prefix}</span> : null}
          <input ref={contactInputRef} placeholder={placeholder} />
        </div>
        <div className="contact-action-button" onClick={handleSubmit}>
          {buttonLabel ? <span>{buttonLabel}</span> : null}
          {renderIcon()}
        </div>
      </ContactActionContainer>
      <MobileActionButton onClick={handleSubmit} color={color}>
        <span>{buttonLabel ? buttonLabel : "Share"}</span>
        {renderIcon()}
      </MobileActionButton>
    </>
  )
}

export default ContactInputButton

const ContactActionContainer = styled.div<{
  color: string
  buttonHasLabel: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 20px 60px 20px 20px;
  background: ${({ color }) => `${color}30`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 20px;
  }

  .contact-action {
    flex: ${({ buttonHasLabel }) => (buttonHasLabel ? 2 : 1)};
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 22px;
    color: rgb(34, 34, 34);
    overflow: hidden;

    input {
      flex: 1;
      background: none;
      border: none;
      color: ${({ theme }) => theme.palette.mineShaft};

      &:focus {
        border: none;
        outline: none;
      }
    }

    .prefix {
      text-align: left;
      font-weight: 500;
      color: ${({ theme }) => theme.palette.black};
      font-size: 15px;
      line-height: 22px;
      margin-right: 20px;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        text-align: center;
      }
    }
  }

  .contact-action-button {
    display: none;
    cursor: pointer;
    color: ${({ color }) => color};

    span {
      border-left: 1px solid ${({ theme }) => theme.palette.bombay};
      padding-left: 20px;
      font-size: 15px;
      line-height: 22px;
      font-weight: 500;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: flex;
      min-width: ${({ buttonHasLabel }) => (buttonHasLabel ? "200px" : "22px")};
      flex: ${({ buttonHasLabel }) => (buttonHasLabel ? 1 : null)};
      position: absolute;
      right: 20px;
      justify-content: space-between;
    }
  }
`

const MobileActionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  background: ${({ color }) => color};
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.white};
  font-weight: 500;
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
    margin-top: 0px;
  }
`

const ButtonIcon = styled.svg`
  height: 22px;
  width: 22px;
`

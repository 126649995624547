import { useField, FieldHookConfig } from "formik"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import {
  InputLabel,
  InputFieldError,
  InputHelpText,
  InputContainer,
} from "./Input/index"

const StyledContainer = styled.div<{
  inFocus: boolean
  hasValue: boolean
  hasError: boolean
  hasTopLabel: boolean
}>`
  border: 1px solid
    ${({ hasError, inFocus, theme }) =>
      hasError
        ? theme.palette.red
        : inFocus
        ? theme.palette.mineShaft
        : theme.palette.cornflowerBlue};
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 0p;
  input {
    flex: 1;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ hasValue, theme }) =>
      hasValue ? theme.palette.black : theme.palette.baliHai};
    font-size: 14px;
    line-height: 22px;
    padding: 0 18px;
    border: none;
    outline: none;
    transition: 0.1s;
    padding-top: ${({ hasValue, hasTopLabel }) =>
      hasValue && !hasTopLabel ? "22px" : "16px"};
    padding-bottom: ${({ hasValue, hasTopLabel }) =>
      hasValue && !hasTopLabel ? "9px" : "15px"};
    width: 100%;
  }
`

const StyledLabel = styled.span<{
  hasValue: boolean
  inFocus: boolean
}>`
  pointer-events: none;
  position: absolute;
  left: 18px;
  top: 0px;
  font-size: 14px;
  transform: translateY(20px);
  transition: all ease 200ms;
  color: ${({ theme }) => theme.palette.baliHai};
  ${({ hasValue, inFocus }) =>
    hasValue || inFocus
      ? css`
          font-size: 10px;
          transform: translateY(8px);
          opacity: 1;
          color: ${({ theme }) => theme.palette.mineShaft};
        `
      : ""}
`

const ShowButton = styled.span`
  position: absolute;
  right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.black};
  transform: translateX(0);
  opacity: 1;
  z-index: 99;
  cursor: pointer;
`
interface Props {
  cypressTestId?: string
  label?: string
  placeholder: string
  helpText?: string
  margin?: string
}

const Password = ({
  cypressTestId,
  label,
  placeholder,
  helpText,
  margin = "0px 0px 10px 0px",
  ...props
}: Props & FieldHookConfig<string>): JSX.Element => {
  const [field, meta, helpers] = useField(props)
  const hasValue = !!field?.value
  const hasError = meta.touched && meta.error
  const [focus, setFocus] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InputContainer margin={margin} data-cy-test="input-container">
      {label && <InputLabel>{label}</InputLabel>}
      <StyledContainer
        hasError={!!hasError}
        inFocus={focus}
        hasValue={hasValue}
        hasTopLabel={!!label}
      >
        <input
          data-cy-test={cypressTestId}
          onFocus={() => {
            setFocus(true)
          }}
          onBlurCapture={() => {
            setFocus(false)
          }}
          type={passwordShown ? "text" : "password"}
          placeholder={label && placeholder ? placeholder : ""}
          {...field}
        />
        {!label && (
          <StyledLabel inFocus={focus} hasValue={hasValue}>
            {placeholder}
          </StyledLabel>
        )}
        <ShowButton
          onClick={togglePassword}
          data-cy-test="password-input-show-button"
        >
          {passwordShown ? "Hide" : "Show"}
        </ShowButton>
      </StyledContainer>
      {hasError && (
        <InputFieldError data-cy-test="input-error-field">
          {meta.error}
        </InputFieldError>
      )}
      {helpText && <InputHelpText>{helpText}</InputHelpText>}
    </InputContainer>
  )
}

export default Password

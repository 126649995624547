import { createReducer } from "@reduxjs/toolkit"

import { notify, dismiss } from "./actions"

interface NotificationState {
  notification: Alerts.Notification | null
}

const alertReducer = createReducer(
  {
    notification: null,
  } as NotificationState,
  {
    [notify.type]: (state, action) => {
      state.notification = action.payload
    },
    [dismiss.type]: (state) => {
      state.notification = null
    },
  }
)

export default alertReducer

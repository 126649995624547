import styled from "styled-components"

const InputContainer = styled.div<{
  margin: string
}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: ${({ margin }) => margin};
`

export default InputContainer

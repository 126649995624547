import { gql } from "@apollo/client"

import { COMPLETED_TRAINING_FRAGMENT } from "../training/fragments"
import {
  USER_DETAILS_FRAGMENT,
  PROFILE_FRAGMENT,
  BANKING_DETAILS_FRAGMENT,
  ADDRESS_FRAGMENT,
  SALES_AREAS_FRAGMENT,
  PREFERENCES_FRAGMENT,
  INVOICE_FRAGMENT,
} from "./fragments"

export const GET_USER = gql`
  ${USER_DETAILS_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${BANKING_DETAILS_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${SALES_AREAS_FRAGMENT}
  ${PREFERENCES_FRAGMENT}
  ${COMPLETED_TRAINING_FRAGMENT}
  ${INVOICE_FRAGMENT}
  query getUser {
    me {
      id
      ...userDetailsFragment
      ...profileFragment
      ...bankingDetailsFragment
      ...addressFragment
      ...salesAreasFragment
      ...preferencesFragment
      ...completedTrainingFragment
      invoices {
        edges {
          node {
            ...invoiceFragment
          }
        }
      }
    }
  }
`

export const GET_BANKS = gql`
  query banks {
    banks {
      id
      bankName
      branchCode
    }
  }
`

export const GET_BANK_ACCOUNT_TYPES = gql`
  query bankAccounts {
    bankAccounts {
      label
      value
    }
  }
`

export const GET_LANGUAGES = gql`
  query languages {
    languages {
      id
      languageName
      dialect
    }
  }
`

export const GET_SHIRT_SIZES = gql`
  query shirtSizes {
    shirtSizes {
      name
      label
      value
    }
  }
`

export const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      id
      name
      provinces {
        id
        name
      }
    }
  }
`

export const GET_DOCUSIGN_SIGNING_URL = gql`
  query getDocusignSigningUrl {
    docusignSigningUrl
  }
`

export const GET_YOCO_ADDRESS = gql`
  query getYocoAddress {
    yocoAddress {
      name
      addressLine1
      addressLine2
      city
      postalCode
    }
  }
`

export const GET_SITE_UNDER_MAINTENANCE = gql`
  query getSiteMaintenanceSettings {
    siteMaintenanceStatus {
      underMaintenance
    }
  }
`

export const GET_ASSOCIATES = gql`
  query getAssociates($first: Int = ${
    process.env.GATSBY_TABLE_ROW_COUNT || 100
  }, $status: String, $after: String) {
    associates(first: $first, status: $status, after: $after) {
      count {
        all
        pending
        activated
        deactivated
      }
      edges {
        node {
          userId
          id
          email
          name
          status
          mobileNumber
          trainingProgress
          type
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }`

export const GET_RESELLER_INVOICES = gql`
  query getResellerInvoices($first: Int = ${
    process.env.GATSBY_TABLE_ROW_COUNT || 100
  }, $status: String, $after: String) {
    invoices(first: $first, status: $status, after: $after) {
      edges {
        node {
          id
          totalVatAmount
          totalAmountExclVat
          totalAmount
          status
          createdAt
          referenceNumber
          pdf
          referenceNumber
          lineItems {
            id
            description
            quantity
            percentage
            itemAmount
            totalVatAmount
            totalAmountExclVat
            totalAmount
            category
            adHocCategory
          }
        }
      }
      principalCutLineItemLookup
      count {
        all
        created
        approved
        paid
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const GET_INVOICE_COUNT = gql`
  query getInvoiceCount {
    invoices {
      count {
        all
        created
        approved
        paid
      }
    }
  }
`

export const GET_INVOICE_PDF = gql`
  query getInvoicePdf($id: ID) {
    invoicePdf(id: $id)
  }
`

export const GET_MERCHANTS = gql`
  query getMerchants($first: Int = ${
    process.env.GATSBY_TABLE_ROW_COUNT || 100
  }, $status: String, $username: String, $after: String) {
    merchants(first: $first, status: $status, username: $username, after: $after) {
      edges {
        node {
          id
          business {
            tradingName
          }
          email
          mobileNumber
          firstName
          lastName
          status
          createdAt
          bwonOn
          activatedOn
          incompleteSteps {
            title
            outstandingInfo
          }
          associatedReseller {
            fullName
            email
            referralCode
          }
        }
      }
      count {
        signedUpCount
        fullyOnboardedCount
        activeCount
        totalCount
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const GET_PAYROLLS = gql`
  query getPayrolls($first: Int = ${
    process.env.GATSBY_TABLE_ROW_COUNT || 100
  }, $status: String, $after: String) {
    payrolls(first: $first, status: $status, after: $after) {
      edges {
        node {
          id
          name
          email
          status
          createdAt
          canPay
          totalPrincipalCut
          totalAssociateCut
          totalVatAmount
          totalAmountExclVat
          totalAmount
          lineItems {
            id
            description
            quantity
            percentage
            itemAmount
            totalVatAmount
            totalAmountExclVat
            totalAmount
            totalPrincipalCut
            adHocCategory
          }
        }
      }
      count {
        all
        paid
        unpaid
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

export const GET_PAYROLL_COUNT = gql`
  query getPayrollCount {
    payrolls {
      count {
        all
        paid
        unpaid
      }
    }
  }
`

export const EXPORT_PAYROLLS = gql`
  query exportPayrolls($payrollIds: [ID]) {
    exportPayrolls(payrollIds: $payrollIds)
  }
`

export const GET_ASSOCIATE_PROGRESS = gql`
  query getAssociateProgress {
    associateProgress {
      edges {
        node {
          id
          userDetails {
            name
            email
          }
          connected {
            amount
            quantity
          }
          active {
            amount
            quantity
          }
          tpv {
            amount
            quantity
          }
          status
          createdAt
          updatedAt
          canPay
          totalPrincipalCut
          totalAssociateCut
          totalVatAmount
          totalAmountExclVat
          totalAmount
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

export const GET_REWARD_BADGES = gql`
  query getRewardsBadge {
    rewardsBadge {
      activatedMerchants
      badges {
        name
        minMerchants
        maxMerchants
        description
        percentage
        currentBadge
      }
    }
  }
`

export const GET_SUPPORT_TICKETS = gql`
  query getSupportTickets($first: Int = ${
    process.env.GATSBY_TABLE_ROW_COUNT || 100
  }, $salesforceStatus: String, $after: String) {
    tickets(first: $first, salesforceStatus: $salesforceStatus, after: $after) {
      edges {
        node {
          id
          salesforceId
          salesforceStatus
          displayStatus
          category
          merchant {
            firstName
            lastName
            email
            business {
              tradingName
            }
          }
          updatedAt
          createdAt
        }
      }
      count {
        all
        new
        open
        inProgress
        merchantActionRequired
        escalated
        solved
        closed
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    notifications {
      heading
      message
      createdAt
    }
  }
`

export const GET_BUSINESS_MEMBER_LEADERBOARD = gql`
  query getBusinessMemberLeaderboard($first: Int = ${
    process.env.GATSBY_TABLE_ROW_COUNT || 100
  }, $ordering: String = "descending", $after: String) {
    businessMemberLeaderboard(first: $first, ordering: $ordering, after: $after) {
      edges {
        node {
          id
          userName
          email
          evolution
          updatedAt
          rank
          monthlyActivatedMerchants
          monthlyTpv
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

export const GET_INDIVIDUAL_LEADERBOARD = gql`
  query getIndividualLeaderboard($first: Int = ${
    process.env.GATSBY_TABLE_ROW_COUNT || 100
  }, $scopeType: String = "GLOBAL", $after: String) {
    individualLeaderboard(first: $first, scopeType: $scopeType, after: $after) {
      edges {
        node {
          id
          userName
          evolution
          updatedAt
          rank
          scopeType
          scopeArea
          monthlyActivatedMerchants
          province
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
export const GET_RANKING_LAST_UPDATED_AT_TIMESTAMP = gql`
  query getRankingLastUpdatedAtTimestamp {
    rankingsLastUpdated
  }
`
export const GET_RANKING = gql`
  query getRanking {
    currentUserRanking {
      id
      startDate
      endDate
      updatedAt
      rank
      scopeType
      scopeArea
      monthlyActivatedMerchants
      monthlyTpv
      evolution
      userName
      province
    }
  }
`

export const GET_PRINCIPAL_BUSINESS = gql`
  query getPrincipalBusiness {
    business {
      id
      name
      type
      vatNumber
      companyRegistrationNumber
    }
  }
`

export const GET_ASSOCIATE_LINE_ITEMS = gql`
  query AssociateLineItems {
    associateLineItems {
      edges {
        node {
          associateName
          lineItems {
            description
            quantity
            itemAmount
            totalAmount
            totalAmountExclVat
            adHocCategory
            category
          }
          total
        }
      }
    }
  }
`

export const GET_POLICY_DOCUMENTS = gql`
  query getPolicyDocuments {
    policyDocuments {
      name
      url
    }
  }
`

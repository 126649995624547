import { gql } from "@apollo/client"

export const COMPLETED_TRAINING_FRAGMENT = gql`
  fragment completedTrainingFragment on UserNode {
    completedTrainingMaterial {
      id
      material {
        id
        module {
          id
        }
      }
    }
  }
`

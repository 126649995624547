import React from "react"
import styled from "styled-components"

import Search from "../components/Search"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .title {
    color: ${({ theme }) => theme.palette.mineShaft};
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    padding-left: 15px;
    margin: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .title {
      font-size: 20px;
      line-height: 32px;
      padding-left: 0px;
    }
  }
`

interface Props {
  cypressTestId?: string
  title: string
  hasSearch?: boolean
  handleSearchTerm?: (searchTerm: string | undefined) => void
}

const PageHeader: React.FC<Props> = ({
  cypressTestId = "",
  title,
  hasSearch,
  handleSearchTerm,
}) => {
  return (
    <Container>
      <h1 data-cy-test={cypressTestId} className="title">
        {title}
      </h1>
      {hasSearch ? (
        <Search
          handleSearchTerm={handleSearchTerm ? handleSearchTerm : () => null}
        />
      ) : null}
    </Container>
  )
}

export default PageHeader

import React from "react"
import styled from "styled-components"

const Container = styled.div<{
  backgroundColor: string
  padding: string
  borderRadius: string
  hasShadow: boolean
  gridArea?: string
}>`
  width: 100%;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ padding }) => padding};
  grid-area: ${({ gridArea }) => (gridArea ? gridArea : null)};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ hasShadow }) =>
    hasShadow ? "0px 18px 30px -20px rgba(0,0,0,0.5)" : null};
  overflow: hidden;
  transition: background-color 0.3s;
  isolation: isolate;
`

interface Props {
  backgroundColor?: string
  padding?: string
  borderRadius?: string
  gridArea?: string
  hasShadow?: boolean
  children: React.ReactNode
  className?: string
}

const DashboardCard: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  backgroundColor = "#ffffff",
  padding = "20px",
  borderRadius = "20px",
  gridArea,
  hasShadow = false,
  children,
  className,
}) => {
  return (
    <Container
      className={className}
      backgroundColor={backgroundColor}
      padding={padding}
      borderRadius={borderRadius}
      hasShadow={hasShadow}
      gridArea={gridArea}
    >
      {children}
    </Container>
  )
}

export default DashboardCard

import React, { useState, useRef } from "react"
import styled from "styled-components"

import CloseIcon from "./CloseIcon"

const SearchContainer = styled.div`
  height: 55px;
  width: 180px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.mineShaft};
  border-radius: 30px;
  padding: 17px 27px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  transition: outline, box-shadow 0.2s;

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.palette.tiara};
    box-shadow: 0px 0px 0px 6px ${({ theme }) => `${theme.palette.tiara}60`};
  }

  .clearInput {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    transition: opacity 0.2s;
  }

  .clearInput:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const SearchInput = styled.input`
  width: 100%;
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.mineShaft};
    opacity: 1;
  }
`

interface Props {
  handleSearchTerm: (searchTerm: string | undefined) => void
}

const ToolTip: React.FC<Props> = ({ handleSearchTerm }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState("")

  const submitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearchTerm(value)
      inputRef?.current?.blur()
    }
  }

  const clearInputValue = () => {
    setValue("")
    inputRef?.current?.blur()
    handleSearchTerm(undefined)
  }

  return (
    <SearchContainer>
      <SearchInput
        ref={inputRef}
        placeholder="Search"
        onKeyDown={submitSearch}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {value !== "" ? (
        <div className="clearInput" onClick={clearInputValue}>
          <CloseIcon strokeWeight="bold" size="small" />
        </div>
      ) : null}
    </SearchContainer>
  )
}

export default ToolTip

import { useDispatch } from "react-redux"

import { dismiss, notify } from "./actions"

export function useNotify(): (
  type: Alerts.NotificationType,
  title: string,
  message?: string
) => void {
  const dispatch = useDispatch()

  return (type: Alerts.NotificationType, title: string, message?: string) => {
    dispatch(
      notify({
        type,
        title,
        message,
      })
    )
  }
}

export function useDismiss(): () => void {
  const dispatch = useDispatch()

  return () => {
    dispatch(dismiss())
  }
}

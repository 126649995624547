import React, { useRef, useState } from "react"
import styled from "styled-components"

import { ToolTip } from "."

const CountIndicator = styled.div`
  .large-counter {
    font-size: 78px;
    font-family: sharp-grotesk-25;
    font-weight: 600;
    margin: 0;
    line-height: 0.8;
  }

  .regular-counter {
    margin: 0;
    font-size: 34px;
    font-family: sharp-grotesk-25;
    font-weight: 600;
    width: fit-content;
  }

  .total {
    font-size: 16px;
    font-weight: 400;
  }

  .description {
    font-family: sharp-grotesk-25;
    font-size: 10px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.doveGrey};
    margin-top: 10px;
    white-space: wrap;
  }
`

type CounterProps = {
  count?: number
  total?: number
  showZero?: boolean
  description: string
  sizeVariant?: string
  tooltipText: string
  tooltipOffset?: number
}

const Counter: React.FC<CounterProps> = ({
  count = 0,
  total = 0,
  showZero = false,
  description,
  sizeVariant = "normal",
  tooltipText,
  tooltipOffset = 0,
}) => {
  const rankingTooltip = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <>
      <ToolTip
        open={showTooltip}
        referenceElement={rankingTooltip}
        customOffsetX={tooltipOffset}
      >
        <p>{tooltipText}</p>
      </ToolTip>
      <CountIndicator
        onMouseEnter={() => {
          setShowTooltip(true)
        }}
        onMouseLeave={() => {
          setShowTooltip(false)
        }}
      >
        <p
          ref={rankingTooltip}
          className={
            sizeVariant === "large" ? "large-counter" : "regular-counter"
          }
        >
          {count === 0 || count === null ? (showZero ? count : "N/A") : count}
          {total > 0 && <span className="total">{` / ${total}`}</span>}
        </p>
        <p className="description">{description}</p>
      </CountIndicator>
    </>
  )
}

export default Counter

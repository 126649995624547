import React from "react"

interface Props {
  color?: string
  size?: "small" | "large"
  strokeWeight?: "thin" | "bold"
}

const CloseIcon = ({ color, size, strokeWeight }: Props): JSX.Element => {
  const strokeLength = size === "small" ? 9 : 17
  const dimensions = size === "small" ? 13 : 21

  return (
    <svg
      width={`${dimensions}px`}
      height={`${dimensions}px`}
      viewBox={`0 0 ${dimensions} ${dimensions}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>1ED9B355-A945-4205-A2B3-EAB3D9F3BEA5@1x</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="YOCO-Reseller-Assets"
          transform="translate(-166.000000, -412.000000)"
          stroke={color ? color : "#222222"}
          strokeWidth={
            strokeWeight && strokeWeight === "bold" ? "3" : "2.4411116"
          }
        >
          <g id="icn-close" transform="translate(168.000000, 414.000000)">
            <line
              x1="2.77514945e-16"
              y1="0.00049991178"
              x2={strokeLength}
              y2={strokeLength}
              id="Stroke-1"
            ></line>
            <line
              x1="2.77514945e-16"
              y1={strokeLength}
              x2={strokeLength}
              y2="1.11002714e-15"
              id="Stroke-3"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CloseIcon

interface Error {
  message?: string
  code?: string
}

interface Config {
  showRateLimitingMessage?: boolean
}

const DEFAULT_ERROR_MESSAGE =
  "Something went wrong. Please try again later or contact the system administrator"

export const getErrorMessage = (error: Error, config?: Config): string => {
  let errorMessage = ""

  if (error.message === "rate_limited") {
    errorMessage =
      "You have exceeded your attempt limit. Please try again later."
  } else {
    errorMessage = error?.message ?? DEFAULT_ERROR_MESSAGE

    if (config?.showRateLimitingMessage) {
      errorMessage += " (Please note you have limited attempts.)"
    }
  }

  return errorMessage
}

import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { useUser } from "../auth/hooks"

type Props = {
  src?: string
  className?: string
  to?: string
  isExternal?: boolean
}

const Logo: React.FC<Props> = ({
  src = "/images/reseller-logo.svg",
  className,
  to = "/dashboard/",
  isExternal = false,
}) => {
  const { user } = useUser()

  const renderLogo = () => {
    if (isExternal) {
      return (
        <a href={to} target="_blank" rel="noopener noreferrer">
          <LogoImage
            className={className}
            src={src}
            width={80}
            height={80}
            alt="Yoco Logo"
          />{" "}
        </a>
      )
    }

    if (user) {
      return (
        <Link to={to}>
          <LogoImage
            className={className}
            src={src}
            width={80}
            height={80}
            alt="Yoco Logo"
          />{" "}
        </Link>
      )
    } else {
      return (
        <Link to={"/sign-in"}>
          <LogoImage
            className={className}
            src={src}
            width={80}
            height={80}
            alt="Yoco Logo"
          />{" "}
        </Link>
      )
    }
  }

  return <>{renderLogo()}</>
}
export default Logo

const LogoImage = styled.img`
  margin: 0 10px;
  margin-bottom: 20px;
  width: 100px;
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;

  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  &:hover {
    cursor: pointer;
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }
`

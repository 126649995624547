import React from "react"

import Layout from "./components/layout"

const wrapPageElement = ({
  element,
}: {
  element: React.ReactNode & {
    props: {
      location: {
        pathname: string
      }
    }
  }
}): React.ReactNode => {
  return <Layout>{element}</Layout>
}

export default wrapPageElement

import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

type Props = {
  label: string
  linkText: string
  linkPath: string
  padding?: string
  cypressTestId?: string
}

const LabeledLink: React.FC<Props> = ({
  label,
  linkText,
  linkPath,
  padding = "20px 0px",
  cypressTestId,
}) => {
  return (
    <Wrapper padding={padding}>
      {label}
      <Link to={linkPath} data-cy-test={cypressTestId}>
        {linkText}
      </Link>
    </Wrapper>
  )
}
export default LabeledLink

const Wrapper = styled.p<{
  padding: string
}>`
  text-align: center;
  padding: ${({ padding }) => padding};
  color: ${({ theme }) => theme.palette.mineShaft};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 0;

  a {
    text-decoration: none;
    margin-left: 5px;
    color: ${({ theme }) => theme.palette.blue};
  }
`

import React from "react"
import styled from "styled-components"

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.outrageousOrange};
  padding: 5px 12px;
  border-radius: 15px;

  .text {
    font-size: 12px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.white};
    margin-bottom: 0;
  }
`

interface Props {
  count: number
}

const NavNotificationChip: React.FC<Props> = ({ count }) => {
  return (
    <Container>
      <p className="text">{count}</p>
    </Container>
  )
}

export default NavNotificationChip

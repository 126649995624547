import { useField, FieldHookConfig } from "formik"
import React, { useEffect } from "react"
import styled from "styled-components"

import Checkbox from "./Checkbox"

const StyledContainer = styled.div<{
  fontSize: string
  readOnly: boolean
}>`
  margin: 10px 0px;

  .checkbox-container {
    display: flex;
    gap: 10px;
  }

  .input-label {
    letter-spacing: 0;
    line-height: 18px;
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ theme }) => theme.palette.mineShaft};
    margin-bottom: 0px;
    cursor: ${({ readOnly }) => (readOnly ? "default" : "pointer")};
  }
`

const FieldError = styled.div`
  font-size: 12px;
  color: 1px solid rgba(72, 72, 74, 0.5);
  opacity: 0;
  pointer-events: none;
  margin-top: 10px;
  margin-bottom: 20px;
  opacity: 1;
  color: red;
`

interface Props {
  cypressTestId?: string
  id: string
  fontSize?: string
  readOnly?: boolean
  children: React.ReactNode
}

const CheckboxInput = ({
  cypressTestId,
  id,
  fontSize = "11px",
  readOnly = false,
  children,
  ...props
}: Props & FieldHookConfig<string>): JSX.Element => {
  const [field, meta, helpers] = useField(props)
  const hasError = meta.touched && meta.error
  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledContainer fontSize={fontSize} readOnly={readOnly}>
      <div className="checkbox-container">
        <Checkbox
          cypressTestId={cypressTestId}
          id={id}
          isChecked={!!field.value}
          {...field}
          disabled={readOnly}
          toggleCheckCallback={(e: any) => helpers.setValue(e.target.checked)}
        />
        <label htmlFor={`checkbox-${id}`} className="input-label">
          {children}
        </label>
      </div>
      {hasError && <FieldError>{meta.error}</FieldError>}
    </StyledContainer>
  )
}

export default CheckboxInput

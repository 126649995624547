import React, { useRef, useState } from "react"
import styled from "styled-components"

import { Button, Loader } from "../components"
import DropdownList from "./DropdownList"

interface ActionType {
  label: string
  onClick: () => void
}

interface Props {
  actions: ActionType[]
  loading?: boolean
  isBulkAction?: boolean
}

const ActionDropdown = ({
  actions,
  loading = false,
  isBulkAction = false,
}: Props): React.ReactElement => {
  const actionDropdown = useRef<HTMLDivElement>(null)
  const [focus, setFocus] = useState(false)

  const toggleFocus = () => {
    focus ? setFocus(false) : setFocus(true)
  }

  const handleClick = (actionHandler: ActionType["onClick"]) => {
    actionHandler()
    toggleFocus()
  }

  return (
    <div style={{ position: "relative" }}>
      <DropdownList open={focus} referenceElement={actionDropdown}>
        <div>
          {actions.map((action, index) => (
            <ActionContainer key={`action-item-${index}`}>
              <ActionButton
                color="#222"
                textColor="#fff"
                label={action.label}
                iconPath="/images/icons/arrow-white.svg"
                onClick={() => handleClick(action.onClick)}
              />
            </ActionContainer>
          ))}
        </div>
      </DropdownList>
      {focus ? (
        <Backdrop
          onClick={() => {
            setFocus(false)
          }}
        />
      ) : null}
      <StyledContainer
        onClick={() => (!loading ? toggleFocus() : null)}
        inFocus={focus}
        ref={actionDropdown}
        isBulkAction={isBulkAction}
        disabled={loading}
      >
        <p>{isBulkAction ? "Bulk Actions" : "Select"}</p>
        {loading ? (
          <Loader />
        ) : (
          <img
            src="/images/icons/icon-dropdown-arrow.svg"
            alt="Dropdown icon"
          />
        )}
      </StyledContainer>
    </div>
  )
}

export default ActionDropdown

const StyledContainer = styled.div<{
  inFocus: boolean
  isBulkAction: boolean
  disabled: boolean
}>`
  background-color: ${({ theme }) => theme.palette.white};
  border: ${({ isBulkAction }) => (isBulkAction ? "2px solid" : "1px solid")};
  border-color: ${({ inFocus, isBulkAction }) =>
    inFocus ? "#222" : isBulkAction ? "#a7c1bb" : "#1a222222"};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  p {
    opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};
    font-weight: ${({ isBulkAction }) => (isBulkAction ? "500" : "auto")};
    color: ${({ isBulkAction }) => (isBulkAction ? "#222" : "inherit")};
    margin: 0;
  }

  img {
    width: 12px;
    transform: ${({ inFocus }) =>
      inFocus ? "rotate(180deg)" : "rotate(0deg)"};
    opacity: ${({ inFocus }) => (inFocus ? "1" : "0.35")};
    margin-bottom: 0;
  }
`

const ActionContainer = styled.div`
  margin-bottom: 10px;
`

const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

const ActionButton = styled(Button)`
  padding: 6px 10px 6px 12px;
  font-family: ${({ theme }) => theme.fonts.body};
`

import { useCallback } from "react"

export const useSegmentTrackEvent = (): ((
  eventName: string,
  properties?: { [key: string]: string }
) => void) => {
  const trackEvent = useCallback(
    (eventName: string, properties?: { [key: string]: string }) => {
      if (window?.analytics) {
        window.analytics.track(eventName, properties)
      } else {
        console.error(
          `Unable to track event ${eventName}. Segment not loaded`,
          properties
        )
      }
    },
    []
  )

  return trackEvent
}

export const useSegmentReset = (): (() => void) => {
  const reset = useCallback(() => {
    if (window?.analytics) {
      window.analytics.reset()
    } else {
      console.error("Unable to reset Segment. Segment not loaded")
    }
  }, [])

  return reset
}

type AnalyticsUserTraits = {
  name?: string
  email?: string
  type: "RESELLER"
}

export const useSegmentSetUser = (): ((
  userId: string,
  userTraits?: AnalyticsUserTraits
) => void) => {
  const setUser = useCallback(
    (userId: string, userTraits?: AnalyticsUserTraits) => {
      if (window?.analytics) {
        window.analytics.identify(userId, userTraits)
      } else {
        console.error(
          "Unable to set Segment user. Segment not loaded",
          userId,
          userTraits
        )
      }
    },
    []
  )

  return setUser
}

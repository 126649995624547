import React from "react"
import styled from "styled-components"

interface Props {
  data: {
    email: string
    mobileNumber: string
  }
  index?: number
}

const ContactIcons = ({ data, index }: Props): JSX.Element => {
  const mailString = "mailto:" + data?.email
  const message = "Message"
  const whatsappString =
    "https://api.whatsapp.com/send/?phone=" +
    data?.mobileNumber +
    "&text=" +
    message +
    "&app_absent=0"

  return (
    <IconsContainer key={`contact-${index}`}>
      <a href={mailString}>
        <svg
          width="100%"
          viewBox="0 0 44 44"
          preserveAspectRatio="xMidYMid meet"
        >
          <use href="/images/icons/icon-email.svg#base" />
        </svg>
      </a>
      <a href={whatsappString} target="_blank" rel="noreferrer">
        <img src="/images/icons/icon-whatsapp.svg" alt="Whatsapp icon" />
      </a>
    </IconsContainer>
  )
}

export default ContactIcons

const IconsContainer = styled.div`
  display: flex;
  gap: 10px;
  grid-area: contact;
  justify-self: flex-start;

  a {
    width: 30px;
    color: ${({ theme }) => theme.palette.aquaDeep};

    &:hover {
      opacity: 0.6;
      transition: 0.2s;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-self: flex-end;
    gap: 15px;

    a {
      width: 44px;
    }
  }
`

import { DefaultTheme } from "styled-components"

export const theme: DefaultTheme = {
  fonts: {
    title: "sharp-grotesk-25",
    body: "sharp-grotesk-20",
  },
  palette: {
    black: "#000000",
    white: "#ffffff",

    aquaDeep: "#014737", //dark green
    baliHai: "#8d9cae", //blue-grey
    bombay: "#b5b6b7",
    blue: "#00a9e0",
    blueDianne: "#256155",
    cocoaBrown: "#161010",
    conch: "#c5d7d1", //green-grey
    cornflowerBlue: "#ccd5e0",
    doveGrey: "#676767",
    goldBrown: "#ffe9c7",
    green: "#018668",
    hummingBird: "#dff5fb", //light blue
    mineShaft: "#222222", // black
    mystic: "#e1eae8",
    opal: "#a7c1bb",
    orangePeel: "#ff9e00",
    outrageousOrange: "#fa5d37",
    porcelain: "#eaf0ee",
    red: "#f44336",
    tiara: "#c0d0cb",
  },
  breakpoints: {
    sm: "768px",
    md: "992px",
    lg: "1200px",
    xl: "1440px",
  },
  margins: {
    xs: "15px",
    sm: "28px",
    md: "35px",
    lg: "70px",
  },
  desktopNavWidth: "300px",
}

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  HttpLink,
  Operation,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { relayStylePagination } from "@apollo/client/utilities"
import { createUploadLink } from "apollo-upload-client"
import fetch from "isomorphic-fetch"

import { verifySession } from "./auth/utils"

let apolloClient: ApolloClient<NormalizedCacheObject>

const privateApiHttpLink = createUploadLink({
  fetch,
  uri: process.env.GATSBY_YOCO_API_ENDPOINT,
})

const publicApiLink = new HttpLink({
  fetch,
  uri: process.env.GATSBY_YOCO_API_ENDPOINT,
})

const privateApiAuthLink = setContext(async (_, { headers }) => {
  const sessionToken = verifySession()
  return {
    headers: {
      ...headers,
      authorization: sessionToken?.token ? `JWT ${sessionToken.token}` : "",
    },
  }
})

function createApolloClient() {
  const client = new ApolloClient({
    uri: process.env.GATSBY_YOCO_API_ENDPOINT,
    link: ApolloLink.split(
      (operation: Operation) =>
        operation.getContext().clientName === "private-api",
      privateApiAuthLink.concat(privateApiHttpLink),
      publicApiLink
    ),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            merchants: relayStylePagination(["status"]),
            associates: relayStylePagination(["status"]),
            invoices: relayStylePagination(["first", "status"]),
            payrolls: relayStylePagination(["first", "status"]),
            tickets: relayStylePagination(["first", "salesforceStatus"]),
            businessMemberLeaderboard: relayStylePagination(["ordering"]),
            individualLeaderboard: relayStylePagination(["scopeType"]),
          },
        },
      },
    }),
  })

  return client
}

export const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  if (!apolloClient) {
    apolloClient = createApolloClient()
  }

  return apolloClient
}

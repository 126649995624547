import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.tiara};
  padding: 10px 15px;
  border-radius: 19px;
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.palette.mineShaft};

  #close {
    font-size: 12px;
    line-height: 18px;
    margin-left: 10px;
    display: none;
    transition: transform 0.2s;
  }

  #close:hover {
    transform: rotate(90deg);
    cursor: pointer;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 20px 22px 17px 22px;
    font-size: 14px;
    line-height: 22px;

    #close {
      display: block;
    }
  }
`

interface Props {
  onDismiss?: () => void
  children: React.ReactNode
}

const NotificationCard: React.FC<Props> = ({ children, onDismiss }) => {
  return (
    <Container>
      {children}
      {onDismiss ? (
        <div id="close" onClick={onDismiss}>
          <img src="/images/icons/icon-close.svg" alt="Close icon" />
        </div>
      ) : null}
    </Container>
  )
}

export default NotificationCard

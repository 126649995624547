import React, { useEffect, useRef, useCallback } from "react"
import styled from "styled-components"
import Player from "@vimeo/player"

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  width: 700px;
  max-width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

interface Props {
  video: Training.Video
  onVideoComplete: (id: string) => void
}

const VimeoPlayer: React.FC<Props> = ({ video, onVideoComplete }) => {
  const playerDiv = useRef<HTMLDivElement>(null)

  const handleVideoComplete = useCallback(() => {
    onVideoComplete(video._uid)
  }, [video, onVideoComplete])

  useEffect(() => {
    const videoPlayer = new Player(playerDiv.current, {
      id: video.linkToAsset.split("/")[3],
      autoplay: true,
      color: "#00A9E0",
      title: true,
      byline: false,
      width: 640,
    })

    videoPlayer.on("ended", handleVideoComplete)
    return () => videoPlayer.off("ended", handleVideoComplete)
  }, [video, handleVideoComplete])

  return (
    <Container>
      <div ref={playerDiv} />
    </Container>
  )
}

export default VimeoPlayer

import React from "react"
import styled from "styled-components"

const Container = styled.div<{
  backgroundColor?: string
}>`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: none;
  min-height: 100vh;
  width: 100vw;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.palette.mineShaft};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
    padding: 100px 60px;
    background-color: ${({ backgroundColor, theme }) =>
      backgroundColor || theme.palette.mineShaft};
    border: 2px solid;
    border-color: ${({ backgroundColor, theme }) =>
      backgroundColor || theme.palette.mineShaft};
    border-bottom-right-radius: 45px;
    text-align: center;
    white-space: pre-wrap;
    display: none;

    h1 {
      color: ${({ theme }) => theme.palette.goldBrown};
      font-family: ${({ theme }) => theme.fonts.title};
      font-size: 40px;
      letter-spacing: -0.8px;
      line-height: 50px;
      text-align: center;
    }

    p {
      color: ${({ theme }) => theme.palette.white};
      font-size: 20px;
      letter-spacing: -0.2px;
      line-height: 32px;
      text-align: center;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }

  .grid-2 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 1;
    border-bottom-left-radius: 0px;
    background-color: ${({ theme }) => theme.palette.white};

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row: 1;
      border: 2px solid ${({ theme }) => theme.palette.white};
      border-bottom-left-radius: 45px;
    }
  }

  .grid-3 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 2;
    text-align: center;
    padding: 50px;
    background-color: ${({ theme }) => theme.palette.white};
    border-top-right-radius: 45px;
    background-size: cover;
    background-repeat: no-repeat;
    display: none;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }
  }

  .grid-4 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 2;
    border-top-left-radius: 0px;

    background-color: ${({ theme }) => theme.palette.white};

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row: 2;
      border: 2px solid ${({ theme }) => theme.palette.white};
      border-top-left-radius: 45px;
    }
  }
`

const FormContainer = styled.div`
  padding: 70px 25px;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 1;

  place-items: center;
  text-align: left;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: 3 / 5;
    grid-row: 1 / 3;
    padding: 70px 60px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 70px 150px;
  }

  .headers {
    text-align: center;
    h3 {
      color: ${({ theme }) => theme.palette.mineShaft};
      font-family: ${({ theme }) => theme.fonts.title};
      font-size: 26px;
      text-align: center;
    }
  }
`

interface Props {
  heading: string
  subheading: string
  backgroundColor?: string
  image: string
}

const BlockGrid: React.FC<Props> = ({
  children,
  heading,
  subheading,
  backgroundColor = "#222222",
  image,
}) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <div className="grid-1">
        <div>
          <h1>{heading}</h1>
          <p>{subheading}</p>
        </div>
      </div>
      <div className="grid-2"></div>
      <div
        className="grid-3"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div className="grid-4"></div>
      <FormContainer>{children}</FormContainer>
    </Container>
  )
}

export default BlockGrid

import React, { useEffect, useRef, useState } from "react"
import { usePopper } from "react-popper"
import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Content = styled.div`
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  min-height: 50px;
  max-width: 90vw;
  background-color: ${({ theme }) => theme.palette.mineShaft};
  color: ${({ theme }) => theme.palette.white};
  font-size: 12px;
  line-height: 14px;
  border-radius: 9px;
  padding: 15px;
  z-index: 15;
  animation: ${fadeIn} 0.3s ease forwards;

  &.fadeOut {
    animation: ${fadeOut} 0.3s ease forwards;
  }

  .popperArrow {
    width: 10px;
    height: 10px;

    &:after {
      content: "";
      position: absolute;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: ${({ theme }) => theme.palette.mineShaft};
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }

  &[data-popper-placement^="top"] > .popperArrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > .popperArrow {
    top: -4px;
  }

  &[data-popper-placement^="left"] > .popperArrow {
    right: -4px;
  }

  &[data-popper-placement^="right"] > .popperArrow {
    left: -4px;
  }
`

interface Props {
  cypressTestId?: string
  open: boolean
  referenceElement: {
    current: HTMLElement | null
  }
  displayLeftSide?: boolean
  displayRightSide?: boolean
  customOffsetX?: number
  children: React.ReactNode
}

const ToolTip: React.FC<Props> = ({
  cypressTestId = "",
  open,
  referenceElement,
  displayLeftSide = false,
  displayRightSide = false,
  customOffsetX = 0,
  children,
}) => {
  const [toolTipShowing, setToolTipShowing] = useState(false)
  const toolTipElement = useRef<HTMLDivElement>(null)
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(
    referenceElement.current,
    toolTipElement.current,
    {
      placement: displayRightSide
        ? "right"
        : displayLeftSide
        ? "left"
        : "top-end",
      modifiers: [
        {
          name: "arrow",
          options: {
            element: arrowRef,
          },
        },
        {
          name: "offset",
          options: {
            offset: [customOffsetX, 12],
          },
        },
        {
          name: "preventOverflow",
          options: {
            padding: 10,
          },
        },
      ],
    }
  )

  useEffect(() => {
    if (open) {
      setToolTipShowing(true)
    } else {
      toolTipElement.current?.classList.add("fadeOut")
      setTimeout(() => {
        setToolTipShowing(false)
      }, 300)
    }
  }, [open])

  return toolTipShowing ? (
    <Content
      data-cy-test={cypressTestId}
      ref={toolTipElement}
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
      <div ref={setArrowRef} style={styles.arrow} className="popperArrow" />
    </Content>
  ) : null
}

export default ToolTip

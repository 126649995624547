import React from "react"
import styled from "styled-components"

import { Loader, Arrow } from "."

export type ButtonProps = {
  cypressTestId?: string
  color: string
  textColor: string
  minWidth?: string
  label?: string
  iconPath?: string
  type?: "button" | "reset" | "submit"
  onClick?: (e: React.MouseEvent) => void
  disabled?: boolean
  loading?: boolean
  loaderColor?: string
  borderColor?: string
  textAlign?: "left" | "center" | "right"
  className?: string
}

const Button: React.FC<ButtonProps> = ({
  cypressTestId,
  color,
  textColor,
  minWidth = "inherit",
  label,
  onClick,
  iconPath,
  type = "button",
  loading = false,
  loaderColor = "#FFFFFF",
  disabled = false,
  borderColor = "transparent",
  textAlign = "center",
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Container
        data-cy-test={cypressTestId}
        color={color}
        textColor={textColor}
        minWidth={minWidth}
        textAlign={textAlign}
        type={type}
        hasIcon={iconPath ? true : false}
        borderColor={borderColor}
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? (
          <Loader color={loaderColor} />
        ) : (
          <>{label ? <p className="button-text">{label}</p> : null}</>
        )}
        {iconPath === "arrow" ? (
          <div className="arrow-icon">
            <Arrow />
          </div>
        ) : iconPath ? (
          <svg
            width="100%"
            viewBox="0 0 18 18"
            preserveAspectRatio="xMidYMid meet"
            className="custom-icon"
          >
            <use href={iconPath} />
          </svg>
        ) : null}
      </Container>
    </Wrapper>
  )
}
export default Button

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: "center";
`
const Container = styled.button<{
  color: string
  textColor: string
  textAlign: "left" | "center" | "right"
  minWidth: string
  hasIcon: boolean
  borderColor: string
  disabled: boolean
}>`
  width: 100%;
  min-width: ${({ minWidth }) => minWidth || "inherit"};
  padding: ${({ hasIcon }) => (hasIcon ? "9px 20px" : "16px 20px")};
  border-radius: 6px;
  border: 1px solid ${({ borderColor }) => borderColor};
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: ${({ hasIcon, textAlign }) =>
    hasIcon ? "space-between" : textAlign};
  background-color: ${({ color }) => color || "inherit"};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  transition: opacity 0.2s;
  color: ${({ textColor, theme }) => textColor || theme.palette.white};

  .custom-icon {
    max-width: 18px;
  }

  .arrow-icon {
    transition: 0.2s;
  }

  &:hover {
    opacity: 0.8;

    .arrow-icon {
      transform: translateX(5px);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .button-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin: 0;
  }
`

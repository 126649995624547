import React, { useRef, useState } from "react"
import styled from "styled-components"

import { ToolTip } from "../../components"

const StatusBadge = styled.div`
  background-color: ${({ theme }) => theme.palette.tiara};
  text-transform: uppercase;
  padding: 6px 10px;
  flex: 1;
  text-align: center;
  font-family: "sharp-grotesk-25";
  font-size: 10px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.aquaDeep};
  border-radius: 5px;

  &.achieved {
    background-color: ${({ theme }) => theme.palette.porcelain};
  }
`

const CurrentStatusBadge = styled(StatusBadge)<{
  completionPercentage: number
}>`
  background-image: ${({ completionPercentage, theme }) =>
    `linear-gradient(to right, ${theme.palette.porcelain} ${completionPercentage}%, ${theme.palette.tiara} ${completionPercentage}%)`};
`

const LevelDetails = styled.p`
  max-width: 250px;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
`

interface Props {
  cypressTestId?: string
  badge: Reseller.GamificationRewardsBadge
  activatedMerchants: number
}

const GamificationLevelBadge = ({
  cypressTestId = "",
  badge,
  activatedMerchants,
}: Props): JSX.Element => {
  const levelBadge = useRef<HTMLDivElement>(null)
  const [showToolTip, setShowToolTip] = useState(false)

  return (
    <div
      data-cy-test={cypressTestId}
      style={{
        display: "flex",
        alignItems: "flex-start",
      }}
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
      onClick={() => setShowToolTip(true)}
    >
      <ToolTip
        cypressTestId={`${cypressTestId}-tooltip`}
        open={showToolTip}
        referenceElement={levelBadge}
      >
        <LevelDetails>{badge.description}</LevelDetails>
      </ToolTip>
      <div
        ref={levelBadge}
        style={{
          width: "max-content",
        }}
      >
        {badge.currentBadge ? (
          <CurrentStatusBadge
            completionPercentage={
              badge.maxMerchants === null
                ? 100
                : (activatedMerchants / badge.maxMerchants) * 100
            }
          >
            {badge.name}
          </CurrentStatusBadge>
        ) : (
          <StatusBadge
            className={
              badge.maxMerchants !== null &&
              activatedMerchants >= badge.maxMerchants
                ? "achieved"
                : undefined
            }
          >
            {badge.name}
          </StatusBadge>
        )}
      </div>
    </div>
  )
}

export default GamificationLevelBadge

import { useField, FieldHookConfig } from "formik"
import React, { useCallback } from "react"
import styled from "styled-components"

import RadioToggle from "./RadioToggle"

const Wrapper = styled.div`
  display: block;
`

const FieldError = styled.div`
  text-align: left;
  margin-bottom: 15px;
  font-size: 12px;
  pointer-events: none;
  color: red;
`

interface Props {
  options: {
    id: string
    label: string
    value: string
    defaultChecked?: boolean
  }[]
  size: "small" | "large"
  padded?: boolean
  OnRadioToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioToggleField = ({
  options,
  size,
  padded = false,
  OnRadioToggle,
  ...props
}: Props & FieldHookConfig<string>): JSX.Element => {
  const [field, meta, helpers] = useField(props)
  const hasError = meta.touched && meta.error

  const handleClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(e.target.value)
      if (OnRadioToggle) {
        OnRadioToggle(e)
      }
    },
    [helpers, OnRadioToggle]
  )

  return (
    <Wrapper {...field} {...props}>
      <RadioToggle
        name={props.name}
        options={options}
        size={size}
        padded={padded}
        OnRadioToggle={handleClick}
      />
      {hasError && <FieldError>{meta.error}</FieldError>}
    </Wrapper>
  )
}

export default RadioToggleField

import React, { useEffect } from "react"

import {
  useGetInvoiceCount,
  useGetNotifications,
  useGetPayrollCount,
  useGetSiteUnderMaintenance,
  useUser,
} from "../../auth/hooks"
import NavLink, { Link } from "./NavLink"

const Links = (): JSX.Element => {
  const { user, loading } = useUser()
  const [getPayrollCount, payrollCountData] = useGetPayrollCount()
  const [getInvoiceCount, invoiceCountData] = useGetInvoiceCount()
  const [notificationCountData] = useGetNotifications()
  const { siteUnderMaintenance } = useGetSiteUnderMaintenance()

  useEffect(() => {
    if (user?.profile?.profileType === "PRINCIPAL") {
      getPayrollCount()
    }

    if (user?.profile?.profileType !== "ASSOCIATE") {
      getInvoiceCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profile?.profileType])

  const profileActivated = !loading && user?.profile?.isProfileActivated

  const LINKS: Link[] = [
    {
      icon: "/images/nav/icon-dashboard.svg#base",
      label: "My dashboard",
      href: "/dashboard/",
      enabled: true,
      shouldShow: true,
      isExternal: false,
    },
    {
      icon: "/images/nav/icon-training.svg#base",
      label: "Training",
      href: "/dashboard/training/",
      enabled: true,
      shouldShow: true,
      isExternal: false,
    },
    {
      icon: "/images/nav/icon-notifications.svg#base",
      label: "Notifications",
      href: "/dashboard/notifications/",
      enabled: true,
      shouldShow: true,
      isExternal: false,
      notificationCount: notificationCountData?.length,
    },
    {
      icon: "/images/nav/icon-shop.svg#base",
      label: "Buy devices",
      href: process.env.GATSBY_SHOP_URL ? process.env.GATSBY_SHOP_URL : "",
      enabled: true,
      shouldShow: true,
      isExternal: true,
    },
    {
      icon: "/images/nav/icon-merchant-status.svg#base",
      label: "Merchant status",
      href: "/dashboard/merchant-status/",
      enabled: !siteUnderMaintenance && profileActivated,
      shouldShow: true,
      isExternal: false,
    },
    {
      icon: "/images/nav/icon-sendable-content.svg#base",
      label: "Sendable Content",
      href: "/dashboard/sendable-content/",
      enabled: profileActivated,
      shouldShow: process.env.GATSBY_SHOW_SENDABLE_CONTENT === "true",
      isExternal: false,
    },
    {
      icon: "/images/nav/icon-invoices.svg#base",
      label: "Invoices",
      href: "/dashboard/invoices/",
      enabled: !siteUnderMaintenance && profileActivated,
      shouldShow: user?.profile?.profileType !== "ASSOCIATE",
      isExternal: false,
      notificationCount: invoiceCountData?.created,
    },
    {
      icon: "/images/nav/icon-manage-associates.svg#base",
      label: "Manage Resellers",
      href: "/dashboard/manage-associates/",
      enabled: !siteUnderMaintenance && profileActivated,
      shouldShow: user?.profile?.profileType === "PRINCIPAL",
      isExternal: false,
    },
    {
      icon: "/images/nav/icon-support.svg#base",
      label: "Support Tracking",
      href: "/dashboard/support-tracking/",
      enabled: !siteUnderMaintenance && profileActivated,
      shouldShow: true,
      isExternal: false,
    },
    {
      icon: "/images/nav/icon-leaderboard.svg#base",
      label: "Leaderboard",
      href: "/dashboard/leaderboard/",
      enabled: !siteUnderMaintenance && profileActivated,
      shouldShow: true,
      isExternal: false,
    },
    {
      icon: "/images/nav/icon-payroll.svg#base",
      label: "Payroll",
      href: "/dashboard/principal-payroll/",
      enabled: !siteUnderMaintenance && profileActivated,
      shouldShow: user?.profile?.profileType === "PRINCIPAL",
      isExternal: false,
      notificationCount: payrollCountData?.unpaid,
    },
    {
      icon: "/images/nav/icon-invoices.svg#base",
      label: "Performance Reports",
      href: "/dashboard/associate-performance/",
      enabled: !siteUnderMaintenance && profileActivated,
      shouldShow: user?.profile?.profileType === "ASSOCIATE",
      isExternal: false,
    },
    {
      icon: "/images/nav/icon-associate-progress.svg#base",
      label: "Reseller Progress",
      href: "/dashboard/associate-progress/",
      enabled: !siteUnderMaintenance && profileActivated,
      shouldShow: user?.profile?.profileType === "PRINCIPAL",
      isExternal: false,
    },
  ]

  return (
    <>
      {LINKS.map((link) =>
        link.shouldShow ? (
          <NavLink
            cypressTestId={`main-nav-link-${link.label
              .toLowerCase()
              .replace(" ", "-")}`}
            key={link.label}
            link={link}
            siteUnderMaintenance={siteUnderMaintenance}
          />
        ) : null
      )}
    </>
  )
}

export default Links

import styled from "styled-components"

const InputHelpText = styled.p`
  margin-top: 5px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: normal;
  color: ${({ theme }) => theme.palette.mineShaft};
  opacity: 0.7;
`

export default InputHelpText

import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { NotificationCard } from "./index"

const Banner = styled.div.attrs(({ ref }) => ({
  ref: ref,
}))`
  margin-bottom: 30px;

  p {
    margin: 0;
  }

  .highlighted {
    color: ${({ theme }) => theme.palette.orangePeel};
    font-weight: 500;
  }
`

interface Props {
  children: React.ReactNode
  notificationState?: boolean
}

const PageNotificationBanner: React.FC<Props> = ({
  children,
  notificationState,
}) => {
  const [notificationShowing, setNotificationShowing] = useState(true)

  useEffect(() => {
    if (typeof notificationState === "boolean") {
      setNotificationShowing(notificationState)
    }
  }, [notificationState])

  return notificationShowing ? (
    <Banner>
      <NotificationCard onDismiss={() => setNotificationShowing(false)}>
        {children}
      </NotificationCard>
    </Banner>
  ) : null
}

export default PageNotificationBanner

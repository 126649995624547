import React from "react"
import styled from "styled-components"

const ToggleForm = styled.div<{
  size: "small" | "large"
  padded: boolean
}>`
  padding: ${({ padded }) => (padded ? "20px 0" : "0px")};
  margin: 0;

  .radio-buttons {
    min-width: 80px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      justify-content: flex-start;
      margin: 10px 0;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    display: none;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-right: 25px;
    cursor: pointer;
    font-size: ${({ size }) => (size === "small" ? "15px" : "20px")};
    line-height: ${({ size }) => (size === "small" ? "20px" : "24px")};
    display: inline-block;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid ${({ theme }) => theme.palette.cornflowerBlue};
    border-radius: 100%;
    background: ${({ theme }) => theme.palette.white};
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.palette.white};
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    border-color: ${({ theme }) => theme.palette.cornflowerBlue};
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  [type="radio"]:checked + label:before {
    background: ${({ theme }) => theme.palette.green};
    border-color: rgb(66 153 225 / 50%);
  }
`
interface Props {
  cypressTestId?: string
  name: string
  options: {
    id: string
    label: string
    value: string
    defaultChecked?: boolean
  }[]
  size: "small" | "large"
  padded?: boolean
  OnRadioToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioToggle = ({
  cypressTestId,
  name = "radioToggleInput",
  options,
  size,
  padded = false,
  OnRadioToggle,
}: Props): JSX.Element => {
  return (
    <ToggleForm size={size} padded={padded}>
      <div className="radio-buttons" onChange={OnRadioToggle}>
        {options.map((option) => (
          <div key={option.id} className="radio-button">
            <input
              data-cy-test={cypressTestId}
              id={option.id}
              type="radio"
              name={name}
              value={option.value}
              defaultChecked={option.defaultChecked || false}
            />
            <label htmlFor={option.id}>{option.label}</label>
          </div>
        ))}
      </div>
    </ToggleForm>
  )
}

export default RadioToggle

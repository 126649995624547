import { Formik } from "formik"
import React from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { useGetAssociates, useInviteAssociate } from "../auth/hooks"
import { Card, Input, Button } from "./index"

const Container = styled.div<{
  gridArea?: string
}>`
  grid-area: ${({ gridArea }) => (gridArea ? gridArea : null)};
  position: relative;
`

const Header = styled.h2<{
  external: boolean
}>`
  margin: ${({ external }) =>
    external ? "15px 0px 20px 0px" : "0px 0px 10px 0px"};
  font-size: ${({ external }) => (external ? "18px" : "16px")};
  font-weight: 500;
`

const InviteForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }
`

const validationSchema = Yup.object({
  firstName: Yup.string(),
  email: Yup.string().email("Invalid email address").required("Email required"),
})

interface FormProps {
  onInviteReseller?: () => void
}

const InviteFormContainer: React.FC<FormProps> = ({ onInviteReseller }) => {
  const inviteAssociate = useInviteAssociate()
  const { refetch } = useGetAssociates()

  return (
    <Formik
      initialValues={{
        firstName: "",
        email: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        try {
          const response = await inviteAssociate(values)
          if (response) {
            refetch({})
            if (onInviteReseller) {
              onInviteReseller()
            }
          }
        } catch (err) {
          console.warn(err)
        } finally {
          resetForm()
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <InviteForm onSubmit={handleSubmit}>
          <div style={{ flex: 1 }}>
            <Input placeholder="First Name" name="firstName" />
          </div>
          <div style={{ flex: 2 }}>
            <Input placeholder="Email Address *" name="email" type="email" />
          </div>
          <div style={{ flex: 1 }}>
            <Button
              label="Send Invite"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
              color="#222222"
              textColor="white"
              iconPath="arrow"
            />
          </div>
        </InviteForm>
      )}
    </Formik>
  )
}

interface Props {
  externalHeading: boolean
  gridArea?: string
  onInviteReseller?: () => void
}

const InviteAssociateCard: React.FC<Props> = ({
  externalHeading,
  gridArea,
  onInviteReseller = () => null,
}) => {
  return externalHeading ? (
    <Container gridArea={gridArea}>
      <Header external={externalHeading}>
        Send invite to a new associated Reseller
      </Header>
      <Card padding="20px 20px 10px 20px">
        <InviteFormContainer onInviteReseller={onInviteReseller} />
      </Card>
    </Container>
  ) : (
    <Card gridArea={gridArea}>
      <Header external={externalHeading}>
        Send invite to a new associated Reseller
      </Header>
      <InviteFormContainer onInviteReseller={onInviteReseller} />
    </Card>
  )
}

export default InviteAssociateCard

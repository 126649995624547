import { gql } from "@apollo/client"

export const USER_DETAILS_FRAGMENT = gql`
  fragment userDetailsFragment on UserNode {
    id
    pk
    firstName
    lastName
    mobileNumber
    email
    keyExpiresAt
    dateJoined
  }
`

export const PROFILE_FRAGMENT = gql`
  fragment profileFragment on UserNode {
    profile {
      id
      idNumber
      passportNumber
      profilePicture
      contractSigned
      verificationStatus
      trainingCompleted
      referralCode
      isProfileActivated
      isVatRegistered
      profileType
      principalFullName
      vatNumber
    }
  }
`

export const BANKING_DETAILS_FRAGMENT = gql`
  fragment bankingDetailsFragment on UserNode {
    bankingDetails {
      id
      bankName
      branchCode
      accountNumber
      accountType
    }
  }
`

export const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on UserNode {
    address {
      id
      firstName
      lastName
      companyName
      phoneNumber
      addressLine1
      addressLine2
      city
      country
      province
      postalCode
      type
      vatNumber
    }
  }
`

export const SALES_AREAS_FRAGMENT = gql`
  fragment salesAreasFragment on UserNode {
    salesAreas {
      placeId
      province
      longitude
      latitude
      suburbName
      radius
    }
  }
`

export const PREFERENCES_FRAGMENT = gql`
  fragment preferencesFragment on UserNode {
    preferences {
      id
      firstLanguage
      secondLanguages
      shirtSize
    }
  }
`

export const INVOICE_FRAGMENT = gql`
  fragment invoiceFragment on InvoiceOutput {
    id
    totalVatAmount
    totalAmountExclVat
    totalAmount
    status
    createdAt
    pdf
    lineItems {
      description
      quantity
      percentage
      itemAmount
      totalVatAmount
      totalAmountExclVat
      totalAmount
    }
  }
`

export const BUSINESS_FRAGMENT = gql`
  fragment businessFragment on BusinessOutput {
    id
    name
    vatNumber
    companyRegistrationNumber
    type
  }
`

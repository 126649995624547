import jwt_decode from "jwt-decode"

import { notify } from "../alert/actions"
import { store } from "../store"

export interface DecodedJwtToken {
  email: string
  exp: number
  origIat: number
}

export interface SessionToken {
  token: string
  decoded: DecodedJwtToken
}

export const storeSessionToken = (token: string): void => {
  const decoded: DecodedJwtToken = jwt_decode(token)
  localStorage.setItem(
    "session",
    JSON.stringify({
      token: token,
      decoded: decoded,
    })
  )
}

export const getSessionToken = (): SessionToken | null => {
  const token = localStorage.getItem("session")
  return token ? JSON.parse(token) : null
}

export const verifySession = (): SessionToken | null => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null

  const sessionToken = getSessionToken()
  const tokenExpiry = sessionToken?.decoded.exp

  if (tokenExpiry) {
    const now = Date.now() / 1000
    if (now >= tokenExpiry) {
      store.dispatch(
        notify({
          type: "warning",
          message: "please login again",
          title: "Session expired",
        })
      )
      return null
    }
  }
  return sessionToken
}

export const cleanQueryParam = (param: string | string[] | null): string => {
  if (!param) return ""
  let cleanedParam = param.toString()

  const malformedIndex = cleanedParam.indexOf("?")
  if (malformedIndex > -1) {
    cleanedParam = cleanedParam.substring(0, malformedIndex)
  }
  return cleanedParam
}

import { useField, FieldHookConfig } from "formik"
import React, { useEffect, useState, useMemo, useCallback } from "react"
import styled from "styled-components"

import { Loader, MultiSelectCheckbox } from "../components"
import { InputLabel, InputFieldError, InputContainer } from "./Input/index"

interface Props {
  label?: string
  placeholder: string
  options: Inputs.SelectOption[]
  excludedItem?: string
  loading?: boolean
  margin?: string
  disabled?: boolean
  disabledPlaceholder?: string
  emptyPlaceholder?: string
}

const MultiSelectInput = ({
  label,
  placeholder,
  options,
  excludedItem,
  loading,
  margin = "0px 0px 10px 0px",
  disabled = false,
  disabledPlaceholder = "Select an item",
  emptyPlaceholder = "No items selected",
  ...props
}: Props & FieldHookConfig<string[]>): JSX.Element => {
  const [field, meta, helpers] = useField(props)
  const hasError = meta.touched && meta.error
  const [focus, setFocus] = useState(false)
  const { setValue } = helpers

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputFocus = useCallback(() => {
    if (!disabled && !focus && !loading) {
      setFocus(true)
    } else {
      setFocus(false)
    }
  }, [disabled, focus, loading])

  const handleCheckboxSelected = useCallback(
    (selected: boolean, targetItem: string) => {
      if (selected) {
        const temp = [...field.value, targetItem]
        setValue(temp)
      } else {
        const temp = field.value.filter((language) => language !== targetItem)
        setValue(temp)
      }
    },
    [field.value, setValue]
  )

  const configureValue = useMemo(() => {
    if (excludedItem === "") {
      return disabledPlaceholder
    }

    let numValues = field.value.length
    if (field.value === []) {
      numValues = 0
    }

    if (numValues >= 3) {
      return `${numValues} selected`
    } else if (numValues === 2) {
      return `${field.value[0]}, ${field.value[1]}`
    } else if (numValues === 1) {
      return `${field.value[0]}`
    } else {
      return emptyPlaceholder
    }
  }, [field.value, excludedItem])

  const isInitiallySelected = useCallback(
    (option: string) => {
      const found = field.value.find((currLanguage) => currLanguage === option)
      if (found) {
        return true
      }
      return false
    },
    [field.value]
  )

  useEffect(() => {
    if (excludedItem) {
      handleCheckboxSelected(false, excludedItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedItem])

  return (
    <InputContainer margin={margin}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledContainer inputWidth="0px" inFocus={focus} hasError={!!hasError}>
        <input
          id="multiSelectInput"
          hidden
          {...field}
          placeholder={placeholder}
        />
        <FauxSelect
          htmlFor="multiSelectInput"
          hasValue={field.value.length > 0}
          inFocus={focus}
          onClick={handleInputFocus}
        >
          {configureValue}
          {loading ? (
            <Loader size="small" />
          ) : (
            <img
              src="/images/icons/icon-dropdown-arrow.svg"
              alt="Dropdown icon"
            />
          )}
        </FauxSelect>
      </StyledContainer>
      {hasError && <InputFieldError>{meta.error}</InputFieldError>}
      {focus ? (
        <DropdownList>
          <div
            className="list-backdrop"
            onClick={() => {
              setFocus(false)
            }}
          />
          <div className="list-content-wrapper">
            <div className="list-content">
              {options.map((option, index) =>
                excludedItem && option.value !== excludedItem ? (
                  <MultiSelectCheckbox
                    key={option.label}
                    id={`${option.label}${index}`}
                    label={option.label}
                    initialSelect={isInitiallySelected(option.value)}
                    onClick={handleCheckboxSelected}
                  />
                ) : null
              )}
            </div>
          </div>
        </DropdownList>
      ) : null}
    </InputContainer>
  )
}

export default MultiSelectInput

const StyledContainer = styled.div<{
  inFocus: boolean
  inputWidth: string
  hasError: boolean
}>`
  border: 1px solid
    ${({ hasError, inFocus, theme }) =>
      hasError
        ? theme.palette.red
        : inFocus
        ? theme.palette.mineShaft
        : theme.palette.cornflowerBlue};
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: ${({ inputWidth }) => inputWidth || "0px"};
`

const FauxSelect = styled.label<{
  hasValue: boolean
  inFocus: boolean
}>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ hasValue, theme }) =>
    hasValue ? theme.palette.black : theme.palette.baliHai};
  font-size: 14px;
  line-height: 22px;
  padding: 16px 18px 15px 18px;
  border: none;
  outline: none;
  transition: 0.1s;

  img {
    width: 12px;
    transform: ${({ inFocus }) =>
      inFocus ? "rotate(180deg)" : "rotate(0deg)"};
    opacity: ${({ inFocus }) => (inFocus ? "1" : "0.35")};
    margin-bottom: 0;
  }
`

const DropdownList = styled.div`
  .list-backdrop {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .list-content-wrapper {
    position: relative;
  }

  .list-content {
    width: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.palette.cornflowerBlue};
    padding: 10px;
    z-index: 1;
  }
`

import React, { useState } from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  .label {
    letter-spacing: 0;
    line-height: 18px;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.mineShaft};
    margin-bottom: 0px;

    &:hover {
      cursor: pointer;
    }
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  background-color: ${({ theme }) => theme.palette.white};
  padding: 18px 10px;
  border-radius: 3px;
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.palette.hummingBird};
  }

  .label {
    width: 100%;
    position: relative;
    cursor: pointer;
    text-align: left;

    span {
      margin-left: 15px;
    }
  }

  .label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.palette.cornflowerBlue};
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
  }

  input {
    display: none;
  }

  input:checked + label:before {
    border-color: ${({ theme }) => theme.palette.blue};
    background-color: ${({ theme }) => theme.palette.blue};
  }

  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 9px;
    width: 5px;
    height: 8px;
    border: solid ${({ theme }) => theme.palette.white};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

interface Props {
  id: string
  label: string
  initialSelect?: boolean
  onClick: (selected: boolean, label: string) => void
}

const MultiSelectCheckbox = ({
  id,
  label,
  initialSelect = false,
  onClick,
}: Props): JSX.Element => {
  const checkboxId = id
  const [selected, setSelected] = useState(
    initialSelect ? initialSelect : false
  )

  const handleSelect = () => {
    if (selected) {
      onClick(false, label)
      setSelected(false)
    } else {
      onClick(true, label)
      setSelected(true)
    }
  }

  return (
    <StyledContainer>
      <CheckboxWrapper>
        <input
          id={checkboxId}
          defaultChecked={selected}
          type="checkbox"
          name={label}
          onClick={handleSelect}
        />
        <label htmlFor={checkboxId} className="label">
          <span>{label}</span>
        </label>
      </CheckboxWrapper>
    </StyledContainer>
  )
}

export default MultiSelectCheckbox

import React from "react"
import styled, { keyframes } from "styled-components"

import { useSignOut } from "../../auth/hooks"
import LinkButton from "../LinkButton"
import Modal from "../Modal"
import { UnderMaintenanceContext } from "../Nav/UnderMaintenanceContext"

const scrollUp = keyframes`
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-120px);
  }
  20% {
    transform: translateY(-120px);
  }
  30% {
    transform: translateY(-240px);
  }
  40% {
    transform: translateY(-240px);
  }
  50% {
    transform: translateY(-360px);
  }
  60% {
    transform: translateY(-360px);
  } 
  70% {
    transform: translateY(-480px);
  }
  80% {
    transform: translateY(-480px);
  }
  90% {
    transform: translateY(-600px);
  }
  100% { 
    transform: translateY(-600px);
  }
`

const Container = styled.div`
  max-width: 700px;

  .sub-header {
    font-family: ${({ theme }) => theme.fonts.title};
  }

  .description-text {
    max-width: 80%;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
  }

  .button-wrapper {
    display: flex;
  }
`

const AnimatedHeader = styled.div`
  margin: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  margin-bottom: 25px;

  h1,
  p {
    font-family: ${({ theme }) => theme.fonts.title};
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 30px;
    margin-bottom: 0;
  }

  p {
    line-height: 100px;
  }

  .exclaimation-text-wrapper {
    height: 100px;
    overflow: hidden;
  }

  .exclaimation-text {
    color: ${({ theme }) => theme.palette.outrageousOrange};
    animation: ${scrollUp} 6s ease forwards infinite;
  }

  .exclaimation-text-phrase {
    white-space: nowrap;

    &:not(:last-child) {
      padding-bottom: 20px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    h1,
    p {
      font-size: 64px;
    }
  }
`

const exclaimations = [
  "Haibo!",
  "Ag, Nee!",
  "What kind?",
  "No, man!",
  "Eish!",
  "Haibo!",
]

interface Props {
  open: boolean
  onClose: () => void
}

const UnderMaintenanceModal: React.FC<Props> = ({ open, onClose }: Props) => {
  const signOut = useSignOut()

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <AnimatedHeader>
          <h1>We are under maintenance: </h1>
          <div className="exclaimation-text-wrapper">
            <div className="exclaimation-text">
              {exclaimations.map((phrase, index) => (
                <p
                  key={`${phrase}-${index}`}
                  className="exclaimation-text-phrase"
                >
                  {phrase}
                </p>
              ))}
            </div>
          </div>
        </AnimatedHeader>
        <h2 className="sub-header">Check back just now.</h2>
        <p className="description-text">
          We&apos;re working on content that would usually be available to you
          here. In the meantime, you have limited access to portal features like
          editing your profile info.
        </p>
        <div className="button-wrapper">
          <UnderMaintenanceContext.Consumer>
            {({ closeUnderMaintenanceModal }) => (
              <LinkButton
                color="black"
                padding="0px"
                onClick={() => {
                  closeUnderMaintenanceModal()
                  signOut()
                }}
              >
                Logout
              </LinkButton>
            )}
          </UnderMaintenanceContext.Consumer>
        </div>
      </Container>
    </Modal>
  )
}

export default UnderMaintenanceModal

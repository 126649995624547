import { useState, useEffect } from "react"
import useLocalStorage from "react-use-localstorage"

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const updateIsMobile = () => {
      if (window.innerWidth >= 992) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    }

    window.addEventListener("resize", updateIsMobile)
    updateIsMobile()
    return () => {
      window.removeEventListener("resize", updateIsMobile)
    }
  }, [])

  return isMobile
}

export const useSSRLocalStorage = (
  key: string,
  initial: string
): [string, React.Dispatch<string>] | [string] =>
  typeof window !== "undefined" ? useLocalStorage(key, initial) : [initial] // eslint-disable-line react-hooks/rules-of-hooks

export const useGooglePlacesAutocompleteAPI = (): boolean => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const existingScript = document.getElementById(
      "googlePlacesAutocompleteAPI"
    )
    if (existingScript) {
      setLoading(false)
    } else {
      const script = document.createElement("script")
      script.id = "googlePlacesAutocompleteAPI"
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_PLACES_API_KEY}&libraries=places&callback=initMap`
      script.async = true
      window.initMap = () => {
        setLoading(false)
      }
      document.head.appendChild(script)
    }
  }, [])

  return loading
}

export const useInfiniteScroll = (
  allRowsLoaded: boolean,
  currentLoader: {
    current: HTMLDivElement | null
  },
  handleLoadMore: () => void
): void => {
  useEffect(() => {
    let allowCallback = false
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    }

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            if (!allowCallback) {
              allowCallback = true
              handleLoadMore()
            }
          } else {
            if (allowCallback) {
              allowCallback = false
            }
          }
        })
      },
      observerOptions
    )

    const loader = currentLoader.current
    if (currentLoader.current) {
      if (loader && !allRowsLoaded) {
        observer.observe(loader)
      }
    }

    return () => observer.disconnect()
  }, [allRowsLoaded, currentLoader, handleLoadMore])
}

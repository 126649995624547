// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-associate-performance-tsx": () => import("./../../../src/pages/dashboard/associate-performance.tsx" /* webpackChunkName: "component---src-pages-dashboard-associate-performance-tsx" */),
  "component---src-pages-dashboard-associate-progress-tsx": () => import("./../../../src/pages/dashboard/associate-progress.tsx" /* webpackChunkName: "component---src-pages-dashboard-associate-progress-tsx" */),
  "component---src-pages-dashboard-contract-signature-processing-tsx": () => import("./../../../src/pages/dashboard/contract-signature-processing.tsx" /* webpackChunkName: "component---src-pages-dashboard-contract-signature-processing-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-invoices-tsx": () => import("./../../../src/pages/dashboard/invoices.tsx" /* webpackChunkName: "component---src-pages-dashboard-invoices-tsx" */),
  "component---src-pages-dashboard-leaderboard-tsx": () => import("./../../../src/pages/dashboard/leaderboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-leaderboard-tsx" */),
  "component---src-pages-dashboard-manage-associates-tsx": () => import("./../../../src/pages/dashboard/manage-associates.tsx" /* webpackChunkName: "component---src-pages-dashboard-manage-associates-tsx" */),
  "component---src-pages-dashboard-merchant-status-tsx": () => import("./../../../src/pages/dashboard/merchant-status.tsx" /* webpackChunkName: "component---src-pages-dashboard-merchant-status-tsx" */),
  "component---src-pages-dashboard-notifications-tsx": () => import("./../../../src/pages/dashboard/notifications.tsx" /* webpackChunkName: "component---src-pages-dashboard-notifications-tsx" */),
  "component---src-pages-dashboard-principal-payroll-tsx": () => import("./../../../src/pages/dashboard/principal-payroll.tsx" /* webpackChunkName: "component---src-pages-dashboard-principal-payroll-tsx" */),
  "component---src-pages-dashboard-profile-tsx": () => import("./../../../src/pages/dashboard/profile.tsx" /* webpackChunkName: "component---src-pages-dashboard-profile-tsx" */),
  "component---src-pages-dashboard-sendable-content-tsx": () => import("./../../../src/pages/dashboard/sendable-content.tsx" /* webpackChunkName: "component---src-pages-dashboard-sendable-content-tsx" */),
  "component---src-pages-dashboard-support-tracking-tsx": () => import("./../../../src/pages/dashboard/support-tracking.tsx" /* webpackChunkName: "component---src-pages-dashboard-support-tracking-tsx" */),
  "component---src-pages-dashboard-training-tsx": () => import("./../../../src/pages/dashboard/training.tsx" /* webpackChunkName: "component---src-pages-dashboard-training-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onfido-tsx": () => import("./../../../src/pages/onfido.tsx" /* webpackChunkName: "component---src-pages-onfido-tsx" */),
  "component---src-pages-principal-sign-up-tsx": () => import("./../../../src/pages/principal/sign-up.tsx" /* webpackChunkName: "component---src-pages-principal-sign-up-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */)
}


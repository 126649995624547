import React, { useState, useCallback } from "react"
import styled from "styled-components"

import { useUser } from "../../auth/hooks"
import LinkButton, { LinkButtonProps } from "../LinkButton"
import ToolTip from "../ToolTip"

const ReferralButton = styled(LinkButton)<LinkButtonProps>`
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.mineShaft};
  width: 100%;
  color: white;
  padding: 15px 20px;
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }
`

const ReferralCode = styled.p`
  color: ${({ theme }) => theme.palette.orangePeel};
  font-weight: normal;
  font-size: 11px;
  margin-top: 5px;
`

const BaseContainer = styled.div`
  position: relative;
`

const ToolTipContainer = styled.div`
  position: absolute;
  width: 100%;
  top: -66px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    top: -93px;
  }
`

interface Props {
  toggleReferralCodeModal: () => void
}

const ReferralLink = ({ toggleReferralCodeModal }: Props): JSX.Element => {
  const { user } = useUser()
  const [showToolTip, setShowTooltip] = useState(false)

  const handleClick = () => {
    if (!user?.profile?.isProfileActivated) return setShowTooltip(!showToolTip)
    toggleReferralCodeModal()
  }

  const handleShowTooltip = useCallback(() => {
    if (!user?.profile?.isProfileActivated) {
      setShowTooltip(true)
    }
  }, [setShowTooltip, user])

  const handleHideTooltip = useCallback(() => {
    if (showToolTip) {
      setShowTooltip(false)
    }
  }, [showToolTip])

  return (
    <BaseContainer>
      <ReferralButton
        cypressTestId="main-nav-link-referral-code"
        onClick={handleClick}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={handleHideTooltip}
        rightIcon={user?.profile?.isProfileActivated ? "arrow" : "lock"}
      >
        <div>
          <p>Send Unique Link</p>
          <ReferralCode>
            {user?.profile?.isProfileActivated
              ? user?.profile?.referralCode
              : "***********"}
          </ReferralCode>
        </div>
      </ReferralButton>
      <ToolTipContainer>
        <ToolTip
          cypressTestId="main-nav-link-referral-code-tooltip"
          open={showToolTip}
          referenceElement={{ current: null }}
        >
          <span>
            You have to update your profile, sign your contract <br />
            and complete all training to unlock your link
          </span>
        </ToolTip>
      </ToolTipContainer>
    </BaseContainer>
  )
}

export default ReferralLink

import { useLocation } from "@reach/router"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"

import { UnderMaintenanceContext } from "../Nav/UnderMaintenanceContext"
import NavNotificationChip from "./NavNotificationChip"

const LinkIcon = styled.svg`
  margin-right: 12px;
  color: ${({ theme }) => theme.palette.doveGrey};
  transition: color ease 0.2s;
`

const LinkWrapper = styled.div<{
  isEnabled: boolean
  underMaintenance: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f4f4f4;
  cursor: default;

  color: ${({ isEnabled }) =>
    isEnabled ? "inherit" : "rgba(103,103,103,0.5)"};

  a,
  div {
    display: flex;
    align-items: center;
  }

  &.active {
    font-weight: 500;
    ${LinkIcon} {
      color: ${({ theme }) => theme.palette.blue};
    }
  }

  ${({ isEnabled }) =>
    isEnabled &&
    css`
      &:hover ${LinkIcon} {
        color: ${({ theme }) => theme.palette.blue};
        cursor: pointer;
      }
    `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-bottom: none;
    padding-bottom: 10px;
  }
`

const LinkText = styled.span`
  font-size: 14px;
  line-height: 14px;
`

export interface Link {
  icon: string
  label: string
  href: string
  enabled?: boolean
  shouldShow: boolean
  isExternal: boolean
  notificationCount?: number
}

interface Props {
  cypressTestId?: string
  link: Link
  siteUnderMaintenance: boolean
}

const NavLink: React.FC<Props> = ({
  cypressTestId = "",
  link,
  siteUnderMaintenance,
}) => {
  const { pathname } = useLocation()

  const renderLinkContent = () => (
    <>
      <LinkIcon height={24} width={24}>
        <use href={link.icon} />
      </LinkIcon>
      <LinkText>{link.label}</LinkText>
    </>
  )

  return (
    <UnderMaintenanceContext.Consumer>
      {({ openUnderMaintenanceModal }) => (
        <LinkWrapper
          data-cy-test={cypressTestId}
          key={`nav-link-${link.label}`}
          className={pathname === link.href ? "active" : undefined}
          isEnabled={link.enabled ? true : false}
          underMaintenance={siteUnderMaintenance}
        >
          {!link.enabled ? (
            <div
              onClick={
                siteUnderMaintenance ? openUnderMaintenanceModal : undefined
              }
            >
              {renderLinkContent()}
            </div>
          ) : !link.isExternal ? (
            <GatsbyLink to={link.href}>{renderLinkContent()}</GatsbyLink>
          ) : (
            <a href={link.href} target="_blank" rel="noreferrer">
              {renderLinkContent()}
            </a>
          )}
          {link.notificationCount && link.notificationCount !== 0 ? (
            <NavNotificationChip count={link.notificationCount} />
          ) : null}
        </LinkWrapper>
      )}
    </UnderMaintenanceContext.Consumer>
  )
}

export default NavLink

import { useField, FieldHookConfig } from "formik"
import React, { useEffect, useState, useCallback } from "react"
import styled from "styled-components"

import { InputLabel, InputFieldError, InputContainer } from "./Input/index"

const StyledTextArea = styled.textarea<{
  inFocus: boolean
  hasValue: boolean
  isDisabled: boolean
  hasError: boolean
  resizable: boolean
}>`
  width: 100%;
  height: ${({ resizable }) => (resizable ? "auto" : "100px")};
  color: ${({ hasValue, theme }) =>
    hasValue ? theme.palette.black : theme.palette.baliHai};
  font-size: 14px;
  line-height: 18px;
  padding: 16px 18px 15px 18px;
  border: 1px solid
    ${({ hasError, inFocus, theme }) =>
      hasError
        ? theme.palette.red
        : inFocus
        ? theme.palette.mineShaft
        : theme.palette.cornflowerBlue};
  border-radius: 6px;
  text-align: left;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};
  resize: ${({ resizable }) => (resizable ? "both" : "none")};

  &:focus {
    outline: none;
  }
`

interface Props {
  label?: string
  placeholder: string
  resizable?: boolean
  margin?: string
}

const TextArea = ({
  label,
  placeholder,
  disabled,
  resizable = true,
  margin = "0px 0px 10px 0px",
  ...props
}: Props & FieldHookConfig<string>): JSX.Element => {
  const [field, meta, helpers] = useField(props)
  const hasValue = (field.value || "").length > 0
  const hasError = !!(meta.touched && meta.error)
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      helpers.setValue(event.target.value)
    },
    [helpers]
  )

  return (
    <InputContainer margin={margin}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledTextArea
        inFocus={focus}
        hasValue={hasValue}
        isDisabled={disabled ? disabled : false}
        hasError={hasError}
        onMouseDown={() => {
          setFocus(true)
        }}
        onBlurCapture={() => {
          setFocus(false)
        }}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
        onChange={handleChange}
        resizable={resizable}
      />
      {hasError && <InputFieldError>{meta.error}</InputFieldError>}
    </InputContainer>
  )
}

export default TextArea

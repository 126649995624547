import {
  useApolloClient,
  useMutation,
  useQuery,
  useLazyQuery,
  NetworkStatus,
} from "@apollo/client"
import { useLocation } from "@reach/router"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { useCallback, useEffect, useContext } from "react"

import { useNotify } from "../alert/hooks"
import {
  useSegmentTrackEvent,
  useSegmentReset,
  useSegmentSetUser,
} from "../analytics/hooks"
import { UnderMaintenanceContext } from "../components/Nav/UnderMaintenanceContext"
import { getErrorMessage } from "../utils/apollo"
import { mobileNumberToInternational } from "../utils/mobileNumber"
import { getUrlSearchParams } from "../utils/navigation"
import {
  AUTHENTICATE,
  CHANGE_PASSWORD,
  REGISTER,
  UPDATE_ADDRESS,
  UPDATE_PROFILE,
  UPDATE_BANKING_DEATAILS,
  UPDATE_PREFERENCES,
  UPDATE_SALES_AREAS,
  SEND_RESET_PASSWORD_EMAIL,
  RESET_PASSWORD,
  MERCHANT_REGISTER,
  PRINCIPAL_REGISTER,
  ASSOCIATE_REGISTER,
  APPROVE_INVOICE,
  UPDATE_PAYROLL_STATUS,
  SEND_ONFIDO_INSTRUCTIONS_EMAIL,
  INVITE_ASSOCIATE,
  ACCEPT_ASSOCIATE_TERMS,
  UPDATE_BUSINESS_TYPE,
} from "./mutations"
import {
  GET_USER,
  GET_BANKS,
  GET_BANK_ACCOUNT_TYPES,
  GET_LANGUAGES,
  GET_SHIRT_SIZES,
  GET_DOCUSIGN_SIGNING_URL,
  GET_YOCO_ADDRESS,
  GET_RESELLER_INVOICES,
  GET_INVOICE_COUNT,
  GET_MERCHANTS,
  GET_INVOICE_PDF,
  GET_ASSOCIATES,
  GET_COUNTRIES,
  GET_PAYROLLS,
  GET_PAYROLL_COUNT,
  EXPORT_PAYROLLS,
  GET_ASSOCIATE_PROGRESS,
  GET_REWARD_BADGES,
  GET_SUPPORT_TICKETS,
  GET_NOTIFICATIONS,
  GET_BUSINESS_MEMBER_LEADERBOARD,
  GET_INDIVIDUAL_LEADERBOARD,
  GET_RANKING,
  GET_PRINCIPAL_BUSINESS,
  GET_RANKING_LAST_UPDATED_AT_TIMESTAMP,
  GET_SITE_UNDER_MAINTENANCE,
  GET_ASSOCIATE_LINE_ITEMS,
  GET_POLICY_DOCUMENTS,
} from "./queries"
import { storeSessionToken } from "./utils"
interface SignUpPayload {
  email: string
  idNumber?: string
  passportNumber?: string
  phoneNumber: string
  firstName: string
  lastName: string
  password: string
  recruitmentCode?: string
  processing: boolean
}

interface PasswordPayload {
  oldPassword: string
  password: string
}

interface SignUpError {
  code: string
  message: string
}

export const useSignUp = (): ((payload: SignUpPayload) => Promise<boolean>) => {
  const [registerUser] = useMutation(REGISTER)
  const trackEvent = useSegmentTrackEvent()
  const notify = useNotify()
  const { signIn } = useSignIn()

  const signUp = async (payload: SignUpPayload) => {
    try {
      const userDetails = {
        email: payload.email?.toLowerCase(),
        idNumber: payload.idNumber || "",
        passportNumber: payload.passportNumber || "",
        mobileNumber: mobileNumberToInternational(payload.phoneNumber),
        firstName: payload.firstName,
        lastName: payload.lastName,
        password1: payload.password,
        password2: payload.password,
        recruitmentCode: payload.recruitmentCode,
      }

      const { data } = await registerUser({ variables: { input: userDetails } })
      const success = data?.register?.success

      if (success) {
        trackEvent("reseller_account_created")
        await signIn({
          email: payload.email.toLocaleLowerCase(),
          password: payload.password,
        })
      } else {
        const errorsArray: [SignUpError][] = Object.values(data.register.errors)
        const errorMessage = errorsArray[0][0]
        notify(
          "danger",
          "Failed to sign up",
          getErrorMessage(errorMessage, { showRateLimitingMessage: true })
        )
      }

      return !!success
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        notify(
          "danger",
          "Failed to sign up",
          getErrorMessage(error, { showRateLimitingMessage: true })
        )
      } else {
        notify("danger", "Failed to sign up")
      }
      return false
    }
  }

  return signUp
}

export const useChangePassword = (): ((
  payload: PasswordPayload
) => Promise<boolean>) => {
  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD, {
    context: { clientName: "private-api" },
  })

  const notify = useNotify()

  const changePassword = useCallback(
    async (payload: PasswordPayload) => {
      try {
        const passwordData = {
          oldPassword: payload.oldPassword,
          newPassword1: payload.password,
          newPassword2: payload.password,
        }

        const { data } = await changePasswordMutation({
          variables: { input: passwordData },
        })
        if (data?.changePassword?.success) {
          notify("success", "Password changed successfully")
          return true
        } else {
          if (data?.changePassword?.errors?.oldPassword) {
            notify(
              "danger",
              "Invalid old Password",
              "the old password you entered is incorrect"
            )
          } else if (data?.changePassword?.errors?.newPassword2) {
            notify(
              "danger",
              "Invalid new Password",
              data?.changePassword?.errors?.newPassword2[0].message
            )
          } else {
            notify("danger", "Unable to change your password")
          }
          return false
        }
      } catch (error) {
        console.error(error)
        return false
      }
    },
    [notify, changePasswordMutation]
  )

  return changePassword
}

interface MerchantRegisterPayload {
  email: string
  password: string
}

export const useMerchantRegister = (): ((
  payload: MerchantRegisterPayload
) => Promise<boolean>) => {
  const [merchantRegisterMutation] = useMutation(MERCHANT_REGISTER)

  const notify = useNotify()
  const { signIn } = useSignIn()
  const trackEvent = useSegmentTrackEvent()

  const merchantRegister = useCallback(
    async (payload: MerchantRegisterPayload) => {
      try {
        const merchantData = await merchantRegisterMutation({
          variables: {
            input: payload,
          },
        })
        if (
          merchantData.data?.merchantRegister?.__typename ===
          "MerchantRegisterSuccess"
        ) {
          await signIn({
            email: payload.email.toLocaleLowerCase(),
            password: payload.password,
          })
          notify("success", "Welcome to the Reseller program!")
          trackEvent("reseller_account_created")
          return true
        } else {
          notify(
            "danger",
            "Could not sign you in",
            getErrorMessage(merchantData.data?.merchantRegister, {
              showRateLimitingMessage: true,
            })
          )
          return false
        }
      } catch (error) {
        console.warn(error)
        if (error instanceof Error) {
          notify(
            "danger",
            "Could not sign you in",
            getErrorMessage(error, {
              showRateLimitingMessage: true,
            })
          )
        } else {
          notify("danger", "Could not sign you in")
        }
        return false
      }
    },
    [merchantRegisterMutation, notify, signIn, trackEvent]
  )

  return merchantRegister
}

interface PrincipalRegisterPayload {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  password: string
  idNumber: string
  passportNumber: string
  inviteToken: string
  businessName: string
  companyRegistration: string
  businessType: string
  vatNumber: string
}

export const usePrincipalRegister = (): ((
  payload: PrincipalRegisterPayload
) => Promise<boolean>) => {
  const [principalRegisterMutation] = useMutation(PRINCIPAL_REGISTER)

  const notify = useNotify()
  const { signIn } = useSignIn()
  const trackEvent = useSegmentTrackEvent()

  const principalRegister = useCallback(
    async (payload: PrincipalRegisterPayload) => {
      try {
        const userDetails = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email?.toLowerCase(),
          mobileNumber: mobileNumberToInternational(payload.phoneNumber),
          password1: payload.password,
          password2: payload.password,
          idNumber: payload.idNumber || "",
          passportNumber: payload.passportNumber || "",
          inviteToken: payload.inviteToken,
          businessName: payload.businessName,
          companyRegistrationNumber: payload.companyRegistration,
          businessType: payload.businessType,
          vatNumber: payload.vatNumber,
        }

        const principalData = await principalRegisterMutation({
          variables: {
            input: userDetails,
          },
        })
        if (principalData.data.principalRegister.success) {
          await signIn({
            email: payload.email.toLowerCase(),
            password: payload.password,
          })
          notify("success", "Welcome", `${payload.firstName}`)
          trackEvent("reseller_account_created", {
            type: "Principal",
          })
          return true
        } else {
          notify(
            "danger",
            "Could not register user",
            "please double check your details"
          )
        }
        return false
      } catch (error) {
        console.warn(error)
        return false
      }
    },
    [principalRegisterMutation, notify, signIn, trackEvent]
  )

  return principalRegister
}

interface AssociateRegisterPayload {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  password: string
  idNumber: string
  passportNumber: string
  inviteToken: string
}

export const useAssociateRegister = (): ((
  payload: AssociateRegisterPayload
) => Promise<boolean>) => {
  const [associateRegisterMutation] = useMutation(ASSOCIATE_REGISTER)

  const notify = useNotify()
  const { signIn } = useSignIn()
  const trackEvent = useSegmentTrackEvent()

  const associateRegister = useCallback(
    async (payload: AssociateRegisterPayload) => {
      try {
        const userDetails = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email?.toLowerCase(),
          mobileNumber: mobileNumberToInternational(payload.phoneNumber),
          password1: payload.password,
          password2: payload.password,
          idNumber: payload.idNumber || "",
          passportNumber: payload.passportNumber || "",
          inviteToken: payload.inviteToken,
        }

        const associateData = await associateRegisterMutation({
          variables: {
            input: userDetails,
          },
        })
        if (associateData.data.associateRegister.success) {
          await signIn({
            email: payload.email.toLowerCase(),
            password: payload.password,
          })
          notify("success", "Welcome", `${payload.firstName}`)
          trackEvent("reseller_account_created", {
            type: "Associate",
          })
          return true
        } else {
          const errorsArray: [SignUpError][] = Object.values(
            associateData.data.associateRegister.errors
          )
          const errorMessage = errorsArray[0][0]
          notify(
            "danger",
            "Failed to sign up",
            getErrorMessage(errorMessage, { showRateLimitingMessage: false })
          )
        }
        return false
      } catch (error) {
        console.warn(error)
        return false
      }
    },
    [associateRegisterMutation, notify, signIn, trackEvent]
  )

  return associateRegister
}

interface SignInPayload {
  email: string
  password: string
}

export const useSignIn = (): {
  signIn: (payload: SignInPayload) => Promise<boolean>
  loading: boolean
} => {
  const notify = useNotify()
  const segmentSetUser = useSegmentSetUser()
  const [authenticateUser, isLoading] = useMutation(AUTHENTICATE)
  const { refetch } = useUser()

  const signIn = async (payload: SignInPayload) => {
    try {
      const input = {
        email: payload.email?.toLowerCase(),
        password: payload.password,
      }
      const { data } = await authenticateUser({
        variables: { input },
      })

      if (data?.authenticate?.success) {
        storeSessionToken(data?.authenticate?.token)
        segmentSetUser(data?.authenticate?.user?.id, {
          email: data?.authenticate?.user?.email,
          name: `${data?.authenticate?.user?.firstName ?? ""}${
            data?.authenticate?.user?.lastName
              ? ` ${data?.authenticate?.user?.lastName}`
              : ""
          }`,
          type: data?.authenticate?.user?.profile?.profileType,
        })
        await refetch()
        return true
      } else {
        notify(
          "danger",
          "Failed to sign in",
          getErrorMessage(
            { message: "Please check your email and password" },
            { showRateLimitingMessage: true }
          )
        )
        return false
      }
    } catch (error) {
      console.warn(error)
      if (error instanceof Error) {
        notify(
          "danger",
          "Failed to sign in",
          getErrorMessage(error, { showRateLimitingMessage: true })
        )
      } else {
        notify("danger", "Failed to sign in")
      }
      return false
    }
  }

  return { signIn, loading: isLoading.loading }
}

export const useUser = (): {
  user: Reseller.User | null
  loading: boolean
  refetch: () => void
} => {
  const { data, loading, refetch } = useQuery(GET_USER, {
    context: {
      clientName: "private-api",
    },
  })

  return { user: data?.me, loading: loading, refetch: refetch }
}

const maintenancePageWhitelist = [
  "/dashboard/",
  "/dashboard/notifications/",
  "/dashboard/training/",
  "/dashboard/sendable-content/",
  "/dashboard/profile/",
]

export const useGetSiteUnderMaintenance = (): {
  maintenancePageWhitelist: string[]
  siteUnderMaintenance: boolean
  loading: boolean
  stopPolling: () => void
} => {
  const { data, loading, stopPolling } = useQuery(GET_SITE_UNDER_MAINTENANCE, {
    context: { clientName: "private-api" },
    fetchPolicy: "network-only",
    pollInterval: parseInt(
      process.env.GATSBY_UNDER_MAINTENANCE_POLLING_INTERVAL || "600000"
    ),
  })

  return {
    maintenancePageWhitelist,
    siteUnderMaintenance: data?.siteMaintenanceStatus?.[0]?.underMaintenance,
    loading,
    stopPolling,
  }
}

export const useMaintenanceGuard = (): void => {
  const { siteUnderMaintenance } = useGetSiteUnderMaintenance()
  const { openUnderMaintenanceModal } = useContext(UnderMaintenanceContext)

  useEffect(() => {
    if (siteUnderMaintenance) {
      if (
        window.location.pathname.includes("/dashboard/") &&
        !maintenancePageWhitelist.find(
          (path) => path === window.location.pathname
        )
      ) {
        openUnderMaintenanceModal()
        navigate("/dashboard/")
      }
    }
  }, [siteUnderMaintenance, openUnderMaintenanceModal])
}

export const useSignOut = (setNext = false): (() => Promise<void>) => {
  const client = useApolloClient()
  const location = useLocation()
  const resetAnalytics = useSegmentReset()
  const { stopPolling } = useGetSiteUnderMaintenance()

  const signOut = useCallback(async () => {
    if (setNext) {
      navigate(`/sign-in/?nextUrl=${location.pathname}`)
    } else {
      navigate("/")
    }
    await client.cache.reset()
    localStorage.clear()
    resetAnalytics()
    stopPolling()
  }, [client, resetAnalytics, setNext, location.pathname, stopPolling])

  return signOut
}

export interface UpdateProfilePayload {
  firstName?: string
  lastName?: string
  mobileNumber?: string
  email?: string
  idNumber?: string
  passportNumber?: string
  profilePicture?: File | undefined
  deletingProfilePicture?: boolean
}

export const useUpdateProfile = (): ((
  payload: UpdateProfilePayload
) => Promise<boolean>) => {
  const [updateProfileMutation] = useMutation(UPDATE_PROFILE, {
    context: { clientName: "private-api" },
  })
  const notify = useNotify()

  const updateProfile = useCallback(
    async (payload: UpdateProfilePayload) => {
      try {
        if (payload.mobileNumber)
          payload.mobileNumber = mobileNumberToInternational(
            payload.mobileNumber
          )

        const { data } = await updateProfileMutation({
          variables: { input: payload },
        })
        if (data?.updateProfile.__typename === "ProfileSuccess") {
          notify(
            "success",
            "Details saved",
            "your profile details have been saved"
          )
          return true
        } else {
          notify("danger", "Details not saved", data?.updateProfile.message)
          return false
        }
      } catch (err) {
        if (err instanceof Error && err.message !== "401") {
          console.warn(err)
          notify(
            "danger",
            "Details not saved",
            "we could not save your changes, please try again"
          )
        }
        return false
      }
    },
    [notify, updateProfileMutation]
  )

  return updateProfile
}

export const useUpdateBankingDetails = (): ((
  payload: Reseller.BankDetails
) => Promise<boolean>) => {
  const [updateBankingDetailsMutation] = useMutation(UPDATE_BANKING_DEATAILS, {
    context: { clientName: "private-api" },
  })
  const notify = useNotify()
  const trackEvent = useSegmentTrackEvent()

  const updateBankingDetails = useCallback(
    async (payload: Reseller.BankDetails) => {
      try {
        const { data } = await updateBankingDetailsMutation({
          variables: {
            input: {
              bankName: payload.bankName,
              accountNumber: payload.accountNumber,
              accountType: payload.accountType,
            },
          },
        })
        if (data?.updateBankingDetails.me.bankingDetails) {
          if (!payload.id) {
            trackEvent("reseller_bank_details_completed")
          }
          notify(
            "success",
            "Details saved",
            "your bank details have been saved"
          )
        } else {
          notify("danger", "Details not saved", "please try again")
        }
        return true
      } catch (err) {
        if (err instanceof Error && err.message !== "401") {
          console.warn(err)
          notify("danger", "Details not saved", "please try again")
        }
        return false
      }
    },
    [notify, trackEvent, updateBankingDetailsMutation]
  )

  return updateBankingDetails
}

export const useUpdateBusinessType = (): ((businessInfo: {
  type: Reseller.PrincipalBusinessType
  companyRegistrationNumber: string
}) => Promise<Reseller.Business | undefined>) => {
  const notify = useNotify()
  const [updateBusinessTypeMutation] = useMutation(UPDATE_BUSINESS_TYPE, {
    context: { clientName: "private-api" },
  })

  const updateBusinessType = useCallback(
    async (payload: {
      type: Reseller.PrincipalBusinessType
      companyRegistrationNumber: string
    }) => {
      try {
        const { data } = await updateBusinessTypeMutation({
          variables: { input: payload },
        })
        if (data.updateBusinessType.__typename === "BusinessTypeSuccess") {
          notify(
            "success",
            "Details saved",
            "your business type has been saved"
          )
          return data
        } else {
          throw "updateBusinessType error"
        }
      } catch (err) {
        if (err instanceof Error && err.message !== "401") {
          console.warn(err)
          notify("warning", "Business type not saved", "please try again")
        }
        return undefined
      }
    },
    [notify, updateBusinessTypeMutation]
  )

  return updateBusinessType
}

export const useUpdateAddress = (): ((
  payload: Reseller.Address
) => Promise<boolean>) => {
  const [updateAddressMutation] = useMutation(UPDATE_ADDRESS, {
    context: { clientName: "private-api" },
  })
  const trackEvent = useSegmentTrackEvent()
  const notify = useNotify()

  const updateAddress = useCallback(
    async (payload: Reseller.Address) => {
      try {
        const { data } = await updateAddressMutation({
          variables: {
            input: {
              ...payload,
              phoneNumber: mobileNumberToInternational(payload.phoneNumber),
            },
          },
        })

        if (data?.updateAddress.me.address) {
          if (!payload.id) {
            if (payload.type === "SHIPPING") {
              trackEvent("reseller_shippingaddress_completed")
            } else if (payload.type === "INVOICING") {
              trackEvent("reseller_billingaddress_completed")
            }
          }
          notify("success", "Address saved", "your address has been saved")
          return true
        } else {
          throw "updateAddress error"
        }
      } catch (err) {
        if (err instanceof Error && err.message !== "401") {
          console.warn(err)
          notify("warning", "Details not saved", "please try again")
        }
        return false
      }
    },
    [notify, updateAddressMutation, trackEvent]
  )

  return updateAddress
}

export const useUpdatePreferences = (): ((
  preferences: Reseller.Preferences
) => Promise<void>) => {
  const [updatePreferencesMutation] = useMutation(UPDATE_PREFERENCES, {
    context: { clientName: "private-api" },
  })
  const [updateSalesAreasMutation] = useMutation(UPDATE_SALES_AREAS, {
    context: { clientName: "private-api" },
  })
  const notify = useNotify()

  const updateUserPreferences = useCallback(
    async (payload: Reseller.Preferences) => {
      const preferencesPayload = {
        firstLanguage: payload.firstLanguage,
        secondLanguages: payload.secondLanguages,
        shirtSize: payload.shirtSize,
      }
      const salesAreasPayload = payload.salesAreas.map((area) => ({
        placeId: area.placeId,
        province: area.province,
        latitude: area.latitude,
        longitude: area.longitude,
        suburbName: area.suburbName,
        radius: area.radius,
      }))

      try {
        const { data: preferencesData } = await updatePreferencesMutation({
          variables: { input: preferencesPayload },
        })
        const { data: salesAreasData } = await updateSalesAreasMutation({
          variables: {
            input: {
              salesAreas: salesAreasPayload,
            },
          },
        })
        if (
          preferencesData.updatePreferences.__typename ===
            "PreferencesSuccess" &&
          salesAreasData.updateSalesAreas.__typename === "SalesAreaSuccess"
        ) {
          notify("success", "Details saved", "your preferences have been saved")
        } else {
          throw new Error("An error occured while updating preferences")
        }
      } catch (err) {
        // instanceof check is required for ts to recognize the error type
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "warning",
              "Unauthorized",
              "You are not authorized to perform this action"
            )
          } else {
            notify("warning", "Details not saved", "Please try again")
          }
        }
      }
    },
    [notify, updatePreferencesMutation, updateSalesAreasMutation]
  )

  return updateUserPreferences
}

export const useApproveInvoice = (): ((invoiceId: string) => Promise<void>) => {
  const [approveInvoiceMutation] = useMutation(APPROVE_INVOICE, {
    context: { clientName: "private-api" },
  })
  const notify = useNotify()
  const { refetch } = useGetInvoices()
  const [getInvoiceCount] = useGetInvoiceCount()

  const approveInvoice = useCallback(
    async (payload: string) => {
      try {
        const { data } = await approveInvoiceMutation({
          variables: { input: { id: payload } },
        })
        if (data.approveInvoice.__typename === "ApproveInvoiceSuccess") {
          notify("success", "Your invoice have been approved")
          refetch({
            status: undefined,
          })
          getInvoiceCount()
        } else {
          throw new Error("An error occured while approving invoice")
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "warning",
              "Unauthorized",
              "You are not authorized to perform this action"
            )
          } else {
            notify("warning", "Invoice not approved", "please try again")
          }
        }
      }
    },
    [notify, approveInvoiceMutation, refetch, getInvoiceCount]
  )

  return approveInvoice
}

export const useUpdatePayrollStatus = (): ((
  payrollIds: string[],
  status: Reseller.PayrollStatusType
) => Promise<void>) => {
  const [updatePayrollStatusMutation] = useMutation(UPDATE_PAYROLL_STATUS, {
    context: { clientName: "private-api" },
  })
  const notify = useNotify()
  const [getPayrollCount] = useGetPayrollCount()
  const { refetch } = useGetPayrolls()

  const updatePayrollStatus = useCallback(
    async (payrollIds: string[], status: Reseller.PayrollStatusType) => {
      try {
        const { data } = await updatePayrollStatusMutation({
          variables: { input: { userPayrollIds: payrollIds, status: status } },
        })
        if (data.updatePayrollStatus.__typename === "UpdatePayrollSuccess") {
          notify("success", `This payroll has been marked as ${status}`)
          refetch({
            status: undefined,
          })
          getPayrollCount()
        } else if (data.updatePayrollStatus.__typename === "NotFoundError") {
          notify(
            "danger",
            "The payroll you are trying to update could not be found"
          )
        } else {
          throw new Error("An error occured while updating payroll")
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "warning",
              "Unauthorized",
              "You are not authorized to perform this action"
            )
          } else {
            notify("warning", "Payroll not updated", "please try again")
          }
        }
      }
    },
    [notify, refetch, getPayrollCount, updatePayrollStatusMutation]
  )

  return updatePayrollStatus
}

export const useAcceptAssociateTerms = (): (() => Promise<void>) => {
  const [acceptAssociateTermsMutation] = useMutation(ACCEPT_ASSOCIATE_TERMS, {
    context: { clientName: "private-api" },
  })
  const notify = useNotify()

  const acceptAssociateTerm = useCallback(async () => {
    try {
      const { data } = await acceptAssociateTermsMutation({
        variables: { input: {} },
      })
      if (data.acceptAssociateTerms.__typename === "ProfileSuccess") {
        notify("success", "Your profile has been confirmed")
      } else {
        throw new Error("An error occured while confirming profile")
      }
    } catch (err) {
      if (err instanceof Error) {
        if (err.message === "401") {
          notify(
            "warning",
            "Unauthorized",
            "You are not authorized to perform this action"
          )
        } else {
          notify("warning", "Profile not confirmed", "please try again")
        }
      }
    }
  }, [notify, acceptAssociateTermsMutation])

  return acceptAssociateTerm
}

interface BankData {
  banks: Lookups.BankNode[]
}

export const useGetBanks = (): [BankData, boolean] => {
  const { data, loading } = useQuery(GET_BANKS)
  return [data, loading]
}

interface AccountTypeData {
  bankAccounts: Lookups.BankAccountNode[]
}

export const useGetBankAccountTypes = (): [AccountTypeData, boolean] => {
  const { data, loading } = useQuery(GET_BANK_ACCOUNT_TYPES)
  return [data, loading]
}

interface LanguageData {
  languages: Lookups.LanguageNode[]
}

export const useGetLanguages = (): [LanguageData, boolean] => {
  const { data, loading } = useQuery(GET_LANGUAGES)
  return [data, loading]
}

interface ShirtSizeData {
  shirtSizes: Lookups.ShirtSizeNode[]
}

export const useGetShirtSizes = (): [ShirtSizeData, boolean] => {
  const { data, loading } = useQuery(GET_SHIRT_SIZES)
  return [data, loading]
}

interface YocoAddressData {
  yocoAddress: Lookups.YocoAddressNode
}

export const useGetYocoAddress = (): [YocoAddressData, boolean] => {
  const { data, loading } = useQuery(GET_YOCO_ADDRESS, {
    context: { clientName: "private-api" },
  })
  return [data, loading]
}

interface CountryData {
  countries: Lookups.CountryNode[]
}

export const useGetCountries = (): [CountryData, boolean] => {
  const { data, loading } = useQuery(GET_COUNTRIES)

  return [data, loading]
}

interface RefetchMerchantsVariables {
  status?: Reseller.MerchantStatusType
  username?: string
}

export const useGetMerchants = (): [
  GraphQL_Relay.NodeConnection<
    Reseller.Merchant,
    "signedUpCount" | "fullyOnboardedCount" | "activeCount" | "totalCount"
  >,
  boolean,
  (variables: RefetchMerchantsVariables) => void,
  (fetchMoreOptions: { variables: { after: string } }) => Promise<unknown>
] => {
  const { data, loading, refetch, fetchMore } = useQuery(GET_MERCHANTS, {
    context: { clientName: "private-api" },
  })

  return [data?.merchants, loading, refetch, fetchMore]
}

export const useGetInvoices = (): {
  data: GraphQL_Relay.InvoiceNodeConnection<
    Reseller.Invoice,
    "all" | "created" | "approved" | "paid"
  >
  loading: boolean
  refetch: (variables: { status?: Reseller.InvoiceStatusType }) => void
  fetchMore: (fetchMoreOptions: {
    variables: { after: string }
  }) => Promise<unknown>
} => {
  const { data, loading, refetch, fetchMore } = useQuery(
    GET_RESELLER_INVOICES,
    {
      context: { clientName: "private-api" },
    }
  )

  return { data: data?.invoices, loading, refetch, fetchMore }
}

export const useGetInvoiceCount = (): [
  () => void,
  Reseller.InvoiceCount,
  boolean,
  boolean
] => {
  const [getInvoiceCount, { data, loading, called }] = useLazyQuery(
    GET_INVOICE_COUNT,
    {
      context: { clientName: "private-api" },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  )

  return [getInvoiceCount, data?.invoices?.count, loading, called]
}

export const useGetInvoicePdf = (): [
  (arg0: { variables: { id: string } }) => void,
  NetworkStatus,
  ((variables?: { id: string } | undefined) => void) | undefined
] => {
  const [getInvoicePdf, { data, networkStatus, refetch }] = useLazyQuery(
    GET_INVOICE_PDF,
    {
      context: { clientName: "private-api" },
      notifyOnNetworkStatusChange: true,
    }
  )

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && data?.invoicePdf) {
      window.open(data?.invoicePdf, "_blank")
    }
  }, [data, networkStatus])

  return [getInvoicePdf, networkStatus, refetch]
}

export const useGetAssociates = (): {
  data: GraphQL_Relay.NodeConnection<
    Reseller.Associate,
    "all" | "pending" | "activated" | "deactivated"
  >
  loading: boolean
  refetch: (variables: { status?: Reseller.AssociateStatusType }) => void
  fetchMore: (fetchMoreOptions: {
    variables: { after: string }
  }) => Promise<unknown>
} => {
  const { data, loading, refetch, fetchMore } = useQuery(GET_ASSOCIATES, {
    context: { clientName: "private-api" },
  })

  return {
    data: data?.associates,
    loading: loading,
    refetch: refetch,
    fetchMore: fetchMore,
  }
}

export const useGetPayrolls = (): {
  data: GraphQL_Relay.NodeConnection<
    Reseller.Payroll,
    "all" | "paid" | "unpaid"
  >
  loading: boolean
  refetch: (variables: { status?: Reseller.PayrollStatusType }) => void
  fetchMore: (fetchMoreOptions: {
    variables: { after: string }
  }) => Promise<unknown>
} => {
  const { data, loading, refetch, fetchMore } = useQuery(GET_PAYROLLS, {
    context: { clientName: "private-api" },
  })

  return {
    data: data?.payrolls,
    loading,
    refetch,
    fetchMore,
  }
}

export const useGetPayrollCount = (): [
  () => void,
  Reseller.PayrollCount,
  boolean,
  boolean
] => {
  const [getPayrollCount, { data, loading, called }] = useLazyQuery(
    GET_PAYROLL_COUNT,
    {
      context: { clientName: "private-api" },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  )

  return [getPayrollCount, data?.payrolls?.count, loading, called]
}

export const useExportPayrolls = (): [
  (arg0: { variables: { payrollIds: string[] } }) => void,
  NetworkStatus,
  ((variables?: { id: string } | undefined) => void) | undefined
] => {
  const [getExportedPayrolls, { data, networkStatus, refetch }] = useLazyQuery(
    EXPORT_PAYROLLS,
    {
      context: { clientName: "private-api" },
      notifyOnNetworkStatusChange: true,
    }
  )

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.ready &&
      data?.exportPayrolls &&
      !data?.exportPayrolls.includes("InternalServerError")
    ) {
      window.open(data?.exportPayrolls, "_blank")
    }
  }, [data, networkStatus])

  return [getExportedPayrolls, networkStatus, refetch]
}

export const useGetAssociateProgress = (): [
  GraphQL_Relay.NodeConnection<Reseller.AssociateProgress, "undefined">,
  boolean,
  (fetchMoreOptions: { variables: { after: string } }) => Promise<unknown>
] => {
  const { data, loading, fetchMore } = useQuery(GET_ASSOCIATE_PROGRESS, {
    context: { clientName: "private-api" },
  })

  return [data?.associateProgress, loading, fetchMore]
}

export const useGetSupportTickets = (): {
  data: GraphQL_Relay.NodeConnection<
    Reseller.SupportTicket,
    | "all"
    | "new"
    | "open"
    | "inProgress"
    | "merchantActionRequired"
    | "escalated"
    | "solved"
    | "closed"
  >
  loading: boolean
  refetch: (variables: {
    salesforceStatus?: Reseller.SupportTicketStatusType
  }) => void
  fetchMore: (fetchMoreOptions: {
    variables: { after: string }
  }) => Promise<unknown>
} => {
  const { data, loading, refetch, fetchMore } = useQuery(GET_SUPPORT_TICKETS, {
    context: { clientName: "private-api" },
  })

  return {
    data: data?.tickets,
    loading: loading,
    refetch: refetch,
    fetchMore: fetchMore,
  }
}

export const useSendResetPasswordEmail = (): ((payload: {
  email: string
}) => Promise<void>) => {
  const [sendResetPasswordEmailMutation] = useMutation(
    SEND_RESET_PASSWORD_EMAIL,
    {
      context: { clientName: "private-api" },
    }
  )
  const notify = useNotify()

  const sendResetPasswordEmail = useCallback(
    async (payload: { email: string }) => {
      try {
        const { data } = await sendResetPasswordEmailMutation({
          variables: { input: payload },
        })
        if (
          data.sendResetPasswordEmail.__typename ===
          "SendResetPasswordEmailSuccess"
        ) {
          notify("success", "Email sent", "check your inbox for the next step")
        } else {
          throw new Error("An error occured while sending reset password email")
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "warning",
              "Unauthorized",
              "You are not authorized to perform this action"
            )
          } else {
            notify("warning", "Email not sent", "please try again")
          }
        }
      }
    },
    [notify, sendResetPasswordEmailMutation]
  )
  return sendResetPasswordEmail
}

export const useResetPassword = (): ((payload: {
  password: string
}) => Promise<boolean>) => {
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD, {
    context: { clientName: "private-api" },
  })
  const notify = useNotify()

  const resetPassword = useCallback(
    async (payload: { password: string }) => {
      try {
        const { data } = await resetPasswordMutation({
          variables: { input: payload },
        })
        if (data.resetPassword.__typename === "ResetPasswordSuccess") {
          if (data.resetPassword.success) {
            notify(
              "success",
              "Rassword reset",
              "sign in using your new password"
            )
            return true
          } else {
            notify(
              "danger",
              "Password not reset",
              `${data.resetPassword.context}`
            )
            return false
          }
        } else {
          throw new Error("An error occured while resetting password")
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "warning",
              "Unauthorized",
              "You are not authorized to perform this action"
            )
          } else {
            notify("warning", "Password not reset", "please try again")
          }
        }
        return false
      }
    },
    [notify, resetPasswordMutation]
  )
  return resetPassword
}

export const useSendOnfidoInstructionsEmail = (): (() => Promise<void>) => {
  const [sendOnfidoInstructionsEmailMutation] = useMutation(
    SEND_ONFIDO_INSTRUCTIONS_EMAIL,
    {
      context: { clientName: "private-api" },
    }
  )
  const notify = useNotify()

  const sendOnfidoInstructionsEmail = useCallback(async () => {
    try {
      const { data } = await sendOnfidoInstructionsEmailMutation({
        variables: {},
      })
      if (
        data.onfidoInstructionsEmail.__typename ===
        "SendOnfidoInstructionsEmailSuccess"
      ) {
        notify("success", "Email sent", "check your inbox for the next step")
      } else {
        throw new Error(
          "An error occured while sending onfido instructions email"
        )
      }
    } catch (err) {
      if (err instanceof Error) {
        if (err.message === "401") {
          notify(
            "warning",
            "Unauthorized",
            "You are not authorized to perform this action"
          )
        } else {
          notify("warning", "Email not sent", "please try again")
        }
      }
    }
  }, [notify, sendOnfidoInstructionsEmailMutation])
  return sendOnfidoInstructionsEmail
}

export const useGetDocusignSigningUrl = (): [() => void, boolean] => {
  const [getDocusignSigningUrl, { data, loading, called }] = useLazyQuery(
    GET_DOCUSIGN_SIGNING_URL,
    {
      context: {
        clientName: "private-api",
      },
    }
  )
  const trackEvent = useSegmentTrackEvent()

  useEffect(() => {
    if (!loading && called && data?.docusignSigningUrl) {
      window.location = data?.docusignSigningUrl
    }
  }, [data, loading, called, trackEvent])

  return [getDocusignSigningUrl, loading]
}

export const useNextUrl = (): ((defaultNextUrl: string) => void) => {
  const location = useLocation()
  const pagesQuery = useStaticQuery<GatsbyQueries.AllSitePage>(graphql`
    query MyQuery {
      allSitePage {
        edges {
          node {
            path
          }
        }
      }
    }
  `)

  const nextUrl = useCallback(
    (defaultNextUrl: string) => {
      const queryParams = getUrlSearchParams(["nextUrl"])
      const nextPage = queryParams.nextUrl
      if (
        nextPage &&
        pagesQuery?.allSitePage?.edges?.findIndex((page) =>
          page.node.path.includes(nextPage as string)
        ) !== -1
      ) {
        navigate(`${nextPage}`)
      } else {
        console.warn(
          "No nextUrl found in query string. Navitaging to defaultNextUrl."
        )
        navigate(defaultNextUrl)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, pagesQuery]
  )

  return nextUrl
}

interface InviteAssociatePayload {
  email: string
  firstName: string
}

export const useInviteAssociate = (): ((
  payload: InviteAssociatePayload
) => Promise<boolean>) => {
  const [inviteAssociateMutation] = useMutation(INVITE_ASSOCIATE, {
    context: { clientName: "private-api" },
  })
  const notify = useNotify()

  const inviteAssociate = useCallback(
    async (payload: InviteAssociatePayload) => {
      try {
        const { data } = await inviteAssociateMutation({
          variables: {
            input: {
              email: payload.email?.toLowerCase(),
              name: payload.firstName,
            },
          },
        })
        if (data.inviteAssociate.__typename === "InviteAssociateSuccess") {
          notify(
            "success",
            "Email sent",
            `invited ${
              payload.firstName !== "" ? payload.firstName : payload.email
            } to become an associated Reseller`
          )
          return true
        }
        return true
      } catch (errorMessage) {
        notify("danger", "Invite not sent", `${errorMessage}`)
        return false
      }
    },
    [notify, inviteAssociateMutation]
  )
  return inviteAssociate
}

export const useGetGamificationProgress = (): {
  gamificationData: Reseller.GamificationProgress
  gamificationLoading: boolean
} => {
  const { data, loading } = useQuery(GET_REWARD_BADGES, {
    context: { clientName: "private-api" },
  })

  return { gamificationData: data?.rewardsBadge, gamificationLoading: loading }
}

export const useGetRanking = (
  skip = true
): [Reseller.IndividualRank[], boolean] => {
  const { data, loading } = useQuery(GET_RANKING, {
    skip: skip,
    context: { clientName: "private-api" },
  })

  return [data?.currentUserRanking, loading]
}

export const useGetNotifications = (): [
  Yoco_notifications.Notification[],
  boolean
] => {
  const { data, loading } = useQuery(GET_NOTIFICATIONS, {
    context: { clientName: "private-api" },
  })

  return [data?.notifications, loading]
}

export const useGetBusinessMemberLeaderboard = (): [
  GraphQL_Relay.NodeConnection<Reseller.BusinessMemberRank, "undefined">,
  boolean,
  (variables: { ordering: "descending" | "ascending" }) => void,
  (fetchMoreOptions: { variables: { after: string } }) => Promise<unknown>
] => {
  const { data, loading, refetch, fetchMore } = useQuery(
    GET_BUSINESS_MEMBER_LEADERBOARD,
    {
      context: { clientName: "private-api" },
    }
  )

  return [data?.businessMemberLeaderboard, loading, refetch, fetchMore]
}

export const useGetIndividualLeaderboard = (): [
  GraphQL_Relay.NodeConnection<Reseller.IndividualRank, "undefined">,
  boolean,
  (variables: { scopeType: Reseller.IndividualRankScopeType }) => void,
  (fetchMoreOptions: { variables: { after: string } }) => Promise<unknown>
] => {
  const { data, loading, refetch, fetchMore } = useQuery(
    GET_INDIVIDUAL_LEADERBOARD,
    {
      context: { clientName: "private-api" },
    }
  )

  return [data?.individualLeaderboard, loading, refetch, fetchMore]
}

export const useGetRankingsLastUpdatedAtTimestamp = (): [string, boolean] => {
  const { data, loading } = useQuery(GET_RANKING_LAST_UPDATED_AT_TIMESTAMP, {
    context: { clientName: "private-api" },
  })

  return [data?.rankingsLastUpdated, loading]
}

export const useGetPrincipalBusiness = (): [
  () => void,
  Reseller.Business,
  boolean,
  boolean
] => {
  const [getPrincipalBusiness, { data, loading, called }] = useLazyQuery(
    GET_PRINCIPAL_BUSINESS,
    {
      context: { clientName: "private-api" },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    }
  )

  return [getPrincipalBusiness, data?.business, loading, called]
}

export const useGetAssociatePayrollBreakdown = (): {
  associatePayrollItems: Reseller.AssociatePayrollBreakdown
  loading: boolean
  refetch: () => void
} => {
  const { data, loading, refetch } = useQuery(GET_ASSOCIATE_LINE_ITEMS, {
    context: {
      clientName: "private-api",
    },
  })

  return {
    associatePayrollItems: data?.associateLineItems,
    loading: loading,
    refetch: refetch,
  }
}

export const useGetPolicyDocuments = (): {
  policyDocumentData: Reseller.PolicyDocument[]
  loading: boolean
} => {
  const { data, loading } = useQuery(GET_POLICY_DOCUMENTS, {
    context: {
      clientName: "private-api",
    },
  })
  return { policyDocumentData: data?.policyDocuments, loading: loading }
}

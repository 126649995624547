export function validatePhoneNumber(phoneNumber?: string): boolean {
  if (!phoneNumber) {
    return false
  }

  try {
    const number = phoneNumber.replace(/ /g, "").replace(/[-]/g, "")
    const result = number.match(/^(\+27|0|)[6-8][0-9]{8}$/)
    return !!result
  } catch {
    return false
  }
}

export function formatMobileNumber(
  phoneNumber: string,
  format?: "NATIONAL" | "INTERNATIONAL"
): string {
  let formattedNumber = phoneNumber.replace(/ /g, "").replace(/[-]/g, "")

  if (format === "INTERNATIONAL") {
    if (formattedNumber.substring(0, 3) !== "+27") {
      if (formattedNumber.charAt(0) === "0") {
        formattedNumber = formattedNumber.substring(1)
      }
      formattedNumber = "+27" + formattedNumber
    }
    formattedNumber =
      formattedNumber.substring(0, 3) +
      " " +
      formattedNumber.substring(3, 5) +
      " " +
      formattedNumber.substring(5, 8) +
      " " +
      formattedNumber.substring(8)
  } else {
    if (formattedNumber.charAt(0) !== "0") {
      if (formattedNumber.substring(0, 3) === "+27") {
        formattedNumber = formattedNumber.substring(3)
      }
      formattedNumber = "0" + formattedNumber
    }
    formattedNumber =
      formattedNumber.substring(0, 3) +
      " " +
      formattedNumber.substring(3, 6) +
      " " +
      formattedNumber.substring(6)
  }
  return formattedNumber
}

export function mobileNumberToInternational(phoneNumber: string): string {
  const number = phoneNumber.replace(/ /g, "").replace(/[-]/g, "")
  if (number.substring(0, 3) === "+27") {
    return number
  } else {
    let formattedNumber = number
    if (number.charAt(0) === "0") {
      formattedNumber = formattedNumber.substring(1)
    }
    formattedNumber = "+27" + formattedNumber
    return formattedNumber
  }
}

import React, { useCallback, useState } from "react"
import styled from "styled-components"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { Card, Arrow } from "."

const Wrapper = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  .swiper-container {
    flex: 1;
    max-width: 100%;
    width: 100%;

    .swiper-slide {
      width: 100%;
      margin: 20px 0;

      border-left: 1px solid ${({ theme }) => theme.palette.tiara};
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      .swiper-slide {
        margin: 0;
      }
    }
  }
`

const CarouselControls = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 20px;
  border-radius: 20px;

  .navigation-arrows {
    display: flex;
    flex-direction: column;
  }

  .controlsLabel {
    font-size: 10px;
    line-height: 30px;
    color: ${({ theme }) => theme.palette.doveGrey};
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
    text-transform: uppercase;
    margin: 0;
  }
`

const ButtonWrapper = styled.div<{
  direction: "left" | "right"
}>`
  transition: transform 0.2s, opacity 0.2s, color 0.2s;

  &.disabled {
    color: ${({ theme }) => theme.palette.tiara};
    cursor: default;
  }

  &.active {
    opacity: 1;
    cursor: pointer;

    &:hover {
      transform: ${({ direction }) =>
        direction === "left" ? "translateX(5px)" : "translateX(-5px)"};
      opacity: 0.8;
    }
  }
`

interface Props {
  cards: {
    label: string
    children: React.ReactNode
  }[]
  className?: string
  swiperKey: string
}

const CardCarousel: React.FC<Props> = ({ cards, className, swiperKey }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const [nextButton, setNextButton] = useState<HTMLDivElement | null>(null)
  const [prevButton, setPrevButton] = useState<HTMLDivElement | null>(null)

  const prevButtonRef = useCallback((node) => {
    if (node !== null) {
      setPrevButton(node)
    }
  }, [])
  const nextButtonRef = useCallback((node) => {
    if (node !== null) {
      setNextButton(node)
    }
  }, [])

  const handleCarouselNavigation = (swiper: any) => {
    setActiveIndex(swiper.activeIndex)
  }

  return (
    <Wrapper className={className} padding="0px" backgroundColor="white">
      <CarouselControls>
        <div className="navigation-arrows">
          <ButtonWrapper
            ref={prevButtonRef}
            direction="right"
            className={activeIndex === 0 ? "disabled" : "active"}
          >
            <Arrow direction="right" />
          </ButtonWrapper>
          <ButtonWrapper
            ref={nextButtonRef}
            direction="left"
            className={
              activeIndex === cards?.length - 1 ? "disabled" : "active"
            }
          >
            <Arrow />
          </ButtonWrapper>
        </div>
        <p className="controlsLabel">{cards?.[activeIndex]?.label}</p>
      </CarouselControls>
      <div className="swiper-container">
        <Swiper
          navigation={{
            nextEl: nextButton,
            prevEl: prevButton,
          }}
          modules={[Navigation, Pagination]}
          slidesPerView={"auto"}
          spaceBetween={20}
          grabCursor={true}
          onSlideChangeTransitionEnd={handleCarouselNavigation}
          style={{
            borderRadius: "10px",
          }}
          className={`card-carousel-swiper-${swiperKey}`}
        >
          {cards?.map((card, index) => (
            <SwiperSlide key={`card-${index}`}>{card.children}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Wrapper>
  )
}

export default CardCarousel

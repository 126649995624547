export const getUrlSearchParams = (
  params?: string[]
): Record<string, string> => {
  const results: Record<string, string> = {}

  if (typeof window !== "undefined") {
    const urlSearchParams = new URLSearchParams(window.location.search)

    if (params) {
      params.forEach((param) => {
        const value = urlSearchParams.get(param)
        if (value) {
          results[param] = value
        }
      })
    } else {
      urlSearchParams.forEach((value, key) => {
        results[key] = value
      })
    }
  }

  return results
}

export const removeUrlSearchParams = (params?: string[]): void => {
  if (typeof window === "undefined") return

  const queryParams = getUrlSearchParams()

  if (params) {
    params.forEach((param) => {
      delete queryParams[param]
    })

    const urlSearchParams = new URLSearchParams(queryParams)

    window.history.pushState(
      null,
      "",
      `${window.location.pathname}?${urlSearchParams.toString()}`
    )
  } else {
    window.history.pushState(null, "", `${window.location.pathname}`)
  }
}

import React, { useEffect, useRef, useState } from "react"
import { usePopper } from "react-popper"
import styled from "styled-components"

const DropdownListWrapper = styled.div`
  z-index: 2;

  .list-content {
    min-width: 100%;
    width: max-content;
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #ccd5e0;
    padding: 10px;
    padding-bottom: 0;
  }
`

interface Props {
  open: boolean
  referenceElement: {
    current: HTMLDivElement | null
  }
  children: React.ReactNode
}

const DropdownList: React.FC<Props> = ({
  open,
  referenceElement,
  children,
}) => {
  const [dropdownListShowing, setDropdownListShowing] = useState(false)
  const dropdownListElement = useRef<HTMLDivElement>(null)
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(
    referenceElement.current,
    dropdownListElement.current,
    {
      placement: "bottom-end",
      modifiers: [
        {
          name: "arrow",
          options: {
            element: arrowRef,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  )

  useEffect(() => {
    if (open) {
      setDropdownListShowing(true)
    } else {
      setDropdownListShowing(false)
    }

    if (dropdownListElement.current) {
      setDropdownListShowing(false)
    }
  }, [open, dropdownListElement])

  return dropdownListShowing ? (
    <DropdownListWrapper
      ref={dropdownListElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <div className="list-content">{children}</div>
      <div ref={setArrowRef} style={styles.arrow} className="popperArrow" />
    </DropdownListWrapper>
  ) : null
}

export default DropdownList

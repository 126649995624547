import React from "react"
import styled, { css } from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

const Container = styled.div<{ pageFilter: boolean }>`
  .swiper-slide {
    height: 45px;
    width: auto;
  }

  ${({ pageFilter, theme }) =>
    pageFilter
      ? css`
          width: 100vw;
          margin-left: -${theme.margins.sm};
          margin-right: -${theme.margins.sm};

          @media screen and (min-width: ${theme.breakpoints.md}) {
            width: calc(100vw - 300px);
            margin-left: -${theme.margins.md};
            margin-right: -${theme.margins.md};
          }

          @media screen and (min-width: ${theme.breakpoints.lg}) {
            width: calc(100vw - 300px);
            margin-left: -${theme.margins.lg};
            margin-right: -${theme.margins.lg};
          }
        `
      : undefined};
`

const FilterItem = styled.div`
  padding: 15px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.mystic};
  border-radius: 10px;
  transition: background-color 0.2s;

  h3 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    margin: 0;
    z-index: 1;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.conch};
    cursor: pointer;
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.aquaDeep};
    cursor: default;

    h3 {
      font-weight: 500;
      color: white;
    }
  }
`

export interface FilterOption {
  title: string
  quantity?: number
  completed?: number
}

interface Props {
  filterOptions: FilterOption[]
  onClick: (target: number) => void
  activeIndex: number
  isPageFilter?: boolean
  className?: string
}

const Filter: React.FC<Props> = ({
  filterOptions,
  onClick,
  activeIndex = 0,
  isPageFilter = false,
  className,
}) => {
  const renderDetails = (currentFilter: FilterOption, filterIndex: number) => {
    if (currentFilter.quantity !== undefined) {
      if (
        currentFilter.completed !== undefined &&
        filterIndex === activeIndex
      ) {
        return ` (${currentFilter.completed}/${currentFilter.quantity})`
      } else {
        return ` (${currentFilter.quantity})`
      }
    }
  }

  return (
    <Container className={className} pageFilter={isPageFilter}>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={5}
        grabCursor={true}
        breakpoints={
          isPageFilter
            ? {
                0: {
                  slidesOffsetBefore: 28,
                  slidesOffsetAfter: 28,
                },
                992: {
                  slidesOffsetBefore: 35,
                  slidesOffsetAfter: 35,
                },
                1200: {
                  slidesOffsetBefore: 70,
                  slidesOffsetAfter: 70,
                },
              }
            : undefined
        }
      >
        {filterOptions.map((filter, index) => (
          <SwiperSlide key={filter.title}>
            <FilterItem
              key={`filter-item-${index}`}
              className={`filterItem ${activeIndex == index ? "active" : ""}`}
              onClick={() => {
                if (index !== activeIndex) {
                  onClick(index)
                }
              }}
            >
              <div className="itemSelector" />
              <h3>
                {filter.title}
                {renderDetails(filter, index)}
              </h3>
            </FilterItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}

export default React.memo(Filter)

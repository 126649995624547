import React, { useEffect, useState } from "react"
import styled from "styled-components"

const CheckboxWrapper = styled.div`
  input {
    display: none;
  }

  .label {
    position: relative;
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.palette.cornflowerBlue};
    padding: 11px;
    border-radius: 6px;
    cursor: pointer;
  }

  input:checked + label {
    background-color: ${({ theme }) => theme.palette.aquaDeep};
    border-color: ${({ theme }) => theme.palette.aquaDeep};
  }

  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 8px;
    width: 6px;
    height: 12px;
    border: solid ${({ theme }) => theme.palette.white};
    border-width: 0 2px 2.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

interface Props {
  cypressTestId?: string
  id: string
  name: string
  toggleCheckCallback?: (e: any) => void
  isChecked: boolean
  disabled?: boolean
  className?: string
}

const Checkbox = ({
  cypressTestId,
  id,
  name,
  toggleCheckCallback,
  isChecked,
  disabled = false,
  className,
}: Props): JSX.Element => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const handleCheckToggled = (e: any) => {
    setChecked(e.target.checked)
    if (toggleCheckCallback) {
      toggleCheckCallback(e)
    }
  }

  return (
    <CheckboxWrapper className={className}>
      <input
        id={`checkbox-${id}`}
        type="checkbox"
        name={name}
        value={id}
        onChange={handleCheckToggled}
        checked={checked}
        disabled={disabled}
      />
      <label
        htmlFor={`checkbox-${id}`}
        className="label"
        data-cy-test={cypressTestId}
      />
    </CheckboxWrapper>
  )
}

export default Checkbox

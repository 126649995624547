import styled from "styled-components"

const InputFieldError = styled.div`
  text-align: left;
  font-size: 12px;
  pointer-events: none;
  color: red;
`

export default InputFieldError

import { navigate } from "gatsby"
import React, { useEffect } from "react"

import { useUser, useSignOut, useMaintenanceGuard } from "../auth/hooks"
import { verifySession } from "../auth/utils"

interface Props {
  verifyUserProfile?: boolean
  allowedProfileTypes?: Reseller.ProfileType[]
}

const PageGuard: React.FC<Props> = ({
  verifyUserProfile = false,
  allowedProfileTypes = [],
  children,
}) => {
  const isBrowser = typeof window !== "undefined"

  const { user, loading } = useUser()
  const sessionToken = isBrowser ? verifySession() : undefined
  const signOut = useSignOut(true)

  // Verify Session Guard
  useEffect(() => {
    if (!sessionToken || (!loading && !user)) {
      signOut()
    }
  }, [sessionToken, loading, user, signOut])

  // Verify Profile Guard
  useEffect(() => {
    if (verifyUserProfile && !user?.profile?.isProfileActivated) {
      navigate("/dashboard/")
    }
  })

  // Allowed Profile Type Guard
  useEffect(() => {
    if (allowedProfileTypes && allowedProfileTypes.length > 0) {
      if (
        !user?.profile?.profileType ||
        !allowedProfileTypes.includes(user?.profile?.profileType)
      ) {
        navigate("/dashboard/")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profile?.profileType])

  // Site Under Maintenance Guard
  useMaintenanceGuard()

  return <>{user && children}</>
}

export default PageGuard

import React from "react"
import { useSelector } from "react-redux"

import { RootState } from "../store"
import Toast from "./components/Toast"
import { useDismiss } from "./hooks"

const Notification: React.FC = () => {
  const { notification } = useSelector((state: RootState) => state.notify)

  const dismiss = useDismiss()

  return (
    <>
      {notification ? (
        <Toast
          type={notification.type}
          title={notification.title}
          message={notification.message}
          onClose={dismiss}
        />
      ) : null}
    </>
  )
}

export default Notification

import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Arrow from "./Arrow"
import LockIcon from "./LockIcon"

interface BaseProps {
  className?: string
  leftIcon?: string
  color?: string
  themeColor?: string
  rightIcon?: "arrow" | "lock"
  padding?: string
  cypressTestId?: string
}

interface ToLinkProp extends BaseProps {
  to: string
  target?: "_blank"
}

interface MouseEventProps extends BaseProps {
  onClick: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export type LinkButtonProps = ToLinkProp | MouseEventProps

const LinkWrapper = styled.div<{
  color?: string
  themeColor?: string
  padding?: string
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: ${({ padding }) => padding};
  transition: opacity ease 300ms;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  color: ${({ color, themeColor, theme }) => {
    if (color) {
      return color
    } else if (themeColor) {
      return theme.palette.themeColor
    } else {
      return theme.palette.white
    }
  }};

  .arrow-icon {
    transition: 0.2s;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;

    .arrow-icon {
      transform: translateX(5px);
    }
  }
`

const LeftContent = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
`

const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  color,
  themeColor,
  className,
  leftIcon,
  rightIcon = "arrow",
  padding = "15px 20px",
  cypressTestId = "",
  ...props
}) => {
  const renderChildren = () => (
    <LinkWrapper
      className={className}
      color={color}
      themeColor={themeColor}
      padding={padding}
    >
      <LeftContent>
        {leftIcon ? <img src={leftIcon} alt="Left link button icon" /> : null}
        {children}
      </LeftContent>
      {rightIcon === "arrow" ? (
        <div className="arrow-icon">
          <Arrow />
        </div>
      ) : (
        <LockIcon />
      )}
    </LinkWrapper>
  )

  if ("to" in props && props.to !== "") {
    const linkPrefix = props.to.split("/")[0]
    if (linkPrefix === "https:" || linkPrefix === "http:") {
      return (
        <a
          data-cy-test={cypressTestId}
          href={props.to}
          target="_blank"
          rel="noreferrer"
        >
          {renderChildren()}
        </a>
      )
    } else {
      return (
        <Link data-cy-test={cypressTestId} {...props}>
          {renderChildren()}
        </Link>
      )
    }
  }

  return (
    <div data-cy-test={cypressTestId} {...props}>
      {renderChildren()}
    </div>
  )
}

export default LinkButton

import React from "react"

type Props = {
  dataCyTest?: string
  src: string
  alt: string
  height?: string
  width?: string
}

const ProfileImage = ({
  src,
  alt,
  width = "",
  height = "",
  dataCyTest = "",
}: Props): JSX.Element => {
  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ): void => {
    const target = event.target as HTMLImageElement
    target.onerror = null
    target.src = "/images/icons/icon-default-profile.svg"
  }

  return (
    <img
      data-cy-test={dataCyTest}
      src={src}
      alt={alt}
      width={width}
      height={height}
      onError={handleImageError}
    />
  )
}

export default ProfileImage

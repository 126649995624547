import { useLocation } from "@reach/router"
import React from "react"
import styled from "styled-components"

import {
  useGetGamificationProgress,
  useUser,
  useGetSiteUnderMaintenance,
} from "../../auth/hooks"
import { LinkButton, Chip, ProfileImage } from "../../components"
import GamificationLevelBadge from "./GamificationLevelBadge"

const Wrapper = styled.div`
  border-radius: 16px;
  border: 2px solid ${({ theme }) => theme.palette.mystic};
  padding: 16px;
  margin-bottom: 15px;
`

const UserInfoContainer = styled.div`
  display: grid;
  grid-template-areas:
    "profile names"
    "profile status"
    "sales sales";
  grid-template-columns: max-content 1fr;
  column-gap: 14px;
  row-gap: 8px;
`

const UserProfilePic = styled.div`
  grid-area: profile;
  width: fit-content;

  img {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
`

const UserName = styled.p`
  grid-area: names;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
`

const ChipContainer = styled.div`
  grid-area: status;
  max-width: min-content;
`

const StatusText = styled.div`
  grid-area: sales;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 8px;
    line-height: auto;
  }
`

const StatusContainer = styled.div`
  grid-area: sales;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 4px;
  }
`

const BadgeContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const LinkButtonContainer = styled.div`
  margin-top: 16px;
`

interface Props {
  CloseNav: () => void
}

const UserProfile = ({ CloseNav }: Props): JSX.Element => {
  const { siteUnderMaintenance } = useGetSiteUnderMaintenance()
  const { user, loading } = useUser()
  const { pathname } = useLocation()
  const { gamificationData } = useGetGamificationProgress()

  const currentLevel = gamificationData?.badges?.find(
    (badge) => badge.currentBadge === true
  )

  return (
    <Wrapper data-cy-test="main-nav-profile">
      <UserInfoContainer>
        <UserProfilePic>
          <ProfileImage
            dataCyTest="main-nav-profile-picture"
            src={
              !loading && user?.profile?.profilePicture
                ? user.profile.profilePicture
                : "/images/icons/icon-default-profile.svg"
            }
            alt="Reseller profile picture"
            width="54px"
            height="54px"
          />
        </UserProfilePic>
        <UserName data-cy-test="main-nav-profile-name">
          {user?.firstName} {user?.lastName}
        </UserName>
        <ChipContainer>
          {!loading && user?.profile?.isProfileActivated ? (
            <Chip
              cypressTestId="nav-profile-active-chip"
              size="small"
              backgroundColor="#018668"
              borderColor="#018668"
              fontFamily="Grotesk25"
              text="ACTIVE"
              textColor="#fff"
            />
          ) : (
            <Chip
              cypressTestId="nav-profile-incomplete-chip"
              size="small"
              backgroundColor="#FA5D37"
              borderColor="#FA5D37"
              fontFamily="Grotesk25"
              text="INCOMPLETE"
              textColor="#fff"
            />
          )}
        </ChipContainer>
        {!siteUnderMaintenance && gamificationData?.badges ? (
          <StatusContainer>
            <StatusText data-cy-test="nav-current-reward-level">
              This month&apos;s sales level: {currentLevel?.name}
            </StatusText>
            <BadgeContainer>
              {gamificationData?.badges?.map((badge, index) => (
                <GamificationLevelBadge
                  cypressTestId={`nav-reward-badge-${badge.name.toLowerCase()}`}
                  key={`level-badge-${index}`}
                  activatedMerchants={gamificationData?.activatedMerchants}
                  badge={badge}
                />
              ))}
            </BadgeContainer>
          </StatusContainer>
        ) : null}
      </UserInfoContainer>
      <LinkButtonContainer>
        {pathname === "/dashboard/profile/" ? (
          <LinkButton
            cypressTestId="main-nav-link-profile"
            onClick={CloseNav}
            color="#222222"
            padding="0px"
          >
            In My Profile
          </LinkButton>
        ) : (
          <LinkButton
            cypressTestId="main-nav-link-profile"
            to="/dashboard/profile/"
            color="#222222"
            padding="0px"
          >
            View My Profile
          </LinkButton>
        )}
      </LinkButtonContainer>
    </Wrapper>
  )
}

export default UserProfile

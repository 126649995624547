import { useField, FieldHookConfig } from "formik"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import {
  InputLabel,
  InputFieldError,
  InputHelpText,
  InputContainer,
} from "./Input/index"

interface Props {
  cypressTestId?: string
  label?: string
  placeholder: string
  additionalLabel?: string
  helpText?: string
  iconPath?: string
  iconSize?: "small" | "large"
  disabled?: boolean
  readOnly?: boolean
  margin?: string
  handleHasValue?: (value: boolean) => void
}

const Input = ({
  cypressTestId,
  label,
  placeholder,
  type,
  additionalLabel = "",
  helpText,
  iconPath,
  iconSize = "large",
  disabled = false,
  readOnly = false,
  margin = "0px 0px 10px 0px",
  handleHasValue,
  ...props
}: Props & FieldHookConfig<string>): JSX.Element => {
  const [field, meta, helpers] = useField(props)

  const isPhoneNumber = additionalLabel.length > 0
  const hasValue = !!field?.value
  const hasError = meta.touched && meta.error
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (handleHasValue) {
      if (hasValue) {
        handleHasValue(true)
      } else {
        handleHasValue(false)
      }
    }
  }, [hasValue, handleHasValue])

  return (
    <InputContainer margin={margin} data-cy-test="input-container">
      {label && <InputLabel>{label}</InputLabel>}
      <StyledInput
        inputWidth={isPhoneNumber ? "53px" : "0px"}
        inFocus={focus}
        hasValue={hasValue}
        hasTopLabel={!!label}
        hasError={!!hasError}
      >
        {isPhoneNumber && <AddLabel>{additionalLabel}</AddLabel>}
        {iconPath ? (
          <img
            style={{
              width: iconSize === "large" ? "30px" : "18px",
              marginBottom: "0",
              marginLeft: iconSize === "large" ? "10px" : "18px",
            }}
            src={iconPath}
            alt="icon"
          />
        ) : null}
        <input
          data-cy-test={cypressTestId}
          onFocus={() => {
            if (!readOnly && !disabled) {
              setFocus(true)
            }
          }}
          onBlurCapture={() => {
            setFocus(false)
          }}
          type={type}
          {...field}
          placeholder={label && placeholder ? placeholder : ""}
          readOnly={readOnly}
          disabled={disabled}
        />
        {!label ? (
          <StyledLabel
            hasValue={hasValue}
            isPhoneNumber={isPhoneNumber}
            inFocus={focus}
          >
            {placeholder}
          </StyledLabel>
        ) : null}
      </StyledInput>
      {hasError && (
        <InputFieldError data-cy-test="input-error-field">
          {meta.error}
        </InputFieldError>
      )}
      {helpText && <InputHelpText>{helpText}</InputHelpText>}
    </InputContainer>
  )
}

export default Input

const AddLabel = styled.div`
  background-color: ${({ theme }) => theme.palette.mineShaft};
  color: white;
  line-height: 55px;
  height: 55px;
  width: 55px;
  border-radius: 5px;
  z-index: 4;
  text-align: center;
`

const StyledInput = styled.div<{
  inFocus: boolean
  hasValue: boolean
  hasTopLabel: boolean
  inputWidth: string
  hasError: boolean
}>`
  border: 1px solid
    ${({ hasError, inFocus, theme }) =>
      hasError
        ? theme.palette.red
        : inFocus
        ? theme.palette.mineShaft
        : theme.palette.cornflowerBlue};
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  overflow: hidden;

  input {
    flex: 1;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ hasValue, theme }) =>
      hasValue ? theme.palette.black : theme.palette.baliHai};
    font-size: 14px;
    line-height: 22px;
    padding: 0 18px;
    border: none;
    outline: none;
    transition: 0.1s;
    padding-top: ${({ hasValue, hasTopLabel }) =>
      hasValue && !hasTopLabel ? "22px" : "16px"};
    padding-bottom: ${({ hasValue, hasTopLabel }) =>
      hasValue && !hasTopLabel ? "9px" : "15px"};
    width: 100%;

    &:read-only,
    &:disabled {
      color: ${({ theme }) => theme.palette.baliHai};
      -webkit-text-fill-color: ${({ theme }) => theme.palette.baliHai};
      opacity: 1;
    }
  }
`

const StyledLabel = styled.span<{
  isPhoneNumber: boolean
  hasValue: boolean
  inFocus: boolean
}>`
  position: absolute;
  top: 0;
  left: ${({ isPhoneNumber }) => (isPhoneNumber ? "71px" : "18px")};
  font-size: 14px;
  transform: translateY(20px);
  transition: all ease 200ms;
  color: ${({ theme }) => theme.palette.baliHai};
  pointer-events: none;
  white-space: nowrap;
  ${({ hasValue, inFocus }) =>
    hasValue || inFocus
      ? css`
          font-size: 10px;
          transform: translateY(8px);
          opacity: 1;
        `
      : ""}
`

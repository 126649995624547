import { gql } from "@apollo/client"

import {
  USER_DETAILS_FRAGMENT,
  PROFILE_FRAGMENT,
  BANKING_DETAILS_FRAGMENT,
  ADDRESS_FRAGMENT,
  SALES_AREAS_FRAGMENT,
  PREFERENCES_FRAGMENT,
  INVOICE_FRAGMENT,
  BUSINESS_FRAGMENT,
} from "./fragments"

export const AUTHENTICATE = gql`
  ${USER_DETAILS_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${BANKING_DETAILS_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${SALES_AREAS_FRAGMENT}
  ${PREFERENCES_FRAGMENT}
  mutation Authenticate($input: ObtainJSONWebTokenInput!) {
    authenticate(input: $input) {
      token
      success
      user {
        id
        ...userDetailsFragment
        ...profileFragment
        ...bankingDetailsFragment
        ...addressFragment
        ...salesAreasFragment
        ...preferencesFragment
      }
      errors
    }
  }
`

export const REGISTER = gql`
  mutation RegisterUser($input: RegisterMutationInput!) {
    register(input: $input) {
      success
      errors
      clientMutationId
    }
  }
`

export const MERCHANT_REGISTER = gql`
  ${USER_DETAILS_FRAGMENT}
  mutation RegisterMerchant($input: MerchantRegisterMutationInput!) {
    merchantRegister(input: $input) {
      ... on MerchantRegisterSuccess {
        me {
          id
          ...userDetailsFragment
        }
      }

      ... on InternalServerError {
        message
      }

      ... on InvalidMerchantLoginCredentials {
        message
      }
    }
  }
`

export const PRINCIPAL_REGISTER = gql`
  mutation RegisterPrincipal($input: PrincipalRegisterMutationInput!) {
    principalRegister(input: $input) {
      success
      errors
    }
  }
`

export const ASSOCIATE_REGISTER = gql`
  mutation RegisterAssociate($input: AssociateRegisterMutationInput!) {
    associateRegister(input: $input) {
      success
      errors
    }
  }
`

export const UPDATE_BANKING_DEATAILS = gql`
  ${BANKING_DETAILS_FRAGMENT}
  mutation updateBankingDetailsMutation(
    $input: UpdateBankingsDetailsMutationInput!
  ) {
    updateBankingDetails(input: $input) {
      ... on InternalServerError {
        message
      }
      ... on BankingDetailsSuccess {
        me {
          id
          ...bankingDetailsFragment
        }
      }
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: PasswordChangeInput!) {
    changePassword(input: $input) {
      success
      errors
      token
    }
  }
`
export const UPDATE_PROFILE = gql`
  ${USER_DETAILS_FRAGMENT}
  ${PROFILE_FRAGMENT}
  mutation updateProfileMutation($input: UpdateProfileMutationInput!) {
    updateProfile(input: $input) {
      ... on InternalServerError {
        message
      }
      ... on ProfileSuccess {
        me {
          id
          ...userDetailsFragment
          ...profileFragment
        }
      }
    }
  }
`

export const UPDATE_ADDRESS = gql`
  ${ADDRESS_FRAGMENT}
  mutation UpdateAddress($input: UpdateAddressMutationInput!) {
    updateAddress(input: $input) {
      ... on InternalServerError {
        message
      }
      ... on AddressSuccess {
        me {
          id
          ...addressFragment
        }
      }
    }
  }
`

export const UPDATE_BUSINESS_TYPE = gql`
  ${BUSINESS_FRAGMENT}
  mutation updateBusinessType($input: UpdateBusinessTypeMutationInput!) {
    updateBusinessType(input: $input) {
      ... on BusinessTypeSuccess {
        business {
          ...businessFragment
        }
      }
    }
  }
`

export const UPDATE_PREFERENCES = gql`
  mutation updatePreferences($input: UpdatePreferencesMutationInput!) {
    updatePreferences(input: $input) {
      ... on PreferencesSuccess {
        me {
          id
          ...preferencesFragment
        }
      }
      ... on InternalServerError {
        message
      }
    }
  }
  ${PREFERENCES_FRAGMENT}
`

export const UPDATE_SALES_AREAS = gql`
  ${SALES_AREAS_FRAGMENT}
  mutation updateSalesAreasMutation($input: UpdateSalesAreasMutationInput!) {
    updateSalesAreas(input: $input) {
      ... on InternalServerError {
        message
      }
      ... on SalesAreaSuccess {
        me {
          id
          ...salesAreasFragment
        }
      }
    }
  }
`

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation sendResetPasswordEmailMutation(
    $input: SendResetPasswordEmailMutationInput!
  ) {
    sendResetPasswordEmail(input: $input) {
      ... on SendResetPasswordEmailSuccess {
        success
      }
      ... on InternalServerError {
        message
      }
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPasswordMutation($input: ResetPasswordMutationInput!) {
    resetPassword(input: $input) {
      ... on ResetPasswordSuccess {
        success
        context
      }
      ... on InternalServerError {
        message
      }
    }
  }
`

export const SEND_ONFIDO_INSTRUCTIONS_EMAIL = gql`
  mutation sendOnfidoInstructions {
    onfidoInstructionsEmail(input: {}) {
      __typename
      ... on SendOnfidoInstructionsEmailSuccess {
        success
      }
      ... on NotFoundError {
        message
      }
      ... on InternalServerError {
        message
      }
    }
  }
`

export const APPROVE_INVOICE = gql`
  mutation approveInvoiceMutation($input: ApproveInvoiceMutationInput!) {
    approveInvoice(input: $input) {
      ... on ApproveInvoiceSuccess {
        invoice {
          ...invoiceFragment
        }
      }
      ... on InvoiceAlreadyApproved {
        message
      }
      ... on InvoiceDoesNotExist {
        message
      }
    }
  }
  ${INVOICE_FRAGMENT}
`

export const ACCEPT_ASSOCIATE_TERMS = gql`
  mutation acceptAssociateTermsMutation(
    $input: AcceptAssociateTermsMutationInput!
  ) {
    acceptAssociateTerms(input: $input) {
      ... on UserNotAssociateError {
        message
      }
      ... on InternalServerError {
        message
      }
    }
  }
`

export const INVITE_ASSOCIATE = gql`
  mutation inviteAssociateMutation($input: InviteAssociateMutationInput!) {
    inviteAssociate(input: $input) {
      ... on InviteAssociateSuccess {
        success
      }
      ... on ForbiddenError {
        message
      }
      ... on InternalServerError {
        message
      }
    }
  }
`

export const UPDATE_PAYROLL_STATUS = gql`
  mutation updatePayrollStatusMutation($input: UpdatePayrollStatusInput!) {
    updatePayrollStatus(input: $input) {
      ... on UpdatePayrollSuccess {
        payrolls {
          edges {
            node {
              id
              status
            }
          }
        }
      }
      ... on NotFoundError {
        message
      }
      ... on ForbiddenError {
        message
      }
      ... on InternalServerError {
        message
      }
    }
  }
`

import styled from "styled-components"

const InputLabel = styled.p`
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 22px;
  }
`

export default InputLabel

import React from "react"
import styled from "styled-components"

const Grid = styled.div<{
  numRows: number
  singleInputRows: boolean
  numSpanInputs: number
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ numRows, singleInputRows, numSpanInputs }) =>
    singleInputRows
      ? `repeat(${numRows}, min-content)`
      : `repeat(${numRows * 2 - numSpanInputs}, min-content)`};
  grid-row-gap: 25px;
  grid-column-gap: 10px;
  margin-bottom: 25px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: ${({ singleInputRows }) =>
      singleInputRows ? "1fr" : "repeat(2, 1fr)"};
    grid-template-rows: ${({ numRows }) => `repeat(${numRows}, min-content)`};
  }
`

interface Props {
  children: React.ReactNode
  singleInputRows?: boolean
  numSpanInputs?: number
  numRows: number
  className?: string
}

const GridForm: React.FC<Props> = ({
  children,
  singleInputRows = false,
  numSpanInputs = 0,
  numRows, // Does not include submit button row
  className,
}) => {
  return (
    <Grid
      className={className}
      singleInputRows={singleInputRows}
      numRows={numRows}
      numSpanInputs={numSpanInputs}
    >
      {children}
    </Grid>
  )
}

export default GridForm

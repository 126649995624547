import React, { useMemo } from "react"
import styled from "styled-components"

const Container = styled.div<{
  backgroundColor: string
  borderColor: string
  borderWidth: string
  textColor: string
  fontSize: string
  padding: string
  fontFamily: string
  fontWeight: string
  hasIcon: boolean
  iconLeft: boolean
}>`
  width: auto;
  height: auto;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ borderWidth }) => borderWidth} solid
    ${({ borderColor }) => borderColor};
  padding: ${({ padding }) => padding};
  border-radius: 19px;
  display: flex;
  align-items: center;
  flex-direction: ${({ iconLeft }) => (iconLeft ? "row-reverse" : "row")};
  justify-content: ${({ hasIcon }) => (hasIcon ? "space-between" : "center")};

  .text {
    color: ${({ textColor }) => textColor};
    font-family: ${({ fontFamily, theme }) =>
      fontFamily === "Grotesk25" ? theme.fonts.title : theme.fonts.body};
    font-size: ${({ fontSize }) => fontSize};
    line-height: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    text-align: center;
    margin: 0;
  }

  .chipIcon {
    width: 14px;
    margin-left: 5px;
    margin-bottom: 2px;
    margin: ${({ iconLeft }) => (iconLeft ? "0 5px 2px 0" : "0 0 2px 5px")};
  }
`

interface Props {
  cypressTestId?: string
  backgroundColor?: string
  borderColor?: string
  borderWidth?: string
  textColor?: string
  text: string
  size: "small" | "medium" | "large"
  fontFamily?: "Grotesk20" | "Grotesk25"
  fontWeight?: "400" | "500" | "600"
  iconPath?: string
  iconLeft?: boolean
}

const Chip: React.FC<Props> = ({
  cypressTestId = "",
  backgroundColor = "#ffffff",
  borderColor = "#b2cbc5",
  borderWidth = "1px",
  textColor = "#222",
  text,
  size = "medium",
  fontFamily = "Grotesk20",
  fontWeight = "400",
  iconPath,
  iconLeft = false,
}) => {
  const chipSizeDetails = useMemo(() => {
    if (size === "small") {
      return {
        padding: "4px 8px 3px 8px",
        fontSize: "11px",
      }
    } else if (size === "medium") {
      return {
        padding: "5px 10px 4px 10px",
        fontSize: "13px",
      }
    } else {
      return {
        padding: "9px 10px 8px 10px",
        fontSize: "13px",
      }
    }
  }, [size])

  return (
    <Container
      data-cy-test={cypressTestId}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      fontSize={chipSizeDetails.fontSize}
      padding={chipSizeDetails.padding}
      fontFamily={fontFamily}
      borderWidth={borderWidth}
      textColor={textColor}
      fontWeight={fontWeight}
      hasIcon={iconPath ? true : false}
      iconLeft={iconLeft}
    >
      <p className="text">{text}</p>
      {iconPath ? (
        <img className="chipIcon" src={iconPath} alt="Chip icon" />
      ) : null}
    </Container>
  )
}

export default Chip

import React, { useState } from "react"
import styled from "styled-components"

import "../css/layout.css"
import Notification from "../alert"
import { useUser } from "../auth/hooks"
import Nav from "./Nav"
import { UniqueLinkCodeContext } from "./Nav/ShareCodeContext"
import ShareCodeModal from "./Nav/ShareCodeModal"
import { UnderMaintenanceContext } from "./Nav/UnderMaintenanceContext"
import UnderMaintenanceModal from "./Nav/UnderMaintenanceModal"

const Container = styled.div<{
  hasNav: boolean
}>`
  background-color: ${({ theme, hasNav }) => hasNav && theme.palette.mystic};
  display: grid;
  grid-template-rows: 130px;
  grid-template-columns: none;
  max-width: 100vw;
  min-height: 100vh;
  padding-bottom: 120px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 0px;
    grid-template-rows: none;
    grid-template-columns: ${({ hasNav }) =>
      hasNav ? "minmax(0, 300px) 1fr" : "1fr"};
  }
`

const MainContent = styled.main<{ hasNav: boolean }>`
  background-color: ${({ theme, hasNav }) => hasNav && theme.palette.mystic};
  padding: ${({ hasNav, theme }) =>
    hasNav ? `14px ${theme.margins.xs}` : "0px 0px"};

  &.notificationsPage {
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ hasNav, theme }) =>
      hasNav ? `60px ${theme.margins.md} 80px ${theme.margins.md}` : "0px 0px"};
    &.notificationsPage {
      height: 100vh;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: ${({ hasNav, theme }) =>
      hasNav
        ? `60px ${theme.margins.lg} 100px ${theme.margins.lg}`
        : "0px 0px"};
  }
`

type Props = {
  children: React.ReactNode & {
    props: {
      location: {
        pathname: string
      }
    }
  }
}

const Layout: React.FC<Props> = ({ children }) => {
  const { user } = useUser()
  const renderNav = !!children?.props?.location.pathname.includes("/dashboard")
  const notificationsPage =
    !!children?.props?.location.pathname.includes("/notifications")
  const [referralModalOpen, setReferralModalOpen] = useState(false)
  const [underMaintenanceModalOpen, setUnderMaintenanceModalOpen] =
    useState(false)

  const toggleReferralCodeModal = () => {
    setReferralModalOpen(!referralModalOpen)
  }

  const openUnderMaintenanceModal = () => {
    if (!underMaintenanceModalOpen) {
      setUnderMaintenanceModalOpen(true)
    }
  }

  const closeUnderMaintenanceModal = () => {
    if (underMaintenanceModalOpen) {
      setUnderMaintenanceModalOpen(false)
    }
  }

  return (
    <Container hasNav={renderNav}>
      <UnderMaintenanceContext.Provider
        value={{
          underMaintenanceModalOpen,
          openUnderMaintenanceModal,
          closeUnderMaintenanceModal,
        }}
      >
        <UniqueLinkCodeContext.Provider
          value={{
            referralModalOpen,
            toggleReferralCodeModal,
          }}
        >
          {renderNav ? <Nav /> : null}
          <MainContent
            hasNav={renderNav}
            className={notificationsPage ? "notificationsPage" : ""}
          >
            {children}
          </MainContent>
          <ShareCodeModal
            open={referralModalOpen}
            onClose={toggleReferralCodeModal}
            code={user?.profile?.referralCode || ""}
          />
        </UniqueLinkCodeContext.Provider>
        <UnderMaintenanceModal
          open={underMaintenanceModalOpen}
          onClose={closeUnderMaintenanceModal}
        />
      </UnderMaintenanceContext.Provider>

      <Notification />
    </Container>
  )
}

export default Layout

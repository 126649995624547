import { FieldHookConfig, useField } from "formik"
import React, { useCallback } from "react"
import styled from "styled-components"

import Loader from "./Loader"

const GroupContainer = styled.div`
  .full-text {
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 500;

    .selected-option {
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.mineShaft};
      font-weight: normal;
      opacity: 0.7;
      margin-left: 10px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .full-text {
      font-size: 14px;
    }

    .selected-option {
      font-size: 12px;
    }
  }
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const Radio = styled.input`
  display: none;

  &:checked {
    + div {
      background-color: ${({ theme }) => theme.palette.mineShaft};
      border: 1px solid ${({ theme }) => theme.palette.mineShaft};
      label {
        color: ${({ theme }) => theme.palette.white};
        font-weight: 500;
      }
    }
  }
`

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.conch};
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
  transition: background-color 0.2s;

  :hover {
    background-color: rgba(112, 153, 144, 0.1);
  }
`

const Label = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.mineShaft};
  margin: 0;
  cursor: pointer;
`

const FieldError = styled.div`
  text-align: left;
  margin-bottom: 15px;
  font-size: 12px;
  pointer-events: none;
  color: red;
`

interface Props {
  label: string
  options: {
    label: string
    fullText: string
    value: string
  }[]
  loading: boolean
}

const RadioField = ({
  label,
  options,
  loading,
  ...props
}: Props & FieldHookConfig<string>): JSX.Element => {
  const [field, meta, helpers] = useField(props)
  const hasError = meta.touched && meta.error

  const handleClick = useCallback(
    (value: string) => {
      helpers.setValue(value)
    },
    [helpers]
  )

  return (
    <GroupContainer {...field} {...props}>
      <div>
        <p className="full-text">
          {label}{" "}
          <span className="selected-option">
            {options.find((option) => option.value === field.value)?.fullText}
          </span>
        </p>
        {loading ? (
          <Loader />
        ) : (
          <List>
            {options.map((option, index) => (
              <React.Fragment key={index}>
                <Radio
                  type="radio"
                  value={option.value}
                  checked={option.value === field.value}
                  readOnly
                />
                <Container onClick={() => handleClick(option.value)}>
                  <Label htmlFor={option.value}>{option.label}</Label>
                </Container>
              </React.Fragment>
            ))}
          </List>
        )}
      </div>
      {hasError && <FieldError>{meta.error}</FieldError>}
    </GroupContainer>
  )
}

export default RadioField

import React from "react"
import styled, { keyframes } from "styled-components"

const fadeInLeft = keyframes`
  0% {
    transform: translateX(-50px); 
    opacity: 0;
  }
  100% { 
    transform: translateX(0px);
    opacity: 1;
  }
`
const fadeInRight = keyframes`
  0% {
    transform: translateX(50px); 
    opacity: 0;
  }
  100% { 
    transform: translateX(0px);
    opacity: 1;
  }
`
const fadeInTop = keyframes`
  0% {
    transform: translateY(-50px); 
    opacity: 0;
  }
  100% { 
    transform: translateX(0px);
    opacity: 1;
  }
`
const fadeInBottom = keyframes`
  0% {
    transform: translateY(50px); 
    opacity: 0;
  }
  100% { 
    transform: translateX(0px);
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% { 
    opacity: 0;
  }
`

type FromDirection = "top" | "left" | "right" | "bottom"
type FadeType = "fadeIn" | "fadeOut"

const Wrapper = styled.div<{
  fadeType: FadeType
  duration: string
  delay: string
  direction: FromDirection
}>`
  height: 100%;
  animation: ${({ duration }) => duration} ease-out running
    ${({ delay }) => delay}
    ${({ fadeType }) =>
      fadeType === "fadeIn"
        ? ({ direction }) =>
            direction === "left"
              ? fadeInLeft
              : direction === "right"
              ? fadeInRight
              : direction === "top"
              ? fadeInTop
              : fadeInBottom
        : fadeOut}
    forwards;
`

interface Props {
  fadeType: FadeType
  delay?: string
  duration?: string
  from?: FromDirection
  children?: React.ReactNode
}

const FadeInOut: React.FC<Props> = ({
  fadeType = "fadeIn",
  delay = "0s",
  duration = "0.3s",
  from = "bottom",
  children,
  ...props
}: Props) => {
  return (
    <Wrapper
      fadeType={fadeType}
      duration={duration}
      delay={delay}
      direction={from}
      {...props}
    >
      {children}
    </Wrapper>
  )
}

export default FadeInOut

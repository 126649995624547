import { ApolloProvider } from "@apollo/client"
import React from "react"
import Helmet from "react-helmet"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"

import { getApolloClient } from "./configureApollo"
import { store } from "./store"
import { theme } from "./theme"

const wrapRootElement = ({
  element,
}: {
  element: React.ReactNode
}): React.ReactNode => {
  const apolloClient = getApolloClient()

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          {element}
          {process.env.GATSBY_INCLUDE_THIRD_PARTY_SCRIPTS === "1" ? (
            <Helmet
              script={[
                {
                  innerHTML: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="dvRrQBKJmEtrFvYf6y1SFXzSz07Z5ACs";analytics.SNIPPET_VERSION="4.13.2"; analytics.load("dvRrQBKJmEtrFvYf6y1SFXzSz07Z5ACs"); analytics.page(); }}();`,
                  type: "text/javascript",
                },
              ]}
            />
          ) : null}
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default wrapRootElement

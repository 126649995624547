import { RouteUpdateArgs } from "gatsby"

import wrapPageElement from "./src/wrapPageElement"
import wrapRootElement from "./src/wrapRootElement"

const onRouteUpdate = ({ location, prevLocation }: RouteUpdateArgs): void => {
  if (prevLocation && location.pathname !== prevLocation.pathname) {
    window.analytics && window.analytics.page()
  }
}

export { wrapRootElement, wrapPageElement, onRouteUpdate }

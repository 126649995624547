import React from "react"

interface Props {
  color?: string
  direction?: "left" | "right"
}

const Arrow = ({ color, direction = "left" }: Props): JSX.Element => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Go to...</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform={direction === "right" ? "rotate(180, 15, 15)" : undefined}
      >
        <g
          transform="translate(-93.000000, -650.000000)"
          fill={color ? color : "currentColor"}
        >
          <g transform="translate(93.000000, 650.000000)">
            <polygon points="16.0681857 8 21.5732471 13.3911382 5 13.3911382 5 15.6088618 21.5732471 15.6088618 16.0679487 21 19.3623591 21 26 14.5 19.3623591 8"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Arrow

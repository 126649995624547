import React from "react"

const LockIcon = (): JSX.Element => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>7BBBB603-08F6-40FC-B960-183E6756E855@1x</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="YOCO-Reseller-Assets"
          transform="translate(-44.000000, -650.000000)"
          fill="#ffffff"
        >
          <g
            id="icn-lock-white-copy"
            transform="translate(44.000000, 650.000000)"
          >
            <path
              d="M12,11 C12,9.321 13.321,8 15,8 C16.679,8 18,9.321 18,11 L18,13 L12,13 L12,11 Z M20.363,13 L20,13 L20,11 C20,8.247 17.753,6 15,6 C12.247,6 10,8.247 10,11 L10,13 L9.637,13 C8.193,13 7,14.193 7,15.637 L7,21.363 C7,22.806 8.193,24 9.637,24 L20.363,24 C21.807,24 23,22.806 23,21.363 L23,15.637 C23,14.193 21.807,13 20.363,13 L20.363,13 Z"
              id="Fill-1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LockIcon

import React, { useRef, useMemo, useState, useEffect } from "react"
import styled, { css } from "styled-components"

import { useInfiniteScroll, useIsMobile } from "../../hooks"
import { Loader } from "../components"

const Headings = styled.div<{
  showMobileHeading: boolean
  mobileColumnTemplate: string
  desktopColumnTemplate: string
  rightAlignLastHeading: boolean
}>`
  display: ${({ showMobileHeading }) => (showMobileHeading ? "grid" : "none")};
  grid-template-columns: ${({ mobileColumnTemplate }) => mobileColumnTemplate};
  gap: 5px 10px;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.mystic};
  position: sticky;
  top: 0px;
  padding: 30px 20px 12px 15px;
  z-index: 10;

  span {
    font-family: ${({ theme }) => theme.fonts.title};
    font-size: 10px;
    color: ${({ theme }) => theme.palette.doveGrey};
    text-transform: uppercase;
    text-align: left;
    margin: 0;
  }

  span:first-child {
    text-align: left;
  }

  span:last-child {
    text-align: right;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    padding: 15px 22px;
    gap: 15px;
    grid-template-columns: ${({ desktopColumnTemplate }) =>
      desktopColumnTemplate};

    span:last-child {
      text-align: ${({ rightAlignLastHeading }) =>
        rightAlignLastHeading ? "right" : "left"};
    }
  }
`

const Rows = styled.div<{
  showOverflow: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: ${({ showOverflow }) => (showOverflow ? "visible" : "hidden")};
  padding: 10px 0px;
  gap: 10px;
`

const Row = styled.div<{
  columnCount: number
  mobileColumnTemplate: string
  desktopColumnTemplate: string
  rowDisabled: boolean
}>`
  min-height: 52px;
  height: max-content;
  display: grid;
  gap: 5px 10px;
  align-items: center;
  padding: 12px 20px 12px 15px;
  font-size: 14px;
  background-color: ${({ rowDisabled, theme }) =>
    rowDisabled ? theme.palette.conch : theme.palette.white};
  border-radius: 20px;
  -webkit-border-radius: 20px;
  grid-template-columns: ${({ mobileColumnTemplate }) => mobileColumnTemplate};

  p {
    margin: 0;
  }

  & > :first-child {
    overflow: hidden;
  }
  ${({ rowDisabled }) =>
    !rowDisabled
      ? css`
          &:hover {
            box-shadow: 0px 15px 15px -20px rgba(0, 0, 0, 0.5);
          }
        `
      : null};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: ${({ desktopColumnTemplate }) =>
      desktopColumnTemplate};
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-rows: 1fr;
    padding: 12px 22px;
    gap: 15px;
  }
`

const FixedRow = styled(Row)`
  box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.tiara};
`

const FixedRowContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: ${({ theme }) => theme.margins.xs};
  right: ${({ theme }) => theme.margins.xs};
  padding-bottom: 30px;
  background-image: ${({ theme }) =>
    `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0) 45%, ${theme.palette.mystic})`};
  z-index: 10;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    left: ${({ theme }) =>
      `calc(${theme.desktopNavWidth} + ${theme.margins.md})`};
    right: ${({ theme }) => theme.margins.md};
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    left: ${({ theme }) =>
      `calc(${theme.desktopNavWidth} + ${theme.margins.lg})`};
    right: ${({ theme }) => theme.margins.lg};
  }
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

const EmptyTableMessage = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 20px;
  padding: 20px;

  p {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.mineShaft};
    margin: 0;
  }
`

const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.doveGrey};

  p {
    margin-bottom: 0px;
  }

  .center-loading {
    flex: 1;
    text-align: center;
  }

  .back-to-top {
    opacity: 1;
    border-bottom: 1px solid ${({ theme }) => theme.palette.doveGrey};
    margin-right: 30px;
    transition: opacity 0.2s;
  }

  .back-to-top:hover {
    opacity: 0.6;
  }
`

export interface TableHeading {
  name: string | JSX.Element
  accessor: string
  showOnMobile?: boolean
  columnAllowance:
    | "min-content"
    | "minmax(30px, max-content)"
    | "1fr"
    | "1.5fr"
    | "2fr"
    | "minmax(0, 2fr)"
  renderData: (index: number, data?: any) => void
}

/* Props that can apply to any
   row in any table can be added
   to the type below */
interface BaseTableRow {
  rowDisabled: boolean
  rowFixed?: boolean
}

interface Props {
  headings: TableHeading[]
  rowData: (any | BaseTableRow)[]
  loading?: boolean
  emptyTableText?: string
  allRowsLoaded: boolean
  handleLoadMoreRows: () => void
  className?: string
}

const Table: React.FC<Props> = ({
  headings,
  rowData,
  loading = false,
  emptyTableText = "There is no data for this table",
  allRowsLoaded,
  handleLoadMoreRows,
  className,
}) => {
  const tableRef = useRef<HTMLDivElement>(null)
  const [hasScrollbar, setHasScrollBar] = useState(false)

  useEffect(() => {
    if (!loading && window && tableRef?.current) {
      if (window.innerHeight < tableRef.current.scrollHeight) {
        setHasScrollBar(true)
      }
    }
  }, [loading])

  const isMobile = useIsMobile()
  const totalColumns = headings.length
  const showMobileHeading = !!headings.find((heading) => heading.showOnMobile)

  const rightAlignFinalHeader =
    headings[totalColumns - 1].columnAllowance ===
      "minmax(30px, max-content)" ||
    headings[totalColumns - 1].columnAllowance === "min-content"

  const desktopColumnTemplate = useMemo(() => {
    let tempTemplate = ""
    headings.forEach((heading, index) => {
      tempTemplate = tempTemplate + heading.columnAllowance
      if (index !== headings.length - 1) {
        tempTemplate = tempTemplate + " "
      }
    })
    return tempTemplate
  }, [headings])

  const mobileColumnTemplate = useMemo(() => {
    let tempTemplate = ""
    headings.forEach((heading, index) => {
      if (heading.showOnMobile) {
        tempTemplate = tempTemplate + heading.columnAllowance
        if (index !== headings.length - 1) {
          tempTemplate = tempTemplate + " "
        }
      }
    })
    return tempTemplate
  }, [headings])

  const loadMoreRef = useRef<HTMLDivElement>(null)
  useInfiniteScroll(allRowsLoaded, loadMoreRef, handleLoadMoreRows)

  return (
    <div ref={tableRef} className={className}>
      <Headings
        showMobileHeading={showMobileHeading}
        mobileColumnTemplate={mobileColumnTemplate}
        desktopColumnTemplate={desktopColumnTemplate}
        rightAlignLastHeading={rightAlignFinalHeader}
      >
        {headings.map((heading, index) =>
          isMobile ? (
            heading.showOnMobile ? (
              <span key={`heading-${index}`}>{heading.name}</span>
            ) : null
          ) : (
            <span key={`heading-${index}`}>{heading.name}</span>
          )
        )}
      </Headings>
      {loading ? (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      ) : rowData && rowData.length > 0 ? (
        <>
          <Rows showOverflow={rowData.length <= 3}>
            {rowData.map((data, indexRow) => {
              const rowItems: any[] = []
              headings.map((heading) => {
                rowItems.push(
                  heading.renderData(indexRow, data[heading.accessor])
                )
              })
              return data.rowFixed ? (
                <FixedRowContainer>
                  <FixedRow
                    key={`table-row-${indexRow}-sticky`}
                    mobileColumnTemplate={mobileColumnTemplate}
                    desktopColumnTemplate={desktopColumnTemplate}
                    className="table-row"
                    columnCount={totalColumns}
                    rowDisabled={data.rowDisabled}
                  >
                    {rowItems}
                  </FixedRow>
                </FixedRowContainer>
              ) : (
                <Row
                  key={`table-row-${indexRow}`}
                  mobileColumnTemplate={mobileColumnTemplate}
                  desktopColumnTemplate={desktopColumnTemplate}
                  className="table-row"
                  columnCount={totalColumns}
                  rowDisabled={data.rowDisabled}
                >
                  {rowItems}
                </Row>
              )
            })}
          </Rows>
          <TableFooter ref={loadMoreRef}>
            {allRowsLoaded ? (
              /*
                NOTE: This conditional render is not optimally responsive as it
                does not take the boolean value of loading returned by the
                merchants query into account. This is because of a long-standing
                issue with fetchMore not updating the loading value.
              */
              <>
                <p>All content loaded</p>
                {hasScrollbar ? (
                  <a href="#" className="back-to-top">
                    Back to Top
                  </a>
                ) : null}
              </>
            ) : (
              <p className="center-loading">Loading more</p>
            )}
          </TableFooter>
        </>
      ) : (
        <EmptyTableMessage>
          <p>{emptyTableText}</p>
        </EmptyTableMessage>
      )}
    </div>
  )
}

export default Table

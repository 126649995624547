import { useField } from "formik"
import React, { useCallback } from "react"
import styled from "styled-components"

import Notification from "../alert"
import Button from "./Button"

const Wrapper = styled.div<{
  padding: string
}>`
  padding: ${({ padding }) => padding};
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  #file-upload {
    display: none;
  }

  label {
    background-color: ${({ theme }) => theme.palette.outrageousOrange};
    color: ${({ theme }) => theme.palette.white};
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 16px 20px;
    line-height: 24px;
    height: 55px;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    transition: opacity 0.2s;
    min-width: 200px;

    :hover {
      opacity: 0.8;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: row;
  }
`

const FieldError = styled.div`
  position: absolute;
  text-align: left;
  margin-bottom: 15px;
  font-size: 12px;
  pointer-events: none;
  color: red;
  width: 100%;
  bottom: -45px;
`

interface Props {
  label?: string
  disabled?: boolean
  hasDelete?: boolean
  onDelete?: () => void
  expectedFileType: "image" | "video" | "document"
  isFileSet: boolean
  padding?: string
  name: string
}

const FileUploadInput = ({
  label = "Upload file",
  disabled = false,
  hasDelete = true,
  onDelete,
  expectedFileType,
  isFileSet = false,
  padding = "0px 20px",
  ...props
}: Props): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props)
  const hasError = meta.touched && meta.error

  const getExpectedType = useCallback(() => {
    if (expectedFileType === "image") {
      return "image/*"
    } else if (expectedFileType === "video") {
      return "video/*"
    } else {
      return ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    }
  }, [expectedFileType])

  const updateFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setTouched(true)
      helpers.setValue(
        event?.target?.files?.[0] ? event.target.files[0] : undefined
      )
    },
    [helpers]
  )

  const deleteFile = useCallback(() => {
    helpers.setValue(undefined)
    if (onDelete) {
      onDelete()
    }
  }, [onDelete, helpers])

  return (
    <Wrapper padding={padding}>
      <ButtonContainer>
        <input
          type="file"
          id="file-upload"
          accept={getExpectedType()}
          onChange={updateFile}
          disabled={disabled}
        />
        <label htmlFor="file-upload">{label}</label>
        {hasDelete ? (
          <Button
            color="#e1eae8"
            textColor="#222"
            label="Delete"
            disabled={disabled || isFileSet === false}
            onClick={deleteFile}
          />
        ) : null}
      </ButtonContainer>
      <Notification />
      {hasError && <FieldError>{meta.error}</FieldError>}
    </Wrapper>
  )
}

export default React.memo(FileUploadInput)
